import { AppColors, AppText, AppButton } from '@backlinkit/shared';
import { Flex, FlexProps, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { getFormatDate } from '../../utils/date-format';

export interface AppArticleHeroProps extends FlexProps {
  pageName: string;
  backgroundImage?: string;
  title: string;
  image?: string;
  author: string;
  autherIcon: any;
  publishedDate: Date;
  updatedDate: Date;
  contributers: string[];
  readTime: number;
}

const ArticleHeroComponent: React.FC<AppArticleHeroProps> = ({
  pageName,
  backgroundImage,
  image,
  title,
  autherIcon,
  author,
  publishedDate,
  updatedDate,
  contributers,
  readTime,
  ...props
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const formatPageName = (pageName: string) => {
    if (!pageName) return ''

    const [first, second] = pageName.split('-');

    const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

    return `${capitalize(first)} ${capitalize(second)}`;
  };

  const formatContributors = (contributors: string[]) => {
    if (!contributors || contributors.length === 0) return '';

    if (contributors.length === 1) return contributors[0];

    if (contributors.length === 2) return contributors.join(' and ');

    return (
      contributors.slice(0, -1).join(', ') +
      ' and ' +
      contributors[contributors.length - 1]
    );
  };

  return (
    <Flex
      backgroundImage={backgroundImage}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      bgColor={AppColors.secondary}
      w={'100%'}
      justify={'center'}
      py={image ? '40px' : 0}
      {...props}
    >
      <Flex
        minH={'370px'}
        justify={image ? 'space-between' : 'start'}
        align={'center'}
        width={'100%'}
        maxW={'1140px'}
        gap={{
          '1xl': '150px',
          base: 7,
          lg: '40px',
          xl: '100px'
        }}
        flexDir={variant === 'desktop' ? 'row' : 'column'}
        px={variant === 'desktop' ? '0' : 1}

      >
        <Flex flexDir={'column'} justify={'start'} align={'start'} gap={variant === 'desktop' ? 6 : '24px'} py={8}>
          <Flex align={'center'} gap={2}>
            <Link fontSize={variant === 'desktop' ? '18px' : '14px'} color={'white'} href='/' _hover={{ textDecoration: 'none' }}>Home / </Link>
            {/* <Link fontSize={variant === 'desktop' ? '18px' : '14px'} color={'white'} href='/articles' _hover={{ textDecoration: 'none' }}>Article Page / </Link> */}
            <AppText fontSize={variant === 'desktop' ? '18px' : '14px'} color={'white'} >{formatPageName(pageName)}</AppText>
          </Flex>
          <AppText as='h1' fontSize={variant === 'desktop' ? '48px' : '28px'} fontWeight={'600'} color={'white'}>
            {title ?? pageName}
          </AppText>
          <Flex flexDirection={'row'} gap={variant === 'desktop' ? '40px' : '20px'} alignItems={'center'} flexWrap={'wrap'}>
            <Flex gap={'12px'} alignItems={'center'}>
              <Image height={'32px'} borderRadius={'50%'} src={autherIcon} />
              <AppText fontWeight={'500'} fontSize={'16px'} color={'white'}>
                {author}
              </AppText>
            </Flex>

            <Flex flexDirection={'row'} gap={'5px'} alignItems={'center'} >
              <FaRegCalendarAlt size={'22px'} color={'white'} />
              <AppText fontWeight={'400'} fontSize={'14px'} color={'white'}>
                {getFormatDate(publishedDate)}
              </AppText>
            </Flex>
            <AppText fontWeight={'400'} fontSize={'14px'} color={'white'}>
              &#x2022; {readTime} Min Read
            </AppText>
          </Flex>
          <Flex gap={'5px'} flexDir={{base: 'column', lg: 'row'}}>
            <AppText color={'white'} fontSize={'14px'} fontWeight={'500'} lineHeight={'26px'}>Contributors:</AppText>
            <AppText color={'white'} fontSize={'14px'} fontWeight={'400'} lineHeight={'26px'}>
              {formatContributors(contributers)}
            </AppText>
          </Flex>
          <Flex gap={'5px'}>
            <AppText color={'white'} fontSize={'14px'} fontWeight={'500'}>Updated:</AppText>
            <AppText color={'white'} fontSize={'14px'} fontWeight={'400'}>
              {getFormatDate(updatedDate)}
            </AppText>
          </Flex>
        </Flex>

        {image && (
          <Image src={image} width={'auto'} maxH={'400px'} />
        )}
      </Flex>
    </Flex>
  );
};


export default ArticleHeroComponent;