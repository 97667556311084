import { Box, Flex, FlexProps, useBreakpointValue, Image, Icon } from '@chakra-ui/react';
import { AppText, AppPlayer, AppColors, AppButton, AppTextRenderer } from '@backlinkit/shared';
import { BannerCta } from '../../v2-banner.tsx/app-banner';
import { FaPlay } from 'react-icons/fa';
import { FaPlayCircle } from 'react-icons/fa';

export interface AppVideoHeroProps extends FlexProps {
  title: string;
  content?: string;
  contentItems?: string[];
  btns?: BannerCta[];
  video?: string;
  usersImage?: string;
  theme?: 'light' | 'dark';
  usesDialog?: boolean;
  onDialogClick?: () => void;
}

export const AppVideoHero: React.FC<AppVideoHeroProps> = ({
  title,
  content,
  contentItems,
  btns,
  usersImage,
  video,
  theme = 'dark',
  usesDialog,
  onDialogClick,
  ...props
}) => {
  const textColor = theme === 'dark' ? 'white' : AppColors.textColor;
  const variant = useBreakpointValue({
    base: 'mobile',
    sm: 'tablet',
    xl: 'desktop',
  });

  const getVideoHeight = (): string => {
    switch (variant) {
      case 'mobile':
        return '200px';
      case 'tablet':
        return '250px';
      case 'desktop':
        return '280px';
      default:
        return '280px';
    }
  };

  const getTitleBoxWidth = () => {
    if (!contentItems && variant === 'desktop') {
      return '50%';
    }
    if (!contentItems && variant !== 'desktop') {
      return '100% !important';
    }
    return '100%';
  };
  return (
    <Flex
      width={'100%'}
      maxW={'1140px'}
      align={'center'}
      justify={'space-evenly'}
      {...props}
      flexDir={variant !== 'desktop' ? 'column' : 'row'}
      px={{ base: 7, xl: 0 }}
      gap={{ base: 0, lg: '32px' }}
    >
      <Flex flexDir={'column'} gap={4} w={getTitleBoxWidth()}>
        <AppText
          as='h1'
          fontSize={{base: '30px', lg: '48px'}}
          color={textColor}
          fontWeight={'700'}
          width={variant === 'desktop' ? '350px' : '100%'}
        >
          {title}
        </AppText>
        {content && <AppText color={textColor}>{content}</AppText>}
        {contentItems &&
          contentItems.map((item, idx) => (
            <AppTextRenderer markdown={item} color={textColor} key={`${item}-${idx}`} isArticle />
          ))}
        {btns && (
          <Flex width={'100%'} align={'center'} gap={4}>
            {btns.map((btn, idx) => (
              <Box key={`${btn.label}-${idx}`} w={{ base: '100%', lg: 'auto' }}>
                <AppButton
                  onClick={btn.onClick}
                  borderColor={btn.borderColor}
                  color={btn.color}
                  bgColor={btn.bgColor}
                  borderRadius={'full'}
                  w={{ base: '100%', lg: 'auto' }}
                >
                  {btn.label}
                </AppButton>
              </Box>
            ))}
          </Flex>
        )}
        {usersImage && (
          <Box width={'100%'} my={4} mb={{base: 6, lg: 0}}>
            <Image src={usersImage} h={'50px'} objectFit={'contain'} />
          </Box>
        )}
      </Flex>
      {usesDialog && (
        <Flex
          w={'100%'}
          height={'100%'}
          minH={'450px'}
          borderRadius={'2xl'}
          bgColor={'white'}
          justify={'center'}
          align={'center'}
          boxShadow={'lg'}
        >
          <Icon
            as={FaPlayCircle}
            color={AppColors.ctaColor}
            _hover={{ cursor: 'pointer' }}
            onClick={onDialogClick}
            boxSize={'80px'}
          />
        </Flex>
      )}
      {!usesDialog && video && (
        <Flex width={'100%'} height={'100%'} borderRadius={'2xl'} pt={{ base: '32px', lg: 0 }}>
          <AppPlayer media={video} height={getVideoHeight()} width="480px" />
        </Flex>
      )}
    </Flex>
  );
};
