import { useBreakpointValue, Flex } from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppFaqSection } from '../../components/app/app-faq-section/app-faq-section';
import { ArticleDto, articleFaqs, articleMocks } from './mocks/article-mock';
import { TableOfContents } from '../../components/articles/table-of-content';
import ShareBannerComponent from '../../components/app/share-banner/share-banner';
import ArticleHeroComponent from '../../components/articles/article-hero';
import { AppText } from '@backlinkit/shared';
import { AppArticleCard } from '../../components/app/app-article-card/app-article-card';
import ArticleContentRenderer from '../../components/articles/article-content-renderer';
import { AppArticleBanner } from '../../components/app/app-article-banner/app-article-banner';
import {
  articlePagePaddingLeft,
  articlePagePaddingRight,
} from '../../constants/styles/article-page';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageMetaArticlePage } from '../../constants/meta-data';
import { heroBackground } from '../../constants/heroImages';

const ArticlePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variant = useBreakpointValue({ base: 'mobile', lg: 'desktop', '1xl': 'bigger-desktop' });
  const [article, setArticle] = useState<ArticleDto>();
  const [toc, setToc] = useState<string[]>([]);
  const articleRef = useRef<HTMLDivElement>(null);

  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const foundArticle = articleMocks.find((a) => a.slug === slug);

    if (!foundArticle) navigate('/');

    setArticle(foundArticle);
  }, [slug]);

  useEffect(() => {
    const headings: string[] = [];

    article?.articleContent.forEach((con) => {
      if (con.type === 'text' && typeof con.content === 'string') {
        const parser = document.createElement('div');
        parser.innerHTML = con.content;

        const sectionHeadings = Array.from(parser.querySelectorAll('h2')).map((h2) => h2.innerText);
        headings.push(...sectionHeadings);
      }
    });

    setToc(headings);
  }, [article?.articleContent]);

  const handleScrollToSection = (section: string) => {
    if (articleRef.current) {
      const sectionElement = Array.from(articleRef.current.querySelectorAll('h2')).find(
        (el) => el.innerText === section
      );
      sectionElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  usePageMeta(PageMetaArticlePage);

  return (
    <>
      {article && (
        <>
          <AppComponentWrapper px="0">
            <ArticleHeroComponent
              pageName={article?.slug}
              title={article?.title}
              pl={articlePagePaddingLeft}
              pr={articlePagePaddingRight}
              // image={article?.articleBannerImageUrl}
              author={article?.author}
              autherIcon={article?.authorIcon}
              publishedDate={article?.datePublished}
              updatedDate={article?.lastUpdated}
              readTime={article?.readTime}
              contributers={article?.contributors}
              sx={{
                transition: 'padding-left 0.3s ease, padding-right 0.3s ease',
              }}
              backgroundImage={heroBackground}
            />
          </AppComponentWrapper>

          <AppComponentWrapper
            maxWidth={'1440px'}
            pl={articlePagePaddingLeft}
            pr={articlePagePaddingRight}
            sx={{
              transition: 'padding-left 0.3s ease, padding-right 0.3s ease',
            }}
            py={variant === 'desktop' ? '120px' : '68px'}
            flexWrap="wrap"
          >
            <Flex height={'auto'} minH={'100vh'} overflowX={'hidden'} flexDirection={'column'}>
              <Flex
                w={'full'}
                maxWidth={'1140px'}
                gap={'54px'}
                justifyContent={'center'}
                flexDirection={{ base: 'column', lg: 'row' }}
                alignItems={{ base: 'center', lg: 'normal' }}
              >
                <Flex
                  w={{ base: 'full', lg: '320px' }}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                  flexShrink={0}
                  gap={'20px'}
                >
                  <ShareBannerComponent articleLink={article?.slug} />
                  <TableOfContents toc={toc} onSectionClick={handleScrollToSection} />
                </Flex>

                <Flex
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={'20px'}
                  ref={articleRef}
                  overflow={'visible'}
                >
                  {article.articleContent.map((articleContent, idx) => (
                    <ArticleContentRenderer key={idx} content={articleContent as any} />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </AppComponentWrapper>

          <AppComponentWrapper
            px={variant === 'desktop' ? '135px' : '16px'}
            py={variant === 'desktop' ? '120px' : '68px'}
            bg={'#F7F7F7'}
            justify={'center'}
            align={'center'}
          >
            {(variant === 'desktop' || variant === 'bigger-desktop') && (
              <Flex
                flexDirection={'column'}
                gap={'42px'}
                w={'100%'}
                maxW={'1140px'}
                align={'center'}
                justify={'center'}
              >
                <Flex width={'100%'} justifyContent={'start'}>
                  <AppText fontSize={'48px'} fontWeight={'600'}>
                    Related Articles
                  </AppText>
                </Flex>
                <Flex w={'100%'} justify={'center'}>
                  {/* {articleMocks[1].map((article, idx) => {
                    if (variant === 'desktop' && idx >= 2) return null; */}

                  <AppArticleCard w={'100%'} maxW={'1140px'} title="" article={articleMocks[1]} />
                  {/* })} */}
                </Flex>
              </Flex>
            )}

            {variant === 'mobile' && (
              <AppArticleBanner title="Related Articles" article={articleMocks[1]} />
            )}
          </AppComponentWrapper>

          {/* <AppComponentWrapper
            px={variant === 'desktop' ? '135px' : '16px'}
            py={variant === 'desktop' ? '120px' : '68px'}
            bg={'#F7F7F7'}
          >
            <ArticlePageContactForm onSubmit={() => { }} />
          </AppComponentWrapper> */}

          <AppComponentWrapper>
            <AppFaqSection
              title="People also ask"
              description={`<p>Still have some questions or room for more knowledge? We’ve got you covered in the PAA section.</p> <br /><p>We know visitors like you have additional questions on requested topics, and we encourage that. Below, we’ve answered user’s popular questions directly from Google’s search query data.</p>
              `}
              faqs={articleFaqs}
              variant="light"
            />
          </AppComponentWrapper>
        </>
      )}
    </>
  );
};

export default ArticlePage;
