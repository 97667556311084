import { Flex, FlexProps, FormControl, FormLabel } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export type AppCellInputProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  placeHolder?: string;
  label?: string;
  labelColor?: string;
  country?: string;
  enableAreaCodes?: boolean;
  inputStyles?: CSSProperties;
  dropdownStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
};

export const AppCellInput = <T extends FieldValues>({
  control,
  name,
  error,
  placeHolder,
  label,
  labelColor,
  country,
  inputStyles,
  dropdownStyle,
  buttonStyle,
  enableAreaCodes = true,
  ...props
}: AppCellInputProps<T>) => {
  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            {label && (
              <FormLabel fontSize={'14px'} color={labelColor}>
                {label}
              </FormLabel>
            )}

            <PhoneInput
              placeholder={placeHolder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputStyle={{ ...inputStyles }}
              dropdownStyle={{ ...dropdownStyle }}
              buttonStyle={{ ...buttonStyle }}
              country={country}
              enableAreaCodes={enableAreaCodes}
            />
            {error && <div style={{ color: 'red' }}>{error?.message}</div>}
          </FormControl>
        )}
      />
    </Flex>
  );
};
