import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { UserLead, CreateUserLead } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const userLeadApi = createApi({
  reducerPath: 'user-lead',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    createUserLead: build.mutation<UserLead, CreateUserLead>({
      query: (body) => ({
        url: `/userlead/create`,
        body: body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useCreateUserLeadMutation } = userLeadApi;
