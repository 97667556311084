import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';

export interface AppTextContentProps extends FlexProps {
  title?: string;
  subHeading?: string;
  boldTitle?: boolean;
  titleSize?: 'sm' | 'md' | 'lg';
  listItems?: string[];
  isUnordered?: boolean;
  content?: string;
  orderIndex?: number;
  subIndex?: string;
  startIndex?: number;
  customStartIndex?: string;
  itemAlignemnt?: string;
}

export const AppTextContent: React.FC<AppTextContentProps> = ({
  title,
  subHeading,
  boldTitle = false,
  titleSize,
  listItems,
  isUnordered = true,
  content,
  orderIndex,
  startIndex,
  subIndex,
  customStartIndex,
  itemAlignemnt = 'center',
  children,
  ...props
}) => {
  const getTitleSize = (titleSize?: string) => {
    switch (titleSize) {
      case 'sm':
        return {
          fontSize: '18px',
          lineHeight: '1.1',
        };
      case 'md':
        return {
          fontSize: '24px',
          lineHeight: '1.1',
        };
      case 'lg':
        return {
          fontSize: '28px',
          lineHeight: '34px',
        };
      default:
        return {
          fontSize: '24px',
          lineHeight: '1.1',
        };
    }
  };
  return (
    <Flex
      width={'100%'}
      height={'100%'}
      flexDir={'column'}
      gap={4}
      {...props}
      mt={6}
      maxW={'1000px'}
    >
      {title && (
        <AppText {...getTitleSize(titleSize)} fontWeight={boldTitle ? '700' : '600'}>
          {title}
        </AppText>
      )}
      {subHeading && (
        <AppText>
          {subHeading}
        </AppText>
      )}
      {listItems?.map((item, idx) => (
        <Flex align={itemAlignemnt} gap={4} key={`${idx}-${item}`}>
          {isUnordered && (
            <Box
              width={'8px'}
              minW={'8px'}
              height={'8px'}
              bgColor={'black'}
              borderRadius={'full'}
            />
          )}
          {!isUnordered && (
            <>
              {!startIndex && <AppText>{`${orderIndex ?? 1}.${idx + 1}`}</AppText>}
              {subIndex && <AppText>{`${subIndex}.${idx + 1}`}</AppText>}
              {customStartIndex && <AppText>{`${customStartIndex}`}</AppText>}
              {startIndex && (
                <AppText>{`${startIndex + parseFloat(`${idx / 10}`)}`.slice(0, 3)}</AppText>
              )}
            </>
          )}
          <AppText>{item}</AppText>
        </Flex>
      ))}
      {content && <AppText>{content}</AppText>}
      {children}
    </Flex>
  );
};
