// const months = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];

// export const getFormatDate = (date: Date) => {
//   const isoString = date.toISOString().split('T')[0];
//   const split = isoString.split('-');
//   const month = months[Number(split[1])];
//   const day = split[2];
//   const year = split[0];

//   return `${month} ${day}, ${year}`
// };


export const getFormatDate = (date: Date) => {
  if (!date) return

  const dateToFormat = new Date(date);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(dateToFormat);

  return formattedDate;
}