import { useEffect } from 'react';

type usePageMetaProps = {
  url: string;
  title?: string;
  description?: string;
};

const host = process.env.REACT_APP_WEB_URL ?? '';

export const usePageMeta = ({ url, title, description }: usePageMetaProps) => {
  const defaultTitle = 'The Smart Approach To Tracking Your Off-Page SEO Campaigns';
  const defaultDescription =
    'The edge to unparalleled link building for you or your valued clients. Effortlessly track and enhance your backlink campaigns, just like the experts do.';

  const defaultUrl = host;

  useEffect(() => {
    document.title = title || defaultTitle;
    document.querySelector("meta[name='title']")?.setAttribute('content', title || defaultTitle);
  }, [defaultTitle, title]);

  useEffect(() => {
    if (document) {
      document
        .querySelector("meta[name='description']")
        ?.setAttribute('content', description || defaultDescription);
    }
  }, [defaultDescription, title]);

  useEffect(() => {
    if (document) {
      document
        .querySelector("link[rel='canonical']")
        ?.setAttribute('href', url ? `${defaultUrl}${url}` : defaultUrl);
    }
  }, [defaultUrl, url]);
};
