import React from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppBanner, BannerCta } from '../../components/v2-banner.tsx/app-banner';
import { AppColors } from '../../theme';
import { AppPlayer, useDialog } from '@backlinkit/shared';
import { AppProductBanner } from '../../components/app/app-product-pricing/app-product-pricing';
import { AppArticleBanner } from '../../components/app/app-article-banner/app-article-banner';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppReviewsBanner } from '../../components/app/app-reviews-banner/app-reviews-banner';
import { AppColors as SiteColors } from '../../theme/index';
import { reviews } from '../../mock-data/reviews';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { AppVideoHero } from '../../components/app/app-video-hero/app-video-hero';
import { useFetchProductsQuery } from '../../store/api/productApi';
import { articleMocks } from '../article/mocks/article-mock';
import { baseUrl } from '../../constants/nav-items';
import { AsFeaturedInComponent } from '../../components/as-featured-in/as-featured-in';
import { PageMetaHomePage } from '../../constants/meta-data';
import { usePageMeta } from '../../hooks/usePageMeta';

export const HomePage: React.FC = () => {
  usePageMeta(PageMetaHomePage);
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });
  const dialog = useDialog();

  const { data: products, refetch: refetchProducts } = useFetchProductsQuery({
    refetchOnMountArgChange: true,
  });

  const navigate = useNavigate();

  const bannerButtons: BannerCta[] = [
    {
      label: 'Start Your Free Trial Now',
      bgColor: AppColors.ctaColor,
      color: 'white',
      borderColor: 'transparent',
      onClick: () =>
        window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_blank'),
    },
  ];
  const aboutUsButtons: BannerCta[] = [
    {
      label: 'Start Your Free Trial Now',
      bgColor: AppColors.primary,
      borderColor: 'white',
      color: 'white',
      onClick: () =>
        window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_blank'),
    },
  ];

  const getVideoHeight = (): string => {
    switch (variant) {
      case 'mobile':
        return '200px';
      case 'tablet':
        return '250px';
      case 'desktop':
        return '320px';
      default:
        return '320px';
    }
  };

  const images: string[] = [
    'https://backlinkitprod.blob.core.windows.net/assets/d8748254-bfa9-4c2f-8c51-9c1f817bb81a_boring.webp-1736498888952',
    'https://backlinkitprod.blob.core.windows.net/assets/ab87a4be-ec06-4c01-9f7a-b89232d8374f_code-cave.webp-1736498889182',
    'https://backlinkitprod.blob.core.windows.net/assets/49e45447-8c06-4c56-85fb-cca09e25f88d_diary-seo.webp-1736498889439',
    'https://backlinkitprod.blob.core.windows.net/assets/4a677ecf-9969-453a-93ba-3602a0446aa8_vin-de-cap.webp-1736498889676',
    'https://backlinkitprod.blob.core.windows.net/assets/76dcec8e-040c-415c-bfe3-945cf6ca980d_2-green.webp-1736498889911',
  ];
  const usersImage =
    'https://backlinkitprod.blob.core.windows.net/assets/1e0aa2a8-e870-4469-a50d-a7d07dc279ba_new-users-image.png-1736504811030';

  const descriptionItems: string[] = [
    '<strong>Did you know:</strong> A significant percentage of authoritative backlinks are lost, broken or changed within the first 45 days of being indexed? We know all too well how much broken links can cost your online business.',
    '<strong>The solution:</strong> Our backlink intelligent tool tracks your backlinks 24/7 and helps recover them.',
    '<strong>The result:</strong> Boosted search rankings and an increase in relevant traffic to your website. ',
    'It’s next-level backlink intelligence to safeguard your domain authority.',
  ];

  const handleDialog = () => {
    dialog({
      size: '2xl',
      centeredHeading: true,
      render: (onSubmit) => (
        <AppPlayer
          media="https://www.youtube.com/watch?v=BHfZ9qMnvLw"
          width="480px"
          height={getVideoHeight()}
        />
      ),
    });
  };

  return (
    <>
      <AppComponentWrapper
        bgImage={
          'https://backlinkitprod.blob.core.windows.net/assets/88f41094-b098-42c2-bb0b-651d5a867446_home-hero.png-1736769745950'
        }
      >
        <AppVideoHero
          video="https://www.youtube.com/watch?v=BHfZ9qMnvLw"
          title={'Change Your SEO Trajectory'}
          contentItems={descriptionItems}
          btns={bannerButtons}
          py={{ base: '40px', lg: '80px' }}
          usersImage={usersImage}
          usesDialog
          onDialogClick={() => handleDialog()}
        />
      </AppComponentWrapper>
      <AppComponentWrapper bgColor={AppColors.featuredColor}>
        <AsFeaturedInComponent />
      </AppComponentWrapper>
      <AppComponentWrapper>
        <AppBanner
          title="Backlinkit tracks every link so you don’t have to"
          description="Whether a link is broken, missing its anchor, or set as 'nofollow,' Backlinkit makes sure you know—and helps you recover it."
          image={
            'https://backlinkitprod.blob.core.windows.net/assets/44779ff4-7d3d-4553-9280-6b012fab23f4_pro-link-building-image.webp-1736498888552'
          }
          imageSize="medium"
          btns={aboutUsButtons}
          bgColor={'white'}
          width={'100%'}
          color={AppColors.iconColor}
        />
      </AppComponentWrapper>
      <AppComponentWrapper minH={'600px'} bgColor={SiteColors.primary} pb={{ base: 0, lg: 12 }}>
        <AppReviewsBanner
          theme="dark"
          title={`Don't take our word for it`}
          description={`Discover how website owners, agencies, and SEO professionals maintain their domain authority with Backlinkit.`}
          reviews={reviews}
          brands={images}
          onBtnClick={() =>
            window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_blank')
          }
        />
      </AppComponentWrapper>
      <Flex
        width={'100%'}
        bgColor={'rgba(255, 255, 255, 0.95)'}
        justify={'center'}
        align={'center'}
      >
        <AppProductBanner
          title="Backlinkit plans & pricing"
          plans={products ?? []}
          onBtnClick={() => navigate(RoutesList.Pricing)}
          isGrid={false}
        />
      </Flex>
      <Flex
        width={'100%'}
        bgColor={'rgba(255, 255, 255, 0.95)'}
        justify={'center'}
        align={'center'}
        minH={'600px'}
        flexDir={'column'}
      >
        <AppArticleBanner
          pt={'0px !important'}
          tag="Popular Articles"
          title="What Are Backlinks in SEO?—We Explain Backlinking in 2025"
          article={articleMocks[1]}
        />
        {/* <AppArticleCard article={articleMocks[1]} /> */}
      </Flex>
    </>
  );
};
