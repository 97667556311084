import { AppText } from "@backlinkit/shared";
import { As, Flex, Icon } from "@chakra-ui/react"

export interface KnowledgeCardProps {
  id: number;
  articleLink?: string;
  icon: string | As | any;
  title: string;
  description: string;
  onClick: (articleLink: string) => void;
}

const KnowledgeCard: React.FC<KnowledgeCardProps> = ({ ...props }) => {
  const { id, icon, description, title, articleLink, onClick } = props

  return (
    <Flex
      padding={'32px 24px'}
      height={'100%'}
      minHeight={'118px'}
      width={'100%'}
      border="1px solid #EBEBEB"
      borderRadius="24px"
      backgroundColor={'#FAFAFA'}
      flexDirection={'column'}
      _hover={{ bg: 'white' }}
      cursor={'pointer'}
      onClick={() => onClick(articleLink!)}
    >
      <Icon
        as={icon}
        boxSize={'64px'}
        mb={'24px'}
      />

      <Flex flexDirection={'column'}>
        <AppText
          fontSize={'24px'}
          fontWeight={'600'}
          height={'100%'}
          mb={'12px'}
        >
          {title}
        </AppText>

        <AppText fontSize={'16px'} fontWeight={'400'} color={'#585C67'}>
          {description}
        </AppText>
      </Flex>

    </Flex>
  );
}

export default KnowledgeCard