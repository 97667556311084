import { AppText } from "@backlinkit/shared";
import { Button, Flex, Image, useBreakpointValue } from "@chakra-ui/react";

const FreeDemoSectionComponent = () => {
  const variant = useBreakpointValue({
    base: "mobile",
    md: "desktop",
  });
  const freeDemoImage = 'https://backlinkitprod.blob.core.windows.net/assets/68012db7-eac8-415d-9cf7-b266ab9380ea_request-demo.png-1736504552640'
  return (
    <Flex maxW={'1140px'} flexDirection={variant === 'desktop' ? 'row' : 'column'} width={'100%'} bg={'#4A4F54'} borderRadius={'36px'} padding={variant === 'desktop' ? '40px 96px' : '35px'} align={'center'} gap={variant === 'desktop' ? '195px' : '50px'}>
      <Flex flexDirection={'column'} maxW={'445px'}>
        <AppText mb="10px" color={'white'} fontSize={variant === 'desktop' ? '36px' : '24px'} fontWeight={'600'}>Free demo</AppText>
        <AppText mb={'32px'} color={'white'} fontSize={'16px'} fontWeight={'400'} lineHeight={'24px'}>Get all of your Semrush questions addressed by a professional by requesting a free demo.
        </AppText>
        <Button maxW={'160px'} fontSize={'16px'} borderRadius={'80px'} color={'white'} bg={'#FF8800'} padding={'14px 30px'}>Sign Up Now</Button>
      </Flex>
      <Flex alignItems={'start'} w={variant === 'desktop' ? '' : '100%'}>
        <Image src={freeDemoImage} maxH={variant === 'desktop' ? '282px' : '210px'} />
      </Flex>
    </Flex>
  )
}

export default FreeDemoSectionComponent;