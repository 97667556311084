import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { Box, Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { ContactForm } from '../../components/forms/contact-form/contact-form';
import { AppText, AppButton, AppColors, CreateUserLead } from '@backlinkit/shared';
import { useCreateUserLeadMutation } from '../../store/api/userLeadApi';
import { useWidgetIsReady } from '@livechat/widget-react';
import { PageContactPage } from '../../constants/meta-data';
import { usePageMeta } from '../../hooks/usePageMeta';
import { useEffect } from 'react';
import { heroBackground, mobileBackground } from '../../constants/heroImages';

export const Contact: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  usePageMeta(PageContactPage);
  const widgetIsReady = useWidgetIsReady();
  const toast = useToast();
  const [createTrigger] = useCreateUserLeadMutation();

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const upsertUserLead = async (formData: CreateUserLead) => {
    try {
      await createTrigger({
        displayName: formData.displayName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        subject: formData.subject,
        message: formData.message,
        agreeToTerms: formData.agreeToTerms,
      }).unwrap();
      toast({
        title: 'Message sent',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName="Contact"
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>
      <AppComponentWrapper px={{ base: 7, xl: '135' }}>
        <Flex
          maxW={'1140px'}
          bgColor={'rgba(3, 125, 252, 0.1)'}
          flexDir={{ base: 'column', lg: 'row' }}
          py={'80px'}
          gap={6}
          w={'100%'}
          mt={8}
          borderRadius={'2xl'}
          p={{ base: 4, lg: 8 }}
        >
          <Flex w={'100%'} maxW={'400px'} flexDir={'column'} gap={6}>
            <AppText as={'h2'} fontSize={{ base: '30px', lg: '48px' }} fontWeight={'700'}>
              We'd love to hear from you
            </AppText>
            <AppText>
              Have questions or need assistance? Our team is here to help! Reach out to us anytime,
              and we’ll get back to you as soon as possible. Let’s connect and make your Backlinkit
              journey smoother!
            </AppText>
            <Box>
              {widgetIsReady && (
                <AppButton
                  onClick={() =>
                    (window as any).LC_API && (window as any).LC_API.open_chat_window()
                  }
                  bgColor={AppColors.secondary2}
                  color={'white'}
                  borderRadius={'full'}
                >
                  Live Chat
                </AppButton>
              )}
            </Box>
          </Flex>
          <Flex w={'100%'}>
            <ContactForm
              onSubmit={async (formData) => {
                await upsertUserLead(formData);
              }}
            />
          </Flex>
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
