import { AppColors, AppText, theme } from '@backlinkit/shared';
import { Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RoutesList } from '../../../router/router';

export type AppCrumb = {
  name: string;
  href: string;
};

export interface AppBasicHeroProps extends FlexProps {
  pageName: string;
  backgroundImage?: string;
  title?: string;
  tagAlignment?: string;
  image?: string;
  breadCrumbs?: AppCrumb[];
}

export const AppBasicHero: React.FC<AppBasicHeroProps> = ({
  pageName,
  backgroundImage,
  image,
  tagAlignment,
  title,
  breadCrumbs,
  ...props
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });

  const navigate = useNavigate();

  const contentJustify = () => {
    if (variant === 'desktop' && !image) {
      return 'center';
    }
    if (variant === 'desktop' && image) {
      return 'start';
    }
    if (variant !== 'desktop' && tagAlignment) {
      return tagAlignment;
    }
    return 'center';
  };

  return (
    <Flex
      backgroundImage={backgroundImage}
      backgroundPosition={'center'}
      backgroundSize={{ base: 'cover', lg: 'cover' }}
      backgroundRepeat={'no-repeat'}
      bgColor={AppColors.secondary}
      w={'100%'}
      justify={'center'}
      py={image ? '40px' : 0}
      {...props}
    >
      <Flex
        minH={{ base: '220px', lg: '370px' }}
        justify={image ? 'space-between' : 'center'}
        align={'center'}
        width={'100%'}
        maxW={'1140px'}
        gap={{ base: 7, xl: 0 }}
        flexDir={{ base: 'column', lg: 'row' }}
        px={{ base: 7, xl: 0 }}
      >
        <Flex flexDir={'column'} justify={'center'} align={contentJustify()} gap={6}>
          <Flex align={'center'} gap={2}>
            <Flex
              fontSize={'18px'}
              as={NavLink}
              color={'white'}
              to={RoutesList.Index}
              _hover={{
                cursor: 'pointer',
              }}
            >{`Home`}</Flex>

            {breadCrumbs && (
              <Flex align={'center'} gap={3}>
                {breadCrumbs.map((crumb, idx) => (
                  <AppText
                    onClick={() => navigate(crumb.href)}
                    key={`${crumb.name}-${idx}`}
                    color={'white'}
                    fontSize={'18px'}
                  >
                    / {crumb.name}
                  </AppText>
                ))}
              </Flex>
            )}
            {!breadCrumbs && <AppText color={'white'} fontSize={'18px'}>/ {pageName}</AppText>}
          </Flex>
          <AppText
            textAlign={'center'}
            fontSize={{ base: '30px', lg: '48px' }}
            fontWeight={'700'}
            color={'white'}
            as={'h1'}
          >
            {title ?? pageName}
          </AppText>
        </Flex>
        {image && <Image src={image} width={'auto'} maxH={'400px'} />}
      </Flex>
    </Flex>
  );
};
