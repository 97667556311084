import {
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Icon,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { AppText, AppTextRenderer } from '@backlinkit/shared';
import { AppColors as SiteColors } from '../../../theme';
import { useEffect, useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { FiMinusCircle } from 'react-icons/fi';
import { FAQ } from '../../../models/faq';

export interface AppFaqSectionProps {
  title: string;
  description: string;
  faqs: FAQ[];
  variant?: 'dark' | 'light';
}

export const AppFaqSection: React.FC<AppFaqSectionProps> = ({
  title,
  description,
  faqs,
  variant = 'dark',
}) => {
  const [selectedFaq, setSelectedFaq] = useState<FAQ>();
  const width = useBreakpointValue({
    base: '100%',
    md: '680px',
    lg: '800px',
    xl: '1140px',
  });

  const getItemTextColor = (faq: FAQ) => {
    if (faq === selectedFaq) {
      return 'white';
    }
    if (variant === 'dark') {
      return 'white';
    }
    if (variant === 'light' && faq !== selectedFaq) {
      return 'black';
    }
  };

  const textColor = variant === 'dark' ? 'white' : 'black';

  useEffect(() => {
    setSelectedFaq(faqs[0]);
  }, []);
  return (
    <Flex
      flexDir={'column'}
      w={'100%'}
      gap={'30px'}
      py={'80px'}
      align={'center'}
      justify={'center'}
      maxW={'1140px'}
      pb={'100px'}
      px={width !== '1140px' ? 1 : 0}
    >
      <AppText
        fontSize={{ base: '30px', lg: '48px' }}
        color={textColor}
        fontWeight={'700'}
        className="faq-description"
        as={'h2'}
      >
        {title}
      </AppText>
      <Box>
        <AppTextRenderer
          markdown={description}
          color={textColor}
          className="faq-description"
          isArticle
        />
      </Box>
      <Accordion allowToggle defaultIndex={0}>
        {faqs.map((faq, idx) => (
          <AccordionItem
            key={`${faq.question}-${idx}`}
            bgColor={faq === selectedFaq ? SiteColors.primary : 'transparent'}
            boxShadow={faq === selectedFaq ? 'lg' : 'none'}
            my={0}
            borderRadius={'2xl'}
            border={'none'}
            maxW={'1140px'}
            w={width}
            p={1}
          >
            <AccordionButton
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              bgColor={'transparent'}
              onClick={() => setSelectedFaq(faq === selectedFaq ? undefined : faq)}
              borderRadius={'2xl'}
              p={4}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex align={'center'} gap={3} justify={'space-between'}>
                <AppText
                  fontSize={width === '100%' ? '18px' : '24px'}
                  color={getItemTextColor(faq)}
                  fontWeight={'500'}
                >{`${idx + 1}. `}</AppText>
                <AppText
                  fontSize={width === '100%' ? '18px' : '24px'}
                  color={getItemTextColor(faq)}
                  fontWeight={'500'}
                  textAlign={'left'}
                  as={'h3'}
                >
                  {faq.question}
                </AppText>
              </Flex>
              <Icon
                as={faq === selectedFaq ? FiMinusCircle : FiPlusCircle}
                color={getItemTextColor(faq)}
                boxSize={'30px'}
                ml={{ base: 4, lg: '0' }}
              />
            </AccordionButton>
            <AccordionPanel maxW={width}>
              <Flex ml={width !== '1140px' ? 6 : 8}>
                <AppTextRenderer
                  markdown={faq.answer}
                  isArticle
                  color="white"
                  className="faq-answer"
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};
