import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { useBreakpointValue, SimpleGrid } from '@chakra-ui/react';
import LegalCard from '../../components/app/app-legal-card/app-legal-card';
import { legalItems } from '../../constants/legal';
import { useMediaQuery } from '@chakra-ui/react';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageLegalPage } from '../../constants/meta-data';
import { useEffect } from 'react';
import { heroBackground, mobileBackground } from '../../constants/heroImages';

export const LegalPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  usePageMeta(PageLegalPage);
  const [isSmallScreen] = useMediaQuery('(max-width: 375px)');
  // const { data: faqs, refetch: refetchFaqs } = useFetchFeaturedFaqsQuery({
  //   refetchOnMountArgChange: true,
  // });
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'medium',
    xl: 'desktop',
  });

  const breakpointColumns = {
    base: 2,
    md: 3,
    lg: 3,
    xl: 3,
  };

  const columns = isSmallScreen ? 1 : breakpointColumns;

  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName="Legal"
          title="Legals Hub"
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
          px={{ base: 0, lg: 7, xl: 0 }}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={variant === 'desktop' ? '120px' : '68px'} justifyContent={'center'} px={{base: 4, md: 0}}>
        <SimpleGrid
          columns={columns}
          gap={variant === 'desktop' ? '30px' : '14px'}
          w={{ base: '100%', md: 'auto' }}
        >
          {legalItems.map((item, idx) => (
            <LegalCard key={idx} icon={item.icon} title={item.title} link={item.link} />
          ))}
        </SimpleGrid>
      </AppComponentWrapper>
    </>
  );
};
