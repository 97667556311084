import { Button, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { ArticleCtaContentType } from "../../pages/article/mocks/article-mock";
import { AppText } from "@backlinkit/shared";
import { baseUrl } from "../../constants/nav-items";

export interface ArticleCtaProps {
  content: ArticleCtaContentType;
}

const ArticleCtaComponent: React.FC<ArticleCtaProps> = ({ content }) => {
  const { heading, description, backgroundImage, icon } = content;

  const variant = useBreakpointValue({ base: 'mobile', xl: 'desktop', lg: 'mobile' })


  return (
    <Flex
      borderRadius="15px"
      bgImage={`url(${backgroundImage})`}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="center"
      width="100%"
      padding={variant === 'desktop' ? '37px 30px 30px 82px' : '45px 37px 68px 37px'}
      alignItems="center"
      justifyContent="space-between"
      gap={variant === 'desktop' ? '105px' : '40px'}
      flexDirection={variant === 'desktop' ? 'row' : 'column'}
      mt={variant === 'desktop' ? '50px' : '20px'}
      mb={variant === 'desktop' ? '50px' : '20px'}
    >
      <Image src={icon} w="170px" maxH={'135px'} />
      <Flex flexDirection="column" gap="20px" flex="1" marginLeft="20px" alignItems={variant === 'desktop' ? 'start' : 'center'}>
        <Flex flexDirection="column" gap={'6px'}>
          <AppText fontSize="32px" fontWeight="600" color="white">
            {heading}
          </AppText>
          <AppText fontSize="16px" color="#F7F7F7">
            {description}
          </AppText>
        </Flex>
        <Button
          // maxW={'120px'}
          color="white"
          padding="12px 30px"
          fontSize="16px"
          fontWeight="500"
          borderRadius="80px"
          bg="#FF8800"
          _hover={{
            bg: "#FF8800",
            opacity: '90%'
          }}
          onClick={() => window.open(`${baseUrl}/registration`, '_self')}
        >
          Start 7 Day Free Trial
        </Button>
      </Flex>
    </Flex>
  );
};

export default ArticleCtaComponent;
