import { AppColors, AppText } from '@backlinkit/shared';
import { As, Flex, Icon, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { FaRegLightbulb } from 'react-icons/fa';
import { GrDocumentText } from 'react-icons/gr';
import { PiStudentBold } from "react-icons/pi";

interface CommunityItem {
  title: string;
  description: string;
  icon: string | As | any;
  backgroundImage: string;
}

const communityItems: CommunityItem[] = [
  {
    title: 'Knowledge Base',
    description:
      'Learn how to make the most of your Semrush subscription with straightforward manuals, video walkthroughs, and ready workflows. Some random text to showcase the ellipsing',
    icon: (
      <Flex
        justify={'center'}
        align={'center'}
        boxSize={'40px'}
        borderRadius={'100%'}
        bgColor={AppColors.ctaColor}
      >
        <Icon as={FaRegLightbulb} color={'white'} />
      </Flex>
    ),
    backgroundImage:
      'https://backlinkitprod.blob.core.windows.net/assets/9045f68c-12fc-43d0-92c2-c4a577035266_ellipse.png-1736501388689',
  },
  {
    title: 'Backlinkit blog',
    description:
      'Learn how to make the most of your Semrush subscription with straightforward manuals, video walkthroughs, and ready workflows.',
    icon: (
      <Flex
        justify={'center'}
        align={'center'}
        boxSize={'40px'}
        borderRadius={'100%'}
        bgColor={AppColors.secondary2}
      >
        <Icon as={GrDocumentText} color={'white'} />
      </Flex>
    ),
    backgroundImage:
      'https://backlinkitprod.blob.core.windows.net/assets/e7026c86-e95b-4149-b132-187e1b89d2e5_ellipse-blue.png-1736501388456',
  },
  {
    title: 'Backlinkit academy',
    description:
      'Learn how to make the most of your Semrush subscription with straightforward manuals, video walkthroughs, and ready workflows.',
    icon: (
      <Flex
        justify={'center'}
        align={'center'}
        boxSize={'40px'}
        borderRadius={'100%'}
        bgColor={AppColors.secondary}
      >
        <Icon as={PiStudentBold} color={'white'} />
      </Flex>
    ),
    backgroundImage:
      'https://backlinkitprod.blob.core.windows.net/assets/89584a6a-a6d8-4a01-88db-6b2cab8fc0a8_ellipse-purple.png-1736501388225',
  },
];

const CommunitySectionComponent = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'desktop',
  });

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={'70px'}>
      <AppText
        fontSize={variant === 'desktop' ? '48px' : '24px'}
        textAlign="center"
        color="black"
        fontWeight="700"
        maxW="670px"
      >
        Join our community to receive support
      </AppText>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap={variant === 'desktop' ? '51px' : '32px'}>
        {communityItems.map((item, index) => (
          <Flex
            key={index}
            flexDirection="column"
            maxW="356px"
            bgImage={item.backgroundImage}
            backgroundRepeat="no-repeat"
            backgroundPosition="left bottom"
            backgroundSize="200px"
            paddingLeft={'34px'}
          >
            {/* <Icon as={item.icon} boxSize="80px" mb="25px" /> */}
            {item.icon}
            <AppText
              fontSize={variant === 'desktop' ? '24px' : '20px'}
              color="#202222"
              fontWeight="700"
              my={variant === 'desktop' ? '16px' : '12px'}
            >
              {item.title}
            </AppText>
            <AppText
              fontSize="16px"
              color="#3D424D"
              fontWeight="400"
              lineHeight="26px"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 4,
              }}
            >
              {item.description}
            </AppText>
          </Flex>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default CommunitySectionComponent;
