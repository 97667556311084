import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { ProductDto, ProductType } from '@backlinkit/shared';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchProducts: build.query<ProductDto[], any>({
      query: () => ({
        url: `/product`,
        method: 'GET',
      }),
      transformResponse: (response: ProductDto[]) => {
        return response.filter((x) => x.type === ProductType.SUBSCRIPTION);
      },
    }),
  }),
});

export const { useFetchProductsQuery, useLazyFetchProductsQuery } = productApi;
