import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppTextContent } from '../../components/app/app-text-content/app-text-content';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageCookiePage } from '../../constants/meta-data';
import { useEffect } from 'react';
import { mobileBackground, heroBackground } from '../../constants/heroImages';

export const Cookies: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  usePageMeta(PageCookiePage);
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
    xl: 'large-desktop',
  });
  const title = 'COOKIE POLICY';
  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName={'Cookie Policy'}
          bgImage={variant === 'mobile' ? mobileBackground : heroBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={12} px={{ base: 7, xl: 0 }}>
        <Flex
          bgColor={'rgba(238, 238, 238, 0.3)'}
          borderRadius={'2xl'}
          p={variant === 'mobile' ? 7 : 6}
          flexDir={'column'}
          maxW={'1140px'}
          width={'100%'}
          gap={6}
        >
          <AppText variant={'h3'}>{title}</AppText>
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="INTRODUCTION"
            startIndex={1.1}
            listItems={[
              `BacklinkIt may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our Website, to help us customise the Website and to improve your experience while you are visiting the Website.`,
              `This Cookie Policy (“this Policy”) sets out all that you need to know about what cookies are, how and why we use cookies on the Website, and how you can adjust your cookie settings on the Website. `,
              `We take our legal obligations very seriously and that is why we have ensured that you can easily access all our policies and procedures at any time. Please take the time to read through this Policy carefully, and if you have any questions, please do not hesitate to contact our Information and Privacy Officer at the e-mail address set out in clause 13 below. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="DEFINITIONS AND INTERPRETATION"
            itemAlignemnt="start"
            startIndex={1.1}
            listItems={[
              `This Policy is part of and is incorporated into our Privacy Policy, which can be found on the Website.`,
              `Unless the context clearly indicates a contrary intention:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={2.2}
            listItems={[
              `words and expressions defined in the Privacy Policy shall bear corresponding meanings in this Policy; and`,
              `terms that are used in this Policy that are specifically defined in POPIA, are given the meanings ascribed to them in POPIA.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="WHO DOES THIS POLICY APPLY TO"
            subHeading="This Policy applies to you if you:"
            startIndex={3.1}
            itemAlignemnt="start"
            listItems={[
              `are a guest on our Website;`,
              `create a User account on our Website;`,
              `add to and/or manage your User account on our Website ;`,
              `contact or communicate with us via our Website;`,
              `provide feedback, send messages, or make available any other content on our Website; or`,
              `opt-in to any marketing communication from us`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="WHAT ARE COOKIES"
            itemAlignemnt="start"
            startIndex={4.1}
            listItems={[
              `A “cookie” is a small string of information that is stored on your device’s hard drive when you visit a website. A cookie assigns you a unique identifier, which we then use each time you submit a query to the Website. A cookie allows us to “remember” information about you, such as your preferences  and your login information. It is important to note that cookie files do not contain Personal Information about you.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="CONTROL OF COOKIES"
            itemAlignemnt="start"
            startIndex={5.1}
            listItems={[
              `You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select this setting, you may be unable to access certain parts of the Website, or experience all of the features of the Website. You may also choose to accept only certain cookies and to reject other cookies.`,
              `Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to the Website. We deal with specific cookies or similar technology below. You can also learn more about our use of cookies and similar technologies and your choices below.`,
            ]}
          />
          <Flex flexDir={'column'} gap={5}>
            <AppText fontSize={'24px'} fontWeight={'bold'}>
              Types of Cookies
            </AppText>
            <AppText>
              The following types of cookies may be used when you visit the Website:
            </AppText>
          </Flex>
          <AppTextContent
            subHeading="6.1 Necessary or “First Party” Cookies"
            itemAlignemnt="start"
            listItems={[
              `Our cookies are “first-party cookies” and can be either permanent or temporary. These are necessary cookies, without which the Website won’t work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but we do not recommend that you do this, as this may affect the functionality of the Website.`,
            ]}
          />
          <AppTextContent
            subHeading="6.2 Analytics Cookies"
            itemAlignemnt="start"
            listItems={[
              `Analytics cookies monitor how Users reached the Website, and how they interact with and move around once on the Website. These cookies let us know what features on the Website are working best and what features on the Website can be improved.`,
            ]}
          />
          <AppTextContent
            subHeading="6.3 Personalisation Cookies"
            itemAlignemnt="start"
            listItems={[
              `Personalisation cookies are used to recognise repeat visitors to the Website. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Website.`,
            ]}
          />
          <AppTextContent
            subHeading="6.4 Adverting Cookies:"
            itemAlignemnt="start"
            listItems={[
              `Advertising cookies are placed on your computer by advertisers and ad-servers, so as to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and adservers to gather information about your visits to the Website and other websites, alternate the ads sent to a specific computer, and track how often an advert has been viewed and by whom. These cookies are linked to a computer and do not gather any Personal Information about you.`,
            ]}
          />
          <AppTextContent
            subHeading="6.5 Security Cookies"
            itemAlignemnt="start"
            listItems={[
              `Security cookies help us to identify and prevent security risks. We use these cookies to authenticate Users and to protect User data from unauthorised parties.`,
            ]}
          />
          <AppTextContent
            subHeading="6.6 Website Management Cookies"
            itemAlignemnt="start"
            listItems={[
              `Website management cookies are used to maintain your identity or session on the Website, so that you are not logged off unexpectedly, and any information that you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser should you wish to do so.`,
            ]}
          />
          <AppTextContent
            subHeading="6.7 Third-Party Cookies"
            listItems={[
              `Third party cookies may be place on your computer when you visit the Website by companies that run certain services that we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="WHY WE USE COOKIES"
            itemAlignemnt="start"
            startIndex={7.1}
            listItems={[`They enable us to:`]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={7.1}
            itemAlignemnt="start"
            listItems={[
              `estimate our audience size and usage pattern;`,
              `provide Services and functionality to you;`,
              `improve our marketing and promotional efforts;`,
              `store information about your use of our Services, as well as your preferences, and so allow us to customise the Website according to your individual interests and make your usage of the Website more enjoyable; and`,
              `speed up your searches.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={8.1}
            itemAlignemnt="start"
            listItems={[
              `As highlighted in clause 4 above, cookie files do not contain Personal Information about you. Cookie files do, however, contain a personal identifier, allowing us to associate your Personal Information with a certain device. These files serve a number of useful purposes for you, including:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={8.1}
            itemAlignemnt="start"
            listItems={[
              `granting you access to age-restricted content;`,
              `assisting you in navigating the Website;`,
              `tailoring our Website’s functionality to you personally by letting us remember your preferences;`,
              `improving how our Website performs;`,
              `allowing third parties to provide services to our Website; and`,
              `helping us deliver targeted advertising, where appropriate, in compliance with the applicable laws.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={9.1}
            title="OTHER TRACKING TECHNOLOGY"
            subHeading="In addition to cookies, we may use other tracking technologies on the Website to help customise the Website and improve your experience. These tracking technologies may include, but are not limited to the following:"
            listItems={[`Web Beacons`]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={9.1}
            listItems={[
              `Our Website may contain electronic image requests (called a single-pixel gif or web beacon request) that allow us to count page views and to access cookies. Any electronic image viewed as part of a web page (including an ad banner) can act as a web beacon. `,
              `A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or e -mail. They are used to track the number of users who have visited particular pages and viewed e-mails, and to acquire other statistical data.`,
              `Our web beacons do not collect, gather, monitor or share any of your Personal Information. We merely use them to compile anonymous information about our Website and to collect a limited set of data, such as a cookie numbers, time and date of page or email view, and a description of the page or e-mail on which they reside.`,
              `Web beacons and pixel tags cannot be declined. You can, however, limit their use by controlling the cookies that interact with them. This can be done manually in your browser. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={9.2}
            subHeading="Targeted Content"
            itemAlignemnt="start"
            listItems={[
              `While you are logged into the Website, we may display targeted adverts and other relevant information based on your Personal Information. In a completely automated process, computers process the Personal Information and match it to adverts or related information. `,
              `We never share Personal Information with any advertiser unless you specifically provide us with your Consent to do so. Advertisers receive a record of the total number of impressions and clicks for each advert. They do not receive any of your Personal Information.`,
              `If you click on an advert, we may send a referring URL to the advertiser’s Website, identifying that a customer is visiting from the Website. We do not send Personal Information to advertisers with the referring URL. It is important that you are aware that once you are on the advertiser’s Website, however, the advertiser is then able to collect your Personal Information.`,
              `BacklinkIt is not responsible or liable for any information, representations, warrantie s, and/or content on any Website of any advertiser, as we do not exercise any control over any advertiser’s privacy policy. The onus is on you to refer to the privacy policy of any such advertiser, before providing them with any Personal Information. If you do not want these advertisers to collect your Personal Information, please contact them directly.`,
              `You agree to indemnify BacklinkIt regarding any loss that you may sustain should you fail to take the steps set out in clause 9.2.4 above.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={10.1}
            itemAlignemnt="start"
            title="CONSENT"
            listItems={[
              `When you visit the Website, we check if you have provided Consent in the last 6 (Six)months. `,
              `If you have not provided Consent in the last 6 (Six) months, then we display a cookie Consent banner and ask for your Consent and only trigger necessary cookies and similar tracking technologies until such Consent is provided. `,
              `If you have provided Consent for all cookies and similar technologies in the last 6 (Six) months, then we trigger all cookies and similar tracking technologies for 6 (Six) months from when this Consent is provided.`,
            ]}
          />
          <AppTextContent
            isUnordered
            startIndex={11.1}
            title="PRIVACY POLICY"
            itemAlignemnt="start"
            listItems={[
              `For more information about how we use the information that we collect from you, including Personal Information, information collected by cookies and other tracking technologies, please refer to our Privacy Policy, which is posted on the Website. By using the Website, you agree to accept and to be bound by this Policy and our Privacy Policy.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={12.1}
            title="POLICY MODIFICATIONS"
            itemAlignemnt="start"
            listItems={[
              `BacklinkIt, in its sole discretion, reserves the right to amend, modify or update this Policy from time to time, with or without notice to you and you waive the right to receive specific notice of each such change or modification. Whenever reasonably possible, we will notify you of any changes by placing a notice in a prominent place on the Website or by sending you an e-mail detailing the changes that we have made and indicating the date that the Policy was last updated.`,
              `The most updated version of this Policy will always be available on the Website.`,
              `You agree and undertake to review this Policy whenever you visit the Website`,
              `Save as expressly provided to the contrary in this Policy, any amended version of this Policy will supersede and replace previous versions thereof. If you continue to use the Website or our Services following a change to the Policy, the changed Policy will apply to you and you will be deemed to have accepted such updated Policy`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={13.1}
            itemAlignemnt="start"
            title="UPDATES, FEEDBACK AND CONTACT INFORMATION"
            listItems={[
              `This Policy was last updated on 1 March 2024.`,
              `If you have any questions, concerns or comments regarding this Policy, please send them to: `,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            listItems={[
              `Attention: The Information and Privacy Officer.`,
              `E-mail: info@backlinkit.com.`,
              `Address: 2nd Floor, New Link Centre, 1 New Street, Esterville, Paarl, Western Cape, 7646.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="NOTICES AND DOMICILIUM"
            listItems={[
              `Notices to you shall be given to the e-mail address on file associated with your User account. You agree that this e-mail address shall be your domicilium citandi et executandi ("Domicilium") for all purposes relating to this Policy and your agreement with us, including the giving of any notice, the payment of any sum and the serving of any process.`,
              `To change the email address on file in relation to your User account, please e -mail us at the address specified in clause 13 above. All notices will be deemed to have been received by you on the day that they are sent by us. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            title="GOVERNING LAW"
            itemAlignemnt="start"
            listItems={[
              `This Policy and any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with this Policy are governed by and construed in accordance with South African law. The Parties irrevocably agree that the South African courts have exclusive jurisdiction to settle any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with this Policy.`,
            ]}
          />
          <AppTextContent
            title="GENERAL"
            itemAlignemnt="start"
            listItems={[
              `This Policy does not create or imply any agency, partnership or franchise relationship. Nothing in thisPolicy, express or implied, is intended to or shall confer on any third party any right, benefit, or remedy of any nature whatsoever.`,
              `If any provision of this Policy is found by any court or other authority of competent jurisdiction to be invalid, unenforceable, or illegal, this shall not impair the operation of this Policy, or affect the other provisions which are valid.`,
            ]}
          />
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
