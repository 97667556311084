import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppButton, AppColors, AppText, ProductDto } from '@backlinkit/shared';
import PriceCards from '../../price-card/price-card';
import { AppPriceBannerCards } from '../app-product-banner/app-product-banner';

export interface AppProductBannerProps {
  tag?: string;
  title: string;
  descriptionItems?: string[];
  annualPrices?: boolean;
  isGrid?: boolean;
  plans: ProductDto[];
  onBtnClick?: () => void;
}

export type ToggleState = {
  left: string;
  right: string;
};

export const AppProductBanner: React.FC<AppProductBannerProps> = ({
  tag,
  title,
  descriptionItems,
  annualPrices,
  isGrid = true,
  plans,
  onBtnClick,
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });

  return (
    <Flex
      flexDir={'column'}
      py={'95px'}
      px={{ base: 7, md: 3, xl: 0 }}
      gap={'40px'}
      maxW={isGrid ? '100%' : '970px'}
      w={'100%'}
    >
      <AppText
        fontSize={variant === 'mobile' ? '26px' : '44px'}
        fontWeight={'700'}
        textAlign={'center'}
        as={'h2'}
      >
        {title}
      </AppText>
      {descriptionItems && descriptionItems?.length > 0 && (
        <Flex width={'100%'} align={'center'} justify={'center'} flexDir={'column'} textAlign={'center'} gap={3}>
          {descriptionItems.map((x, i) => (
            <AppText key={`${x}-${i}`}>{x}</AppText>
          ))}
        </Flex>
      )}

      <Flex align={'center'} justify={'space-evenly'}>
        {isGrid ? (
          <PriceCards products={plans} />
        ) : (
          <AppPriceBannerCards products={plans} />
        )}
      </Flex>
      {onBtnClick && (
        <Flex justify={'center'} w={'100%'}>
          <Box>
            <AppButton
              bgColor={AppColors.ctaColor}
              color={'white'}
              height={'60px'}
              borderColor={'transparent !important'}
              onClick={onBtnClick}
              borderRadius={'full'}
            >
              Compare Plans
            </AppButton>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
