import { AppBanner, BannerCta } from '../../components/v2-banner.tsx/app-banner';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppColors as SiteColors } from '../../theme/index';
import { useBreakpointValue } from '@chakra-ui/react';
import { AppWebCard, AppWebCardProps } from '../../components/app/app-web-card/app-web-card';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { AsFeaturedInComponent } from '../../components/as-featured-in/as-featured-in';
import { PageAboutPage } from '../../constants/meta-data';
import { usePageMeta } from '../../hooks/usePageMeta';
import { heroBackground, mobileBackground } from '../../constants/heroImages';

export const AboutUs: React.FC = () => {
  usePageMeta(PageAboutPage);
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });
  const navigate = useNavigate();

  const aboutImage =
    'https://backlinkitprod.blob.core.windows.net/assets/de3b0030-af47-493f-8bd9-4f538d586e24_about-banner.webp-1736500296133';
  const cardOne =
    'https://backlinkitprod.blob.core.windows.net/assets/12f4b79a-8be7-4f11-89e3-608343f23539_about-us-card-1.webp-1736500297316';
  const cardTwo =
    'https://backlinkitprod.blob.core.windows.net/assets/5d2f022b-6c47-4c9a-b816-b39905735842_about-us-card-2.webp-1736500297713';

  const aboutUsButtons: BannerCta[] = [
    {
      label: 'Contact Us',
      bgColor: SiteColors.primary,
      borderColor: 'white',
      color: 'white',
      onClick: () => navigate(RoutesList.Contact),
    },
  ];
  const webCardData: AppWebCardProps[] = [
    {
      title: 'One Backlink at a time',
      description:
        'At Backlinkit, we’re on a mission to revolutionize the way businesses approach SEO. We understand the challenges of maintaining authoritative backlinks in a constantly shifting digital landscape. That’s why we created a cutting-edge platform that provides 24/7 backlink monitoring, helping you recover broken links and protect your online presence. Our innovative solution doesn’t just safeguard your domain authority—it drives meaningful results like improved search rankings and increased traffic. Backed by a team of SEO experts and powered by advanced technology, we’re here to help you unlock the full potential of your backlinks and achieve your growth goals.',
      image: cardOne,
    },
    {
      title: 'Shaping the future of SEO',
      description:
        'At Backlinkit, we envision a future where businesses of all sizes can harness the full power of their backlinks to dominate search rankings and thrive in the competitive digital space. We strive to make backlink management seamless, transparent, and accessible, empowering businesses to turn potential setbacks into growth opportunities. By blending advanced technology with intuitive design, we aim to set new standards in SEO innovation and help our clients unlock sustainable success in an ever-evolving online world.',
      image: cardTwo,
    },
  ];

  const getCardFlexDirection = (idx: number) => {
    if (idx === 1 && variant === 'desktop') {
      return 'row-reverse';
    }
    if (idx === 1 && variant !== 'desktop') {
      return 'column-reverse';
    }
    if (idx === 0 && variant !== 'desktop') {
      return 'column';
    }
    if (idx === 0 && variant === 'desktop') {
      return 'row';
    }
  };

  return (
    <>
      <AppComponentWrapper px="0">
        <AppBasicHero
          tagAlignment="center"
          pageName="About"
          textAlign={'center'}
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper px={{ base: 0, lg: 7, xl: 0 }}>
        <AppBanner
          // tag="About Us"
          title="You'll find BacklinkIt"
          description="In the dynamic world of digital landscapes, where rankings are king and backlinks hold the crown, you'll find Backlinkit."
          image={aboutImage}
          imageSize="small"
          btns={aboutUsButtons}
          bgColor={'white'}
          width={'100%'}
          mx={{ base: 0, xl: '150px' }}
          color={SiteColors.iconColor}
          py={8}
        />
      </AppComponentWrapper>

      <AppComponentWrapper flexDir={'column'} gap={8} px={{ base: 7, xl: 0 }}>
        {webCardData.map((card, idx) => (
          <AppWebCard
            key={`${card.title}-${idx}`}
            title={card.title}
            subTitle={card.subTitle}
            description={card.description}
            image={card.image}
            bgColor={SiteColors.appBackground}
            flexDir={getCardFlexDirection(idx)}
          />
        ))}
      </AppComponentWrapper>

      <AppComponentWrapper my={{base: 0, lg: '80px'}} mt={{base: '80px', lg: 0}} px={0}>
        <AsFeaturedInComponent />
      </AppComponentWrapper>
    </>
  );
};
