import { Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';

export interface AppWebCardProps extends FlexProps {
  title: string;
  subTitle?: string;
  description: string;
  image?: string;
}

export const AppWebCard: React.FC<AppWebCardProps> = ({
  title,
  subTitle,
  description,
  image,
  ...props
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });
  const titleSize = variant === 'desktop' ? '48px' : '22px';
  const subtitleSize = variant === 'desktop' ? '24px' : '18px';
  return (
    <Flex
      w={'100%'}
      align={'center'}
      justify={'center'}
      borderRadius={'3xl'}
      p={variant === 'desktop' ? 10 : 4}
      maxW={'1140px'}
      flexDir={variant === 'desktop' ? 'row' : 'column'}
      {...props}
    >
      <Image src={image} height={'260px'} width={'260px'} />
      <Flex width={'100%'} flexDir={'column'} gap={4} p={4}>
        <AppText fontWeight={'700'} fontSize={titleSize} as={'h2'}>
          {title}
        </AppText>
        {subTitle && (
          <AppText fontWeight={'600'} fontSize={subtitleSize}>
            {subTitle}
          </AppText>
        )}
        <AppText>{description}</AppText>
      </Flex>
    </Flex>
  );
};
