import { Box } from "@chakra-ui/react";

interface PaginationItemProps {
  page: number;
  isActive: boolean;
  variant: "mobile" | "desktop" | undefined;
  onClick: (page: number) => void;
}

const PaginationItem: React.FC<PaginationItemProps> = ({ page, isActive, variant, onClick }) => {
  return (
    <Box
      width="48px"
      height="100%"
      bg={isActive ? "#037DFC" : "#4A4F540D"}
      borderRadius="6px"
      padding={variant === "desktop" ? "10px" : "8px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color={isActive ? "#FFFFFF" : "#3D424D"}
      cursor="pointer"
      onClick={() => onClick(page)}
    >
      {page}
    </Box>
  );
};

export default PaginationItem;
