import { AppText, CreateUserLead } from '@backlinkit/shared';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { Flex, Image, useBreakpointValue, useToast } from '@chakra-ui/react';
import { AppFaqSection } from '../../components/app/app-faq-section/app-faq-section';
import { useFetchFeaturedFaqsQuery } from '../../store/api/faqApi';
import { RequestDemoForm } from '../../components/forms/request-demo-form/request-demo-form';
import { useCreateUserLeadMutation } from '../../store/api/userLeadApi';
import { heroBackground, mobileBackground } from '../../constants/heroImages';

export const RequestDemo: React.FC = () => {
  const toast = useToast();
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const [createTrigger] = useCreateUserLeadMutation();
  const requestImage =
    'https://backlinkitprod.blob.core.windows.net/assets/caa4dbe3-5dd7-4ee4-8917-7b69198cc50a_request-image.webp-1736503534534';
  const heroImage =
    'https://backlinkitprod.blob.core.windows.net/assets/a91c8799-ddb6-4791-81e2-9e0c489a3db6_request-demo-hero-img.webp-1736503535723';

  const { data: faqs, refetch: refetchFaqs } = useFetchFeaturedFaqsQuery({
    refetchOnMountArgChange: true,
  });

  const upsertUserLead = async (formData: CreateUserLead) => {
    try {
      await createTrigger({
        email: formData.email,
        displayName: formData.displayName,
        phoneNumber: formData.phoneNumber,
        subject: formData.subject,
        message: formData.message,
        agreeToTerms: formData.agreeToTerms,
        isDemoRequest: formData.isDemoRequest,
        companyName: formData.companyName,
        companySize: formData.companySize,
        companyRole: formData.companyRole,
        country: formData.country,
        language: formData.language,
        comment: formData.comment,
        learnAboutFeatures: formData.learnAboutFeatures,
        getTheMostOut: formData.getTheMostOut,
      }).unwrap();
      toast({
        title: 'Message sent',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <AppComponentWrapper>
        <AppBasicHero
          title="request-demo"
          pageName="Request-demo"
          image={heroImage}
          backgroundImage={variant === 'mobile' ? mobileBackground : heroBackground}
          px={{ base: 7, xl: 0 }}
        />
      </AppComponentWrapper>
      <AppComponentWrapper px={{ base: 7, xl: 0 }}>
        <Flex
          maxW={'1140px'}
          flexDir={variant === 'desktop' ? 'row' : 'column'}
          w={'100%'}
          borderRadius={'25px'}
          boxShadow={'lg'}
          minH={'600px'}
          my={12}
          align={'start'}
          p={6}
          pt={12}
        >
          <Flex flexDir={'column'} gap={4} align={'center'} w={{ sm: '100%', lg: '50%' }}>
            <AppText variant={'h2'}>Fill in the request</AppText>
            <Image src={requestImage} maxW={'300px'} w={'100%'} />
          </Flex>
          <Flex w={{ sm: '100%', lg: '50%' }}>
            <RequestDemoForm
              onSubmit={async (formData) => {
                await upsertUserLead(formData);
              }}
            />
          </Flex>
        </Flex>
      </AppComponentWrapper>

      <AppComponentWrapper bgColor={'white'} pb={12}>
        <AppFaqSection
          title="Frequently asked questions"
          description="We have put together some commonly asked questions"
          faqs={faqs || []}
          variant="light"
        />
      </AppComponentWrapper>
    </>
  );
};
