import { FAQ } from "../../../models/faq";


export const faqs: FAQ[] = [
    {
        question: 'What are backlinks, and why are they important for SEO?',
        answer: "Backlinks are links from one website to another, crucial for SEO as they signal trust and can improve a website's search engine ranking."
    },
    {
        question: "What are the key factors to consider when evaluating the quality of backlinks?",
        answer: "Source authority, relevance, link placement, diversity, and anchor text relevance are key factors."
    },
    {
        question: "How can one acquire high-quality backlinks for their website?",
        answer: "Acquire high-quality backlinks by creating great content, outreach, guest posting, content promotion, and broken link building."
    }
]