export const pricingFaqs = [
  {
    question: `What is included in each plan?`,
    answer: `All our plans include access to the same core features, such as backlink tracking, campaign management, advanced filters, link stats, alerts, and EFL reports. The only difference between plans is the limits on usage.`,
  },
  {
    question: `Do I need to commit to a long-term contract?`,
    answer: `<p>No! We believe in flexibility. Our plans are available with monthly billing and no long-term commitments, allowing you to upgrade, downgrade, or cancel anytime.</p> <br /> <p>For even greater freedom, we also offer a pay-as-you-go option, which is purely credit-based. This lets you purchase and use credits as needed, making it ideal for users who want to scale at their own pace without fixed monthly fees.</p>`,
  },
  {
    question: `Is there a free trial available?`,
    answer: `Yes, we offer a 7-day free trial with no credit card required. Experience the full capabilities of our tool risk-free.`,
  },
  {
    question: `What happens if I exceed my plan’s limits?`,
    answer: `If you exceed your usage limits, we’ll notify you, and you’ll have the option to upgrade to a higher plan for more capacity.`,
  },
  {
    question: `Can I change my plan later?`,
    answer: `Absolutely. You can easily upgrade or downgrade your plan at any time based on your evolving needs.`,
  },
  {
    question: `What payment methods do you accept?`,
    answer: `<p>We use Paddle as our payment provider, which supports major credit and debit cards (Visa, Mastercard, American Express, and more), PayPal, Apple Pay, Google Pay, and popular local payment methods like iDEAL and Alipay.</p> <br /> <p>For transactions over $100, wire transfers and ACH are also available. Contact us if you need invoicing options for larger plans.</p>`,
  },
  {
    question: `How does your tool help businesses grow?`,
    answer: `Our tool provides comprehensive backlink intelligence, enabling businesses to track, manage, and recover links effectively. By improving your link profile and boosting domain authority, we help drive better search rankings, quality traffic, and overall business growth.`,
  },
  {
    question: `What support is included in my plan?`,
    answer: `All plans come with access to our dedicated support team via email and chat. Higher-tier plans may include priority support.`,
  },
  {
    question: `Is my data safe?`,
    answer: `Absolutely. We use industry-standard security measures to ensure your data is protected at all times.`,
  },
  {
    question: `What happens after the trial ends?`,
    answer: `At the end of your free trial, you can choose a plan that fits your needs. If no plan is selected, your account will be paused, but your data will remain accessible should you choose to subscribe later.`,
  },
];
