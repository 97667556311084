import { Flex, FlexProps, Icon, Image } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';
import { AppColors as SiteColors } from '../../../theme';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { getFormatDate } from '../../../utils/date-format';
import { ArticleDto } from '../../../pages/article/mocks/article-mock';
import { useNavigate } from 'react-router-dom';

export interface AppArticleCardProps extends FlexProps {
  article: ArticleDto;
}

export const AppArticleCard: React.FC<AppArticleCardProps> = ({ article, ...props }) => {
  const date = getFormatDate(article.datePublished);
  const navigate = useNavigate();
  return (
    <Flex
      flexDir={'column'}
      borderRadius={'xl'}
      height={'100%'}
      mx={4}
      boxShadow={'lg'}
      py={3}
      onClick={() => {
        navigate(`/article/${article.slug}`);
      }}
      _hover={{
        cursor: 'pointer'
      }}
      {...props}
    >
      <Flex
        pos={'relative'}
        height={'325px'}
        width={'100%'}
        bgImage={article.articleBannerImageUrl}
        borderTopRadius={'xl'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
      />
      <Flex p={4} flexDir={'column'} gap={7} width={'100%'}>
        <Flex align={'center'} justify={'space-between'} width={'100%'}>
          <Flex gap={4} align={'center'}>
            <Icon as={FaRegCalendarAlt} color={SiteColors.primary} />
            <AppText>{`Published : ${date}`}</AppText>
          </Flex>
          <AppText>{`Reading Time: ${article.readTime}min`}</AppText>
        </Flex>
        <AppText fontSize={'24px'} fontWeight={'bold'}>
          {article.title}
        </AppText>
        <Flex align={'center'} justify={'space-between'} width={'100%'}>
          <AppText>{`Updated : ${getFormatDate(article.lastUpdated)}`}</AppText>
          <Flex align={'center'} gap={2}>
            <Image boxSize={'30px'} src={article.authorIcon} borderRadius={'full'} />
            <AppText>{`${article.author}`}</AppText>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
