import React from 'react';
import { Flex, Alert, Box } from '@chakra-ui/react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { AppColors } from '../../theme';
import { AppText } from '../app-text';
import { FaCheckCircle } from 'react-icons/fa';

export type AppAlertItemListProps = {
  messages: string[];
  alertType: 'info' | 'warning' | 'error' | 'success';
};

export const AppAlertItemList: React.FC<AppAlertItemListProps> = ({ messages, alertType }) => {
  const getAlertTypeIcon = (): JSX.Element => {
    switch (alertType) {
      case 'warning':
        return <FiAlertCircle color={getIconColor()} size={'24px'} />;
      case 'error':
        return <FiAlertTriangle color={getIconColor()} size={'24px'} />;
      case 'info':
        return <IoMdInformationCircleOutline color={getIconColor()} size={'24px'} />;
      case 'success':
        return <FaCheckCircle color={getIconColor()} size={'24px'} />;
      default:
        return <IoMdInformationCircleOutline color={getIconColor()} size={'24px'} />;
    }
  };

  const getIconColor = (): string => {
    switch (alertType) {
      case 'warning':
        return 'orange';
      case 'error':
        return 'red';
      case 'info':
        return '#24b4c7';
      case 'success':
        return '#12B757';
      default:
        break;
    }

    return '';
  };

  return (
    <Alert
      justifyContent="space-between"
      alignContent="center"
      mb={2}
      status={alertType}
      borderRadius={'full'}
    >
      <Flex direction={'row'} justifyContent={'start'} alignItems="center">
        <Box mr={4} justifyContent={'start'} alignContent="center">
          {getAlertTypeIcon()}
        </Box>
        <Flex direction={'column'} gap={1}>
          {messages.map((x) => (
            <AppText color={AppColors.textColor} fontSize={'14px'} key={x}>
              {x}
            </AppText>
          ))}
        </Flex>
      </Flex>
    </Alert>
  );
};
