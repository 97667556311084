import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PricingPage } from '../pages/pricing-v2/pricing-page';
import { HomePage } from '../pages/home/home';
import { ChangeLog } from '../pages/change-log/change-log';
import { ManagedServices } from '../pages/managed-services/managed-services';
import { AboutUs } from '../pages/about-us/about-us';
import { HomeV2 } from '../pages/home-v2/home-page';
import { LegalPage } from '../pages/legal/legal';
import { PrivacyPolicy } from '../pages/privacy-policy/privacy-policy';
import KnowledgePage from '../pages/knowledge/knowledge';
import { RequestDemo } from '../pages/request-demo/request-demo';
import { Contact } from '../pages/contact/contact';
import { WebTerms } from '../pages/terms-and-conditions/terms-and-conditions';
import { Cookies } from '../pages/cookies/cookies';
import { DisclaimerPage } from '../pages/disclaimer/disclaimer';
import { DefinitionsPage } from '../pages/definitions/definitions';
import ArticlePage from '../pages/article/article-page';
import { RouterOutlet } from './router-outlet';

export enum RoutesList {
  Index = '/',
  Home = '/home',
  Pricing = '/pricing',
  ChangeLog = '/changelog',
  ManagedServices = '/managed-services',
  AboutUs = '/about',
  Tools = '/tools',
  Legal = '/legal',
  PrivacyPolicy = '/legal/privacy',
  RequestDemo = '/request-demo',
  Contact = '/contact',
  TermsAndConditions = '/legal/terms',
  Cookies = '/cookies',
  Definitions = '/legal/definitions',
  Disclaimer = '/legal/disclaimer',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<RouterOutlet />}>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/home" element={<HomeV2 />} /> */}
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/changelog" element={<ChangeLog />} />
          <Route path="/managed-services" element={<ManagedServices />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/knowledge" element={<KnowledgePage />} />
          <Route path="/legal/privacy" element={<PrivacyPolicy />} />

          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/article/:slug" element={<ArticlePage />} />
          <Route path="/legal/terms" element={<WebTerms />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/legal/disclaimer" element={<DisclaimerPage />} />
          <Route path="/legal/definitions" element={<DefinitionsPage />} />

          {/* <Route path="/sitemap.xml" /> */}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
          <Route path="/articles" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
