import { Flex, Box, useBreakpointValue, FlexProps } from '@chakra-ui/react';
import { AppCarousel, AppText } from '@backlinkit/shared';
import { AppArticleCard } from '../app-article-card/app-article-card';
import { ArticleDto } from '../../../pages/article/mocks/article-mock';

export interface AppArticleBannerProps extends FlexProps {
  tag?: string;
  title: string;
  articles?: ArticleDto[];
  article?: ArticleDto;
}

export const AppArticleBanner: React.FC<AppArticleBannerProps> = ({
  tag,
  title,
  articles,
  article,
  ...props
}) => {
  const padding = useBreakpointValue({
    base: 4,
    lg: 0,
  });
  return (
    <Flex
      maxW={'1140px'}
      width={'100%'}
      minH={'450px'}
      height={'100%'}
      gap={'40px'}
      flexDir={'column'}
      justify={'center'}
      py={{ base: '45px', lg: '90px' }}
      {...props}
    >
      <Box px={padding}>
        <AppText fontWeight={'700'}>{tag}</AppText>
        <AppText variant={'h3'} mt={4}>{title}</AppText>
      </Box>
      {articles && articles?.length > 0 && (
        <AppCarousel showDots={true} swipeable={true} arrows={true} desktopItems={2}>
          {articles?.map((article, idx) => (
            <Box key={`${article.title}-${idx}`} my={{base: 4, lg: 10}}>
              <AppArticleCard article={article} />
            </Box>
          ))}
        </AppCarousel>
      )}
      {article && <AppArticleCard article={article} />}
    </Flex>
  );
};
