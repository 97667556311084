import React from 'react';
import { Outlet } from 'react-router-dom';
import { BaseLayout } from '../layouts/base-layout';

const RouterOutlet: React.FC = () => {
  return (
    <React.Fragment>
      <BaseLayout>
        <Outlet />
      </BaseLayout>
    </React.Fragment>
  );
};

export { RouterOutlet };
