import { Flex } from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { AppColors } from '../../theme';
import { useState } from 'react';

export interface AppCarouselProps {
  desktopItems?: number;
  mobileItems?: number;
  children: React.ReactNode[];
  swipeable?: boolean;
  draggable?: boolean;
  showDots?: boolean;
  autoPlay?: boolean;
  arrows?: boolean;
  keyBoardControl?: boolean;
  autoPlaySpeed?: number;
  reverseDirection?: boolean;
}

export const AppCarousel: React.FC<AppCarouselProps> = ({
  desktopItems = 2,
  mobileItems = 1,
  swipeable = false,
  draggable = true,
  showDots = false,
  autoPlay = true,
  keyBoardControl = true,
  autoPlaySpeed = 9000,
  reverseDirection,
  children,
}) => {
  const [focusItem, setFocusItem] = useState<number>(0);
  const CarouselResponsiveStyles = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: desktopItems,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: mobileItems,
    },
  };
  return (
    <>
      <Carousel
        swipeable={swipeable}
        draggable={draggable}
        responsive={CarouselResponsiveStyles}
        ssr={true}
        infinite={true}
        autoPlay={autoPlay}
        arrows={false}
        renderButtonGroupOutside={true}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        keyBoardControl={keyBoardControl}
        autoPlaySpeed={autoPlaySpeed}
        rtl={reverseDirection}
        beforeChange={() => {
          setFocusItem((prev) => {
            const count = prev + 1;
            return count === children.length ? 0 : count;
          });
        }}
      >
        {children}
      </Carousel>
      {showDots && (
        <Flex align={'center'} gap={2} width={'100%'} justify={'center'}>
          {children.map((item, idx) => (
            <Flex
              boxSize={'15px'}
              borderRadius={'full'}
              border={`1px solid ${AppColors.secondary}`}
              bgColor={idx === focusItem ? AppColors.secondary2 : 'white'}
            />
          ))}
        </Flex>
      )}
    </>
  );
};
