import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Button,
  Box,
  useBreakpointValue,
  IconButton,
  Icon,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link,
  AccordionIcon,
} from '@chakra-ui/react';
import { AppText, AppButton, NavItem } from '@backlinkit/shared';
import { AppColors } from '../../theme';
import { NavbarLink } from '../../models/nav-bar';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useClientPanel } from '../../providers/panel/web-panel-service';
import { IoMdArrowDropdown } from 'react-icons/io';
import { RoutesList } from '../../router/router';

export interface NavBarProps {
  logo: string;
  links: NavbarLink[];
  login: () => void;
  signUp: () => void;
}
const NavLink = (item: NavItem) => {
  return (
    <Flex
      align={'center'}
      as={RouterLink}
      to={item.href ?? ''}
      px={2}
      gap={3}
      py={1}
      width={'100%'}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bgColor: 'transparent',
      }}
      color={'black'}
      fontWeight={'500'}
    >
      {item.label}
    </Flex>
  );
};

export const NavBar: React.FC<NavBarProps> = ({ logo, links, login, signUp }) => {
  const webPanel = useClientPanel();
  const navigate = useNavigate();
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });

  const handleMenu = () => {
    webPanel({
      size: 'full',
      render: (onSubmit) => {
        return (
          <Flex bgColor={AppColors.primary} flexDir={'column'} gap={4}>
            <Accordion allowToggle>
              {links.map((item, idx) => (
                <Flex flexDir={'column'}>
                  {item.linkedItems && (
                    <AccordionItem key={`${item.title}-${idx}`} border={'none'} mt={2}>
                      <AccordionButton
                        display={'flex'}
                        width={'100%'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <AppText color={'white'} fontSize={'18px'}>
                          {item.title}
                        </AppText>
                        <AccordionIcon color={'white'} />
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex flexDir={'column'} gap={2}>
                          {item.linkedItems?.map((link, idx) => (
                            <Link
                              key={`${link.title}-${idx}`}
                              onClick={() => {
                                onSubmit();
                              }}
                              href={link.href}
                              color={'white'}
                            >
                              <AppText fontSize={'16px'}>{link.title}</AppText>
                            </Link>
                          ))}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  {item.href && (
                    <Link
                      key={`${item.title}-${idx}`}
                      onClick={() => {
                        onSubmit();
                      }}
                      href={item.href}
                      color={'white'}
                      ml={4}
                      my={2}
                    >
                      <AppText color={'white'} fontSize={'18px'}>
                        {item.title}
                      </AppText>
                    </Link>
                  )}
                </Flex>
              ))}
            </Accordion>
          </Flex>
        );
      },
    });
  };

  const renderMenu = (item: NavbarLink) => {
    return (
      <>
        {item.linkedItems && (
          <Menu>
            <MenuButton
              as={Button}
              bgColor={'transparent'}
              color={'white'}
              fontWeight={'700'}
              _hover={{
                bgColor: 'transparent',
              }}
              px={1}
              display={'flex'}
              alignItems={'center'}
              width={'100%'}
            >
              <Flex align={'center'} gap={2}>
                <AppText>{item.title}</AppText>
                <IoMdArrowDropdown color="white" />
              </Flex>
            </MenuButton>
            <MenuList borderRadius={'xl'}>
              {item.linkedItems?.map((link, idx) => (
                <MenuItem key={`${link.title}-${idx}`}>
                  <NavLink href={link.href} label={link.title} />
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        {item.href && (
          <Flex
            align={'center'}
            as={RouterLink}
            to={item.href ?? ''}
            px={2}
            gap={3}
            py={1}
            width={'100%'}
            rounded={'md'}
            _hover={{
              textDecoration: 'none',
              bgColor: 'transparent',
            }}
          >
            <AppText color={'white'} fontWeight={'500'}>
              {item.title}
            </AppText>
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex
      minH={'50px'}
      p={{ base: 1, xl: '18px 135px', md: 1 }}
      width={'100%'}
      justify={'center'}
      align={'center'}
      bgColor={AppColors.primary}
    >
      <Flex width={'100%'} maxWidth={'1140px'} justify={'space-between'} align={'center'}>
        {variant === 'desktop' && (
          <>
            <Flex>
              <Image
                height={'35px'}
                w={'auto'}
                src={logo}
                onClick={() => navigate(RoutesList.Index)}
                _hover={{ cursor: 'pointer' }}
              />
            </Flex>
            <Flex align={'center'} gap={'20px'}>
              {links.map((item, idx) => (
                <Box key={`${item.title}-${idx}`}>{renderMenu(item)}</Box>
              ))}
            </Flex>
          </>
        )}
        {variant === 'tablet' && (
          <Flex align={'center'} width={'100%'}>
            <IconButton
              _hover={{
                bgColor: 'transparent',
              }}
              bgColor={'transparent'}
              aria-label="mobile-menu"
              onClick={handleMenu}
            >
              <Icon as={HamburgerIcon} color={'white'} />
            </IconButton>
            <Image
              src={logo}
              maxH={'28px'}
              width={'auto'}
              onClick={() => navigate(RoutesList.Index)}
            />
          </Flex>
        )}
        {variant === 'mobile' && (
          <Flex align={'center'} width={'100%'}>
            <IconButton
              _hover={{
                bgColor: 'transparent',
              }}
              bgColor={'transparent'}
              aria-label="mobile-menu"
              onClick={handleMenu}
            >
              <Icon as={HamburgerIcon} color={'white'} />
            </IconButton>
            <Image
              src={logo}
              maxH={'20px'}
              width={'auto'}
              onClick={() => navigate(RoutesList.Index)}
            />
          </Flex>
        )}
        <Flex align={'center'} gap={variant === 'mobile' ? 1 : 4}>
          <AppButton
            variant={'ghost'}
            color={'white'}
            onClick={login}
            borderRadius={'full'}
            size={variant === 'mobile' ? 'xs' : 'md'}
            p={variant === 'mobile' ? 4 : 'auto'}
          >
            Login
          </AppButton>
          <AppButton
            bgColor={AppColors.ctaColor}
            borderColor={'none'}
            onClick={signUp}
            borderRadius={'full'}
            color={'white'}
            size={variant === 'mobile' ? 'xs' : 'md'}
            p={variant === 'mobile' ? 4 : 'auto'}
          >
            Sign up
          </AppButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
