import { MdOutlinePrivacyTip } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import { MdOutlinePolicy } from "react-icons/md";

import { LegalCardProps } from '../models/legal';

export const legalItems: LegalCardProps[] = [
  {
    title: 'Privacy Policy',
    link: '/legal/privacy',
    icon: MdOutlinePrivacyTip,
  },
  {
    title: 'Terms of Service',
    link: '/legal/terms',
    icon: GrDocumentText,
  },
  {
    title: 'Disclaimer',
    link: '/legal/disclaimer',
    icon: MdOutlinePrivacyTip,
  },
  {
    title: 'Cookie Policy',
    link: '/cookies',
    icon: MdOutlinePolicy,
  },
  {
    title: 'Definitions',
    link: '/legal/definitions',
    icon: GrDocumentText
  }
];
