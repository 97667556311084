import { LuSearchCheck } from 'react-icons/lu';
import { As } from '@chakra-ui/react';
import { ImCogs } from 'react-icons/im';
export interface KnowledgeCard {
  id: number;
  articleLink?: string;
  icon: string | As | any;
  title: string;
  description: string;
}

export const knowledgeCards: KnowledgeCard[] = [
  {
    id: 1,
    icon: LuSearchCheck,
    title: 'SEO',
    description: 'With the aid of more than 20 tools, raise search engine ranks and traffic.',
    articleLink: 'article/what-are-backlinks-in-seo',
  },
  {
    id: 2,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/592e8997-9885-4cbf-bf10-bcb23930088c_advertising.svg-1736511427534',
    title: 'Advertising',
    description: 'Examine the advertising campaigns of your rivals before starting your own.',
    articleLink: 'article/market-research',
  },
  {
    id: 3,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/592e8997-9885-4cbf-bf10-bcb23930088c_advertising.svg-1736511427534',
    title: 'Trends',
    description: 'Examine the market, compare yourself to rivals, and keep an eye on new trends.',
    articleLink: '',
  },
  {
    id: 4,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/944e0786-86fb-471d-b64a-da0e70d5d5d3_teamwork.svg-1736511428746',
    title: 'Teamwork',
    description: 'Maintain communication with your team, manage users, and exchange insights.',
    articleLink: '',
  },
  {
    id: 5,
    icon: ImCogs,
    title: 'Integrations',
    description:
      'Power up your marketing by connecting your other apps and tools with Semrush data.',
    articleLink: '',
  },
  {
    id: 6,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/b97c49f8-1fd6-4df9-a75e-a9159377bc90_media.svg-1736511428508',
    title: 'Social Media',
    description: 'Plan, publish, and monitor performance on all major social media channels.',
    articleLink: '',
  },
  {
    id: 2,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/592e8997-9885-4cbf-bf10-bcb23930088c_advertising.svg-1736511427534',
    title: 'Advertising',
    description: 'Examine the advertising campaigns of your rivals before starting your own.',
    articleLink: '',
  },
  {
    id: 3,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/39ea6210-6f83-40b1-a6c6-7671d7f6503b_trends.svg-1736511428996',
    title: 'Trends',
    description: 'Examine the market, compare yourself to rivals, and keep an eye on new trends.',
    articleLink: '',
  },
  {
    id: 4,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/944e0786-86fb-471d-b64a-da0e70d5d5d3_teamwork.svg-1736511428746',
    title: 'Teamwork',
    description: 'Maintain communication with your team, manage users, and exchange insights.',
    articleLink: '',
  },
  {
    id: 5,
    icon: ImCogs,
    title: 'Integrations',
    description:
      'Power up your marketing by connecting your other apps and tools with Semrush data.',
    articleLink: '',
  },
  {
    id: 6,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/b97c49f8-1fd6-4df9-a75e-a9159377bc90_media.svg-1736511428508',
    title: 'Social Media',
    description: 'Plan, publish, and monitor performance on all major social media channels.',
    articleLink: '',
  },
  {
    id: 2,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/592e8997-9885-4cbf-bf10-bcb23930088c_advertising.svg-1736511427534',
    title: 'Advertising',
    description: 'Examine the advertising campaigns of your rivals before starting your own.',
    articleLink: '',
  },
  {
    id: 3,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/39ea6210-6f83-40b1-a6c6-7671d7f6503b_trends.svg-1736511428996',
    title: 'Trends',
    description: 'Examine the market, compare yourself to rivals, and keep an eye on new trends.',
    articleLink: '',
  },
  {
    id: 4,
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/944e0786-86fb-471d-b64a-da0e70d5d5d3_teamwork.svg-1736511428746',
    title: 'Teamwork',
    description: 'Maintain communication with your team, manage users, and exchange insights.',
    articleLink: '',
  },
  {
    id: 5,
    icon: ImCogs,
    title: 'Integrations',
    description:
      'Power up your marketing by connecting your other apps and tools with Semrush data.',
    articleLink: '',
  },
];
