interface PageMetaData {
  url: string;
  title: string;
  description: string;
}

export const PageMetaArticlePage: PageMetaData = {
  url: '/article/what-are-backlinks-in-seo',
  title: 'What Are Backlinks in SEO?—We Explain Backlinking in 2025',
  description:
    'A backlink, or inbound link, is a hyperlink from one website to another, boosting SEO by signalling trust and authority to search engines.',
};

export const PageMetaHomePage: PageMetaData = {
  url: '/',
  title: 'Backlinkit: Streamline Your SEO and Boost Rankings',
  description:
    'Simplify your SEO strategy with Backlinkit. Build, track, and recover backlinks effortlessly to enhance your rankings and achieve long-term success.',
};

export const PageMetaPricingPage: PageMetaData = {
  url: '/pricing',
  title: 'Backlinkit Pricing: Affordable Plans for Every SEO Need',
  description:
    'Explore flexible pricing plans tailored to individuals, businesses, and agencies. Backlinkit offers scalable solutions to fit your SEO budget and goals.',
};

export const PageChangelogPage: PageMetaData = {
  url: '/changelog',
  title: 'Backlinkit Updates: Latest Features and Improvements',
  description:
    'Stay up to date with the latest Backlinkit features, updates, and improvements. See how we’re enhancing your SEO experience with every release.',
};

export const PageAboutPage: PageMetaData = {
  url: '/about',
  title: 'About Backlinkit: Revolutionizing SEO Backlink Management',
  description: `Discover Backlinkit's mission to simplify SEO and empower businesses to build stronger online visibility. Learn more about our story and vision.`,
};

export const PageContactPage: PageMetaData = {
  url: '/contact',
  title: 'Contact Backlinkit: We’re Here to Help',
  description:
    'Have questions or need support? Contact the Backlinkit team today. We’re here to assist you with your SEO journey.',
};

export const PageLegalPage: PageMetaData = {
  url: '/legal',
  title: 'Backlinkit Legal Hub: Terms, Privacy, and Policies',
  description:
    'Find all legal information for Backlinkit, including terms of service, privacy policies, and compliance details. Stay informed about our practices.',
};

export const PagePrivacyPage: PageMetaData = {
  url: '/legal/privacy',
  title: 'Backlinkit Privacy Policy: Your Data, Our Commitment',
  description:
    'Learn how Backlinkit protects your privacy and handles your personal data. Read our privacy policy to understand your rights and our practices.',
};

export const PageTermsConditionsPage: PageMetaData = {
  url: '/legal/terms',
  title: 'Backlinkit Terms of Service: User Agreement & Guidelines',
  description:
    'Review the Backlinkit terms of service to understand our policies, user responsibilities, and service guidelines. Stay informed and compliant.',
};

export const PageDisclaimerPage: PageMetaData = {
  url: '/legal/disclaimer',
  title: 'Backlinkit Disclaimer: Limitations and Responsibilities',
  description:
    'Read the Backlinkit disclaimer to understand the scope of our services, limitations, and responsibilities in delivering SEO solutions.',
};

export const PageCookiePage: PageMetaData = {
  url: '/cookies',
  title: 'Backlinkit Cookie Policy: Transparency in Tracking',
  description:
    'Understand how Backlinkit uses cookies to enhance your experience. Read our cookie policy for details on tracking, data usage, and preferences.',
};

export const PageDefinitionsPage: PageMetaData = {
  url: '/legal/definitions',
  title: 'Backlinkit Definitions: Key Terms Explained',
  description:
    'Get clear explanations of key terms and concepts used across Backlinkit’s platform. Simplify SEO jargon with our definitions page.',
};
