import { Flex, Stack, Text, Box, Icon, useBreakpointValue, SimpleGrid } from '@chakra-ui/react';
import { AppColors, AppText } from '@backlinkit/shared';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AppButton, ProductDto } from '@backlinkit/shared';
import { useEffect } from 'react';
import { baseUrl } from '../../../constants/nav-items';

export interface BannerProps {
  products: ProductDto[];
  annaulPrices?: boolean;
}

export const AppPriceBannerCards: React.FC<BannerProps> = ({ products, annaulPrices }) => {
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8,
  });

  const getColor = (x: ProductDto): string => (x.name === 'Basic Plan' ? 'white' : 'black');

  useEffect(() => {}, [annaulPrices]);

  return (
    <SimpleGrid columns={1} gap={cardGap} w={'100%'}>
      {products.map((x) => {
        return (
          <Flex
            key={x.id}
            justify={'space-between'}
            flexDir={{ base: 'column', md: 'row' }}
            pos={'relative'}
            boxShadow={'xl'}
            gap={{ base: 3, md: 8 }}
            w={'100%'}
            flex={1}
            direction={'row'}
            borderRadius={'2xl'}
            border={
              x.name === 'Basic Plan'
                ? `1.5px solid ${AppColors.secondary2}`
                : `1px solid ${AppColors.appBorder}`
            }
            bgColor={x.name === 'Basic Plan' ? AppColors.secondary2 : 'white'}
            p={6}
          >
            <Flex
              flexDir={'column'}
              align={'start'}
              bgColor={x.name === 'Basic Plan' ? `${AppColors.secondary2}` : `white`}
              pos={'relative'}
              pb={4}
              gap={4}
              w={{ base: '100%', md: '35%' }}
            >
              <Flex gap={3} align={'center'}>
                <Box
                  height={'35px'}
                  width={'3px'}
                  bgColor={x.name === 'Basic Plan' ? 'white' : AppColors.ctaColor}
                />
                <AppText
                  fontWeight={'600'}
                  textAlign={'center'}
                  fontSize={{ base: '18px', lg: '24px' }}
                  color={x.name === 'Basic Plan' ? 'white' : AppColors.ctaColor}
                >
                  {x.name}
                </AppText>
              </Flex>
              <Flex align={'center'} justify={'center'} pos={'relative'} gap={2}>
                {x.price > 0 && (
                  <Text
                    pos={'absolute'}
                    color={x.name === 'Basic Plan' ? 'white' : 'black'}
                    left={0}
                    top={0}
                  >
                    $
                  </Text>
                )}
                <Text
                  textAlign={'center'}
                  fontSize={'2xl'}
                  fontWeight={'700'}
                  color={getColor(x)}
                  marginLeft={'15px'}
                >
                  {annaulPrices ? `${(x.price * 12) / 0.75}/` : `${x.price}/`}
                </Text>
                <Text fontWeight={'500'} color={getColor(x)} mt={2}>
                  {annaulPrices ? 'Yearly' : 'Monthly'}
                </Text>
              </Flex>
              <Box width={'100%'}>
                <AppButton
                  borderRadius={'full'}
                  borderColor={`${AppColors.secondary2} !important`}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  color={x.name === 'Basic Plan' ? AppColors.secondary2 : 'white'}
                  bgColor={x.name === 'Basic Plan' ? 'white' : AppColors.secondary2}
                  onClick={() => {
                    window.open(
                      `${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`,
                      '_self'
                    );
                  }}
                >
                  {'Start 7 Day Trial'}
                </AppButton>
              </Box>
            </Flex>
            <Box w={'2px'} height={'50%'} bgColor={AppColors.appBackground} />
            <Stack spacing={3} w={'100%'}>
              <SimpleGrid spacing={3} columns={{ base: 1, xl: 2 }}>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={x.name === 'Basic Plan' ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(x)}>{`${x.linkLimit} links`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={x.name === 'Basic Plan' ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(x)}>{`${x.userLimit} User Seats`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={x.name === 'Basic Plan' ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(x)}>{`${x.domainLimit} Domains`}</Text>
                </Flex>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={x.name === 'Basic Plan' ? 'white' : AppColors.secondary2}
                  />
                  <Text color={getColor(x)}>{`${x.freeCredits} Free Credits`}</Text>
                </Flex>
              </SimpleGrid>
              <Flex width={'100%'} mt={'20px'}>
                <Text color={x.name === 'Basic Plan' ? 'white' : 'inherit'} width={'100%'}>
                  {x.description}
                </Text>
              </Flex>
            </Stack>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
