import { Box, Checkbox, Flex, FormLabel, Link, useBreakpointValue } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  AppText,
  AppInput,
  AppButton,
  SelectOption,
  SelectListFilter,
  AppColors,
  AppCellInput,
  AppCheckbox,
  AppAlertItem,
  UserLead,
  CreateUserLead,
} from '@backlinkit/shared';
import { CreateReqestDemo, DemoUserRole } from '../../../models/request-demo';
import { BaseFormProps } from '../../../models/base';
import { countryOptions } from '../../../constants/countries';
import { languageOptions } from '../../../constants/languages';
import { useEffect, useState } from 'react';

const demoDefaults: CreateUserLead = {
  email: '',
  displayName: '',
  phoneNumber: '',
  subject: '',
  message: '',
  agreeToTerms: false,
  isDemoRequest: true,
  companyName: '',
  companySize: '',
  companyRole: '',
  country: '',
  language: '',
  comment: '',
  learnAboutFeatures: false,
  getTheMostOut: false,
};

const demoDataSchema = yup.object({
  userName: yup.string().required('Field is required'),
  companyName: yup.string(),
  companySize: yup.number(),
  userRole: yup.number(),
  email: yup.string().required('Field is required'),
  contactNumber: yup.number().required('Field is required'),
  country: yup.string().required('Field is required'),
  language: yup.string().required('Field is required'),
  callPurpose: yup.string(),
  comment: yup.string(),
});

type RequestDemoProps<T> = {
  form?: CreateUserLead;
} & BaseFormProps<T>;

export const RequestDemoForm: React.FC<RequestDemoProps<CreateUserLead>> = ({ form, onSubmit }) => {
  const {
    control,
    formState: { isValid, errors },
    getValues,
    setValue,
    handleSubmit,
  } = useForm<CreateUserLead>({
    defaultValues: form || demoDefaults,
    resolver: yupResolver(demoDataSchema),
    mode: 'onChange',
  });

  const [warning, setWarning] = useState<boolean>(false);
  const [features, setFeatures] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<boolean>(false);
  const [legalAgreement, setLegalAgreement] = useState<boolean>(false);

  const companySizeOptions: SelectOption[] = [
    { value: '1-10', label: '1-10 employees' },
    { value: '11-50', label: '11-50 employees' },
    { value: '51-200', label: '51-200 employees' },
    { value: '201-500', label: '201-500 employees' },
    { value: '501-1000', label: '501-1000 employees' },
    { value: '1001-5000', label: '1001-5000 employees' },
  ];
  const userRoleOptions: SelectOption[] = [
    {
      value: DemoUserRole.Employer,
      label: 'Employer',
    },
    {
      value: DemoUserRole.Employee,
      label: 'Employee',
    },
  ];

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const handleFormSubmit = () => {
    const formValues = getValues();
    if (!legalAgreement) {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 9000);
    }
    if (legalAgreement) {
      onSubmit && onSubmit(formValues);
    }
  };

  useEffect(() => {
    if (currentProduct) {
      setFeatures(false);
    }
    if (features) {
      setCurrentProduct(false);
    }
  }, [features, currentProduct]);

  return (
    <Flex gap={4} p={4} flexDir={'column'} w={'100%'}>
      <AppInput<CreateUserLead>
        name={'displayName'}
        control={control}
        error={errors.displayName}
        label={'Your Name'}
        placeHolder="Your Name"
        variant="default"
      />
      <Flex
        width={'100%'}
        align={'center'}
        gap={4}
        flexDir={variant === 'mobile' ? 'column' : 'row'}
      >
        <AppInput<CreateUserLead>
          name={'companyName'}
          control={control}
          error={errors.companyName}
          label={'Company Name'}
          placeHolder="Company Name"
          width={'100%'}
          variant="default"
        />
        <Box w={'100%'}>
          <FormLabel>Company Size</FormLabel>
          <SelectListFilter
            name="companySize"
            options={companySizeOptions}
            isInModal
            showClear
            width={'100%'}
            placeholder="Select Size"
            onSelectionChange={(item: SelectOption[]) => {
              setValue('companySize', item[0].value);
            }}
          />
        </Box>
      </Flex>
      <Box w={'100%'}>
        <FormLabel>Your role</FormLabel>
        <SelectListFilter
          name="userRole"
          options={userRoleOptions}
          isInModal
          showClear
          w={'100%'}
          placeholder="Select your role"
          onSelectionChange={(item: SelectOption[]) => {
            setValue('companyRole', item[0].value);
          }}
        />
      </Box>
      <AppInput<CreateUserLead>
        name={'email'}
        control={control}
        error={errors.email}
        label={'Contact email'}
        variant="default"
      />
      <AppCellInput<CreateUserLead>
        name={'phoneNumber'}
        error={errors.phoneNumber}
        control={control}
        label={'Contact Number'}
        inputStyles={{
          height: '60px',
          width: '100%',
          borderRadius: '15px',
          paddingLeft: '75px',
        }}
        buttonStyle={{
          backgroundColor: 'transparent',
          borderTopLeftRadius: '15px',
          borderBottomLeftRadius: '15px',
          padding: '0 15px',
        }}
      />
      <Flex align={'center'} gap={4} w={'100%'} flexDir={{ base: 'column', lg: 'row' }}>
        <Box w={'100%'}>
          <FormLabel>Country</FormLabel>
          <SelectListFilter
            name={'country'}
            options={countryOptions}
            isInModal
            placeholder="Country"
            onSelectionChange={(item: SelectOption[]) => {
              setValue('country', item[0].value);
            }}
          />
        </Box>
        <Box w={'100%'}>
          <FormLabel>Language</FormLabel>
          <SelectListFilter
            name={'language'}
            options={languageOptions}
            isInModal
            placeholder="Language"
            onSelectionChange={(item: SelectOption[]) => {
              setValue('language', item[0].value);
            }}
          />
        </Box>
      </Flex>
      <AppText>What do you want to achieve with this call?</AppText>
      <Flex align={'center'} gap={4}>
        <Checkbox
          isChecked={features === true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFeatures(true);
            setCurrentProduct(false);
            setValue('learnAboutFeatures', e.currentTarget.checked);
          }}
        />
        <AppText>Learn about features with this call?</AppText>
      </Flex>
      <Flex align={'center'} gap={4}>
        <Checkbox
          isChecked={currentProduct === true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentProduct(true);
            setFeatures(false);
            setValue('getTheMostOut', e.currentTarget.checked);
          }}
        />
        <AppText>Get the most out of what I already use</AppText>
      </Flex>
      <AppInput<CreateUserLead>
        name={'comment'}
        error={errors.comment}
        label="Comment"
        control={control}
      />
      <Flex align={'center'} gap={4}>
        <Checkbox
          name={'legalAgreement'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('agreeToTerms', e.currentTarget.checked);
            setLegalAgreement(e.currentTarget.checked);
          }}
        />
        <AppText>
          I agree to the{' '}
          <Link href={'#'} color={AppColors.secondary2}>
            Terms of Services
          </Link>{' '}
          and <Link color={AppColors.secondary2}>Privacy Policy</Link>
        </AppText>
      </Flex>
      <AppButton
        borderRadius={'full'}
        bgColor={AppColors.secondary2}
        color={'white'}
        onClick={handleFormSubmit}
      >
        Request
      </AppButton>
      {warning && (
        <AppAlertItem
          item={{
            name: 'Terms and Conditions',
            nameLabel: undefined,
            alertType: 'warning',
            alertMessage:
              'Please read through and agree to our terms and conditions and privacy policies before you request a demo',
            alertMessageLabel: undefined,
            alertNote: undefined,
            alertNoteLabel: undefined,
            entityId: undefined,
            alertKey: undefined,
          }}
        />
      )}
    </Flex>
  );
};
