import { As, Flex, FlexProps, Icon, Image, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { AppColors as SiteColors } from '../../../theme';
import { AppColors, AppButton, AppText } from '@backlinkit/shared';
import { useEffect } from 'react';

type ImagePositionOption = 'left' | 'right';
type ComponentVariant = 'light' | 'dark';

type ItemFlex = 'column' | 'column-reverse' | 'row' | 'row-reverse';

export type Repeatable = {
  icon?: string;
  title: string;
  description: string;
  bgImage?: string;
};

export type ItemTextSizeOptions = 'sm' | 'md' | 'lg';

export interface AppBannerListProps extends FlexProps {
  variant?: ComponentVariant;
  tag?: string;
  titleSize?: string;
  title: string;
  subHeading?: string;
  description?: string;
  image?: string;
  imagePosition?: ImagePositionOption;
  items: Repeatable[];
  itemDirection?: ItemFlex;
  itemTextSize?: string;
  itemBgColor?: string;
  list?: boolean;
}

export const AppBannerList: React.FC<AppBannerListProps> = ({
  variant,
  tag,
  title,
  titleSize,
  subHeading,
  description,
  image,
  imagePosition,
  items,
  itemDirection,
  itemTextSize = 'lg',
  itemBgColor = 'rgba(255, 255, 255, 0.3)',
  list = true,
  ...props
}) => {
  const screenSize = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });
  const textColor = variant === 'dark' ? 'white' : 'black';
  const getImagePosition = () => {
    if (screenSize !== 'desktop' && imagePosition === 'left') {
      return 'column';
    }
    if (screenSize !== 'desktop' && imagePosition === 'right') {
      return 'column-reverse';
    }
    if (screenSize === 'desktop' && imagePosition === 'right') {
      return 'row-reverse';
    }
    if (screenSize === 'desktop' && imagePosition === 'left') {
      return 'row';
    }
  };

  const getHeight = () => {
    if (image) {
      return 'auto';
    }
    if (!image && screenSize === 'desktop') {
      return '400px';
    }
    return '100%';
  };

  const getTextSize = () => {
    switch (itemTextSize) {
      case 'sm':
        return '16px';
      case 'md':
        return '18px';
      case 'lg':
        return '24px';
    }
    return '24px';
  };

  useEffect(() => {}, [imagePosition]);

  const getFlexGrid = () => {
    if (screenSize !== 'desktop' && !list) {
      return 'column';
    }
    if (!list) {
      return 'row';
    }
    return 'column';
  };

  const getTitleBoxWidth = () => {
    if (!list && screenSize === 'desktop') {
      return '50%'
    }
    if (!list && screenSize !== 'desktop') {
      return '100% !important'
    }
    return '100%'
  }

  return (
    <Flex
      {...props}
      flexDir={getImagePosition()}
      maxW={'1140px'}
      py={'80px'}
      px={screenSize === 'desktop' ? 0 : 7}
      gap={screenSize === 'mobile' ? '20px' : '40px'}
    >
      <Flex flexDir={getFlexGrid()} gap={!list ? '80px' : '40px'} justify={'space-evenly'}>
        <Flex flexDir={'column'} width={getTitleBoxWidth()} gap={6} >
          <AppText
            color={textColor}
            fontWeight={'700'}
            fontSize={screenSize === 'desktop' ? '24px' : '18px'}
          >
            {tag}
          </AppText>
          <AppText
            color={textColor}
            fontWeight={'700'}
            variant={'h2'}
          >
            {title}
          </AppText>
          <AppText color={textColor}>{subHeading}</AppText>
          <AppText color={textColor}>{description}</AppText>
        </Flex>
        {list && (
          <Flex
            align={'center'}
            flexDir={itemDirection ?? 'column'}
            justify={'center'}
            gap={8}
            width={'100%'}
          >
            {items.map((item, idx) => (
              <Flex
                key={`${item.title}-${idx}`}
                flexDir={'column'}
                gap={4}
                borderRadius={'xl'}
                flex={1}
                height={'100%'}
                minH={getHeight()}
                p={6}
                bgColor={variant === 'dark' ? itemBgColor : 'white'}
              >
                {item.icon && <Image src={item.icon} boxSize={'35px'} />}
                <AppText fontSize={getTextSize()} color={textColor} fontWeight={'700'}>
                  {item.title}
                </AppText>
                <AppText color={textColor}>{item.description}</AppText>
              </Flex>
            ))}
          </Flex>
        )}
        {!list && (
          <SimpleGrid
            columns={2}
            gap={screenSize === 'mobile' ? 2 : 4}
            rowGap={screenSize === 'mobile' ? 2 : 4}
            w={getTitleBoxWidth()}
          >
            {items.map((item, idx) => (
              <Flex
                key={`${item.title}-${idx}`}
                borderRadius={'xl'}
                flexDir={'column'}
                height={screenSize === 'mobile' ? '100px' : '130px'}
                bgImage={item.bgImage ?? ''}
                p={4}
                gap={3}
                backgroundSize={screenSize === 'mobile' ? 'cover' : '95% 100%'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
              >
                <AppText
                  color={'white'}
                  fontSize={screenSize !== 'desktop' ? '36px' : '56px'}
                  fontWeight={'700'}
                >
                  {item.title}
                </AppText>
                <AppText color={'white'}>{item.description}</AppText>
              </Flex>
            ))}
          </SimpleGrid>
        )}
      </Flex>
      {image && <Image src={image} width={'auto'} maxH={'700px'} />}
    </Flex>
  );
};
