import { Button, Flex, Icon, Input, useBreakpointValue } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";

export interface KnoweledgeSearchbarProps {
  handleSearch: (searchString: string) => void;
}

const KnowledgeSearchBar: React.FC<KnoweledgeSearchbarProps> = ({ ...props }) => {
  const { handleSearch } = props;
  const [searchString, setSearchString] = useState<string>("");

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const onHandleSearch = () => {
    if (!searchString?.length) return;
    handleSearch(searchString);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onHandleSearch();
    }
  };

  return (
    <Flex
      width={"100%"}
      flexDirection={"row"}
      align={"center"}
      justifyContent={"center"}
      height={"56px"}
      gap="10px"
    >
      <Flex height={"100%"} flexDir={"row"} width={"100%"}>
        <Input
          placeholder="How-to Articles"
          height="100%"
          bg={"#FAFAFA"}
          onKeyDown={onKeyDownHandler}
          onChange={(e) => setSearchString(e.currentTarget.value)}
          borderRadius={"100px"}
          borderRight={"0px"}
          borderRightRadius={"0px"}
          _focusVisible={{ borderColor: "" }}
          _hover={{ borderColor: "" }}
        />
        <Flex
          height={"100%"}
          bg={"#FAFAFA"}
          alignItems={"center"}
          borderTop={"1px solid rgb(226, 232, 240)"}
          borderBottom={"1px solid rgb(226, 232, 240)"}
          justifyContent={"center"}
          paddingRight={"16px"}
          borderEnd={"1px solid  rgb(226, 232, 240)"}
          borderEndRadius={"100px"}
        >
          <Icon as={FaSearch} boxSize={"24px"} />
        </Flex>
      </Flex>

      {variant === 'desktop' && (
        <Button
          height={"100%"}
          borderRadius={"100px"}
          fontSize={"16px"}
          padding={"17px 60px"}
          onClick={onHandleSearch}
          color={'white'}
          bg={'#037DFC'}
          _hover={{ bg: '#037DFC95' }}
        >
          Search
        </Button>
      )}

    </Flex>
  );
};

export default KnowledgeSearchBar;
