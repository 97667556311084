import { Flex, FlexProps } from '@chakra-ui/react';

export const AppComponentWrapper: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      width={'100%'}
      justify={{ base: 'center', xl: 'normal' }}
      px={{ base: 1, xl: '135px', '1xl': '0' }}
      justifyContent={{ '1xl': 'center' }}
      align={'center'}
      {...props}
      bgPos={'center'}
      bgSize={'cover'}
    >
      {children}
    </Flex>
  );
};
