import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppProductBanner } from '../../components/app/app-product-pricing/app-product-pricing';
import { AppArticleBanner } from '../../components/app/app-article-banner/app-article-banner';
import { AppComparisonTable } from '../../components/app/app-comparison-table/app-comparison-table';
import { mockProducts } from '../../constants/product-mocks';
import { useBreakpointValue } from '@chakra-ui/react';
import { useFetchProductsQuery } from '../../store/api/productApi';
import { AppFaqSection } from '../../components/app/app-faq-section/app-faq-section';
import { articleMocks } from '../article/mocks/article-mock';
import { pricingFaqs } from './pricing-faqs';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageMetaPricingPage } from '../../constants/meta-data';
import { heroBackground, mobileBackground } from '../../constants/heroImages';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const PricingPage: React.FC = () => {
  usePageMeta(PageMetaPricingPage);

  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  const { data: products, refetch: refetchProducts } = useFetchProductsQuery({
    refetchOnMouuntArgChange: true,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <AppComponentWrapper px={0} id="pricing-hero">
        <AppBasicHero
          pageName="Pricing"
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper bgColor={'rgba(3, 125, 252, 0.05)'} justify={'center'}>
        <AppProductBanner
          title="Backlinkit Plans & Pricing"
          plans={products ?? []}
          isGrid={false}
          descriptionItems={[
            `Over a decade of expertise refined into a powerful backlink intelligence tool. We're committed to continuously evolving our features based on your feedback, ensuring our growth drives yours.`,
            `Try our risk-free, zero-commitment 7-day trial—no credit card required—and see the difference for yourself!`,
          ]}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={12} px={variant === 'desktop' ? 0 : 7}>
        <AppComparisonTable
          title="Compare Plan Limits"
          description="Every package comes with the same powerful features—only the limits differ—because we believe no one should miss out on essential tools due to price."
          comparisonItems={mockProducts}
          footerTexts={[
            `Our intelligence tool is loaded with powerful features, including campaign and link management, tagging, advanced filters, detailed link stats, real-time alerts, and EFL reports—designed to give you unparalleled control and insights.`,
            `We're dedicated to continuous improvement and have an exciting roadmap of features set to launch soon, ensuring our tool keeps adapting to the ever-changing SEO landscape.`,
          ]}
        />
      </AppComponentWrapper>

      <AppComponentWrapper bgColor={'#4A4F54'}>
        <AppFaqSection
          title={'Frequently asked questions'}
          description={'We have put together some commonly asked questions.'}
          faqs={pricingFaqs || []}
          variant="dark"
          
        />
      </AppComponentWrapper>

      <AppComponentWrapper>
        <AppArticleBanner
          tag="Featured Articles"
          title="Why Backlinks Are Key to Boosting Your Website's Rankings"
          article={articleMocks[1]}
        />
      </AppComponentWrapper>
    </>
  );
};
