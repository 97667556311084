import { AppText } from '@backlinkit/shared';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppTextContent } from '../../components/app/app-text-content/app-text-content';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PagePrivacyPage } from '../../constants/meta-data';
import { useEffect } from 'react';
import { heroBackground, mobileBackground } from '../../constants/heroImages';
import { RoutesList } from '../../router/router';

export const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  usePageMeta(PagePrivacyPage);
  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  const title = 'WEBSITE PRIVACY POLICY';

  const crumbs = [
    { name: 'Legal', href: RoutesList.Legal },
    { name: 'Privacy Policy', href: RoutesList.PrivacyPolicy },
  ];

  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName="Privacy Policy"
          breadCrumbs={crumbs}
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>
      <AppComponentWrapper py={12} justify={'center'}>
        <Flex
          bgColor={'rgba(238, 238, 238, 0.3)'}
          borderRadius={'2xl'}
          p={variant === 'mobile' ? 7 : 6}
          flexDir={'column'}
          maxW={'1140px'}
          width={'100%'}
          gap={6}
        >
          <AppText variant={'h3'}>{title}</AppText>
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="INTRODUCTION"
            startIndex={1.1}
            listItems={[
              `Welcome to BacklinIt and thank you for visiting our Website. The Website offers information and Services to Users in relation to the online management, research, analysis and automated tracking of links, as well as other SEO-related Services.`,
              `We take our legal obligations very seriously, which is why we have made sure that you can easily access all of our policies and procedures on the Website, at any time. Some of our policies and procedures are unfortunately quite lengthy, but it is important to us that we cover all of the relevant issues as they pertain to your rights and obligations, so that you are always clear as to where you stand with us.`,
              `Please take the time to read through this Privacy Policy (“this Policy”) carefully, and if you have any questions, please do not hesitate to contact our Information and Privacy Officer. The details of our Information and Privacy Officer are set out in clause 24 below. `,
              `When registering on the Website, you will be required to create an account for yourself. Once this account has been created, depending on the Subscription Plan that you select, you will then be able to make use of the various Services that are offered by BacklinkIt. It is your responsibility to ensure that your User account information is accurate and up-to-date and that you do not misrepresent yourself, either through false information, or through any omission by you. BacklinkIt is not responsible for the accuracy of your User account details.`,
              `This Policy can be accessed on our Website at all times. By using our Website, you accept the terms of this Policy and you Consent to us Processing your Personal Information for the purposes set out in this Policy. Furthermore, when you register for any of our Services, you are deemed to have accepted and agreed to the Processing of your Personal Information and the use practices set out in this Policy. Such acceptance is voluntary, but it is required in order for us to provide you with the Services that we offer. If you do not agree with anything in this Policy, or any of the changes made to it, then you may not access the Website, nor register for or use any of the Services that we offer.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="DEFINITIONS "
            startIndex={2.1}
            listItems={[
              `Please see our Definitions Key for a full explanation of all of the important terms that are used in this Policy.`,
              `Unless the context clearly indicates a contrary intention:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={2.1}
            listItems={[
              `words and expressions defined in the Definitions Key shall bear corresponding meanings in this Policy; and`,
              `terms that are used in this Policy that are specifically defined in POPIA, are given the meanings ascribed to them in POPIA.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="OUR COMMITMENT TO YOUR PRIVACY"
            startIndex={3.1}
            listItems={[
              `BacklinkIt is committed to protecting the privacy of all Users of the Website. When you engage with us, you trust us with Personal Information about yourself. We value your trust and, as part of maintaining that trust, we recognise that there needs to be transparency and accountability regarding how we deal with your Personal Information.`,
              `The purpose of this Policy is to explain to you how we Process and safeguard your Personal Information in performing the Services that we offer to you, providing support services to you, and generally during the course of conducting our business. `,
              `This Policy also describes to you the choices that are available to you regarding how we may make use of your Personal Information, how you can access and update your Personal Information and what steps you can take should you be unhappy or concerned about the ways that we are Processing your Personal Information.`,
              `In performing our Services in the ordinary course of business, we may Process Personal Information. If we collect Personal Information from you, you have the right to expect that such Personal Information will be lawfully protected, as far as is reasonably possible. Any use of, or other dealing with your Personal Information is subject to Consent, where this is required by law. This is in line with the general privacy practices of the Organisation. `,
              `When we Process Personal Information, we will always do so for legitimate business purposes and in line with the provisions of POPIA, the GDPR and other relevant legislation.`,
              `This Policy is incorporated into, forms part of and is subject to the Organisation’s standard terms and conditions and the General Terms and Conditions pertaining to the use of our Website. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="WHO DOES THIS POLICY APPLY TO"
            subHeading="This Policy applies to you if you: "
            startIndex={4.1}
            listItems={[
              `are a guest on our Website (being a customer, business associate or other interested party);`,
              `intend to make use of or are currently making use of any of the Services that we offer;`,
              `create a User account on our Website;`,
              `add to and/or manage your User account on our Website;`,
              `contact or communicate with us via our Website;`,
              `provide feedback, send messages, or make available any other content on our Website; or`,
              `opt-in to any marketing communication from us.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="CHILDREN’S PRIVACY"
            startIndex={5.1}
            listItems={[
              `In line with the provisions of the Children’s Act and POPIA, the Organisation defines any individual under the age of 18 (Eighteen) years as a child. `,
              `Our Website is not targeted at children, nor is it intended for use by anyone under the age of 18 (Eighteen) years. You may not use our Services if you are younger than 18 (Eighteen) years old or do not have the legal capacity to conclude legally binding agreements. By registering to use the Services, you warrant that you are over the age of 18 (Eighteen) years old and have the necessary authority to register for the Services and to agree to the Terms of Use and the Ancillary Terms.`,
              `We have no intention of Processing Personal Information about children through the Website and we will use our best endeavours not to knowingly Process Personal Information from or about children through the Website. If we become aware that a child under the age of 18 (Eighteen) years old has provided us with Personal Information, we will take steps to delete such information. If you believe that a child under the age of 18 (Eighteen) years has provided Personal Information to us, please contact our Information and Privacy Officer, as set out in clause 24, and we will take the necessary steps to delete any Personal Information that we hold about that child.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="WHAT PERSONAL INFORMATION IS COLLECTED BY US"
            startIndex={6.1}
            listItems={[
              `We may Process Personal Information in conducting our ordinary business operations, including through the use of the Website and any registration for the use of our Services. In Processing such Personal Information, we will at all times ensure that:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={6.1}
            listItems={[
              `we do so in compliance with the provisions of POPIA, the GDPR and other applicable legislation; and`,
              `such Personal Information is used for legitimate business purposes.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={6.2}
            listItems={[
              `Examples of the types of Personal Information that we may Process include, but are not limited to the following:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={6.2}
            listItems={[
              `identifying information, such as your name, date of birth, passport number or identification number of any kind;`,
              `geographic or locational information, such as the country that you reside in;`,
              `contact information, such as your telephone number or an email address;`,
              `address information, such as your physical or postal address; `,
              `demographic information, such as your gender or marital status;`,
              `bank account information or debit/credit card information; and`,
              `sensitive demographic information, such as your race or ethnicity.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={7.1}
            title="WHAT OTHER INFORMATION IS COLLECTED BY US"
            listItems={[
              `We may also collect information from you that is not defined as Personal Information. Such information may include the following: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={7.1}
            listItems={[
              `non-personal statistical information or data collected and compiled by us;`,
              `information that you have provided voluntarily in an open, public environment, such as comments, reviews, recommendations, endorsements or other content (because the information has been disclosed in a public environment, it is no longer confidential and therefore does not constitute Personal Information, subject to protection under this Policy);`,
              `information that has been made anonymous, so that it does not identify a specific person; and`,
              `permanently de-identified information that does not relate or cannot be traced back to you specifically.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={8.1}
            title="CONSENT"
            listItems={[
              `Unless permitted by law, whenever Consent is required to Process your Personal Information, we will ensure that we obtain such Consent from you`,
              `Please note that we may, in certain circumstances, Process your Personal Information without your knowledge or Consent, in compliance with a regulatory obligation, or if we are permitted by law to do so`,
              `If we have Processed your Personal Information with your Consent, you have the right to withdraw that Consent at any time. However, it's essential that you are aware that the withdrawal of Consent will not impact the legality of any Processing carried out before the withdrawal of such Consent, nor will it affect any Processing based on other legitimate grounds for data processing.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={9.1}
            title="HOW DO WE OBTAIN YOUR PERSONAL INFORMATION"
            subHeading="We will obtain your Personal Information in the following ways: "
            listItems={[
              `Certain Personal Information will be Processed automatically by us when you visit our Website. This occurs mainly from your browser, when we automatically receive and record your Internet Usage Information on our server logs from your browser. It is very important that you are aware that:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={9.1}
            listItems={[
              `other websites that you visit before entering our Website might place Personal Information within your URL;`,
              `we have no control over such websites and the Personal Information that they may collect and share; and`,
              `this Personal Information may be shared with any subsequent websites that you visit that collect URL information.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={9.2}
            listItems={[
              `Certain Personal Information will be Processed by us when you register for any Services on our Website and create a User account. This information will be provided by you to us. We will use this Personal Information to fulfil your account, provide additional services and information to you as we reasonably think appropriate, and for any other purposes set out in this Policy. This Personal Information may include:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={9.2}
            listItems={[
              `your name and surname, identity number (passport), contact number and e-mail address;`,
              `your postal address or residential address;`,
              `your company name, company registration number, and VAT number; and`,
              `your username and password.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={9.3}
            listItems={[
              `Certain information will be Processed by us when you fill in your payment details. This information may include your debit or credit card information, or other payment information. When you submit your debit or credit card information, the name and address of the card holder, the expiry date of the card and the last four digits of the credit or debit card number will be stored in an encrypted format in the secured servers of our payment gateway service providers. We do not store the actual debit or credit card number.`,
              `Certain additional Optional Information may also be Processed by us.`,
              `When you interact with us, either online or via e-mail or telephone, we may record and analyse such interactions, so as to improve our Services to you and other Users.`,
              `When you provide feedback or reviews about our Services, interact, or engage with us on marketplaces, review sites or social media platforms such as Facebook, Twitter, LinkedIn and Instagram, through posts, comments, questions, reviews and other interactions, we may collect such publicly available information, including profile information. We collect this information to allow us to:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={9.6}
            listItems={[
              `connect with you;`,
              `improve our Services;`,
              `better understand User reactions and issues; or`,
              `reproduce and publish your feedback elsewhere. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={9.7}
            listItems={[
              `Please be aware that the information set out in clause 9.6 may still be stored by us, even if you delete it from the site or platform in question. We may also add and update information about you from other publicly available sources. It is always advisable to be cautious when sharing information on a public forum (whether Personal Information or other information), as such information can be  viewed by any third parties. We cannot control the actions of other Users or third parties who access your information on a public forum, whether such information relates to us, our Website, our Services or not.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="HOW WE USE YOUR PERSONAL INFORMATION"
            startIndex={10.1}
            listItems={[
              `We operate our Website, provide our Services and conduct our business in accordance with South African legislation, as well as in line with international standards and guidelines on data protection. We consider it imperative to protect the privacy interests of all Users making use of our Services and our Website.At all times, we will take all reasonable steps to ensure that the privacy requirements, as set out in relevant pieces of legislation, are adhered to.`,
              `Generally, you agree that we may Process your Personal Information in order to fulfil our obligations to you and to render any Services to you that you may require from us.`,
              `You also agree that we may collect and Process your Personal Information for certain specific purposes, including but not limited to the following: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={10.3}
            listItems={[
              `the collection of and response to requests;`,
              `to set up or maintain your User account;`,
              `as may be necessary to support the operation of any Services, such as for billing, account maintenance, record-keeping purposes, enabling collaboration, providing Website hosting, and backing up and restoring your data;`,
              `to provide support to you and to analyse and improve our interactions with you;`,
              `the distribution of communications that are relevant to your use of the Services, including system updates, changes to our Terms of Use or policies and important notices or information about your use of the Services;`,
              `to ask you to provide feedback to us on the Website or the Services;`,
              `the pursuance of lawful marketing activities, which you always have the option to “optout” of;`,
              `to monitor and improve marketing campaigns and to make suggestions that may be relevant and useful to you; and`,
              `legal purposes, such as the compliance with legislation, regulations and orders of court, detecting and reporting illegal activities, handling claims, disputes and queries, protecting our rights, your rights, the rights of third parties and the public and the pursuance of good governance. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={10.4}
            listItems={[
              `Regarding your Internet Usage Information, you agree that in addition to the purposes described in clauses 10.2 and 10.3 above, we may also use such information to: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={10.4}
            listItems={[
              `monitor Website usage metrics, such as total number of visitors and pages accessed; and`,
              `track your entries, submissions, and status in any promotions, marketing campaigns or other activities in connection with your usage of the Website.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={10.5}
            listItems={[
              `You agree that we may Process your Personal Information for purposes other than those referred to in clauses 10.2, 10.3, and 10.4 above, only after notice has been provided to you of our intention to Process such Personal Information, and only after Consent has been obtained from you, where required by law.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={11.1}
            title="YOUR CHOICES REGARDING HOW WE ACCESS AND USE YOUR PERSONAL INFORMATION"
            listItems={[`At any given time, you have the right to: `]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={11.1}
            listItems={[
              `Opt-out of non-essential electronic materials: You may opt-out of receiving newsletters, promotions, and other non-essential messages by clicking on the “unsubscribe” function included in such messages. You will, however, continue to receive essential messages from us, such as messages and e-mails about your User account, security incident alerts, security and privacy update notifications and essential transactional and payment-related e-mails.`,
              `Disable cookies: You may disable browser cookies before visiting our Website. Cookies, their function and your options as regards cookies is dealt with in detail in our Cookie Policy, which can be accessed on our Website.`,
              `Optional Information: You can choose not to provide us with any Optional Information. You can also delete or change your optional User account information at any time. You can always choose not to fill in non-mandatory fields when you submit any form linked to our Website.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={12.1}
            title="DISCLOSING YOUR PERSONAL INFORMATION TO THIRD PARTIES"
            listItems={[
              `We do not typically share your Personal Information with third parties, other than when:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={12.1}
            listItems={[
              `such sharing is required to provide you with the Services that we offer to you, as stated in clause 10.2 ;`,
              `such sharing is required by us to protect the safety or to maintain the security and integrity of the Services that we offer;`,
              `we need to disclose certain Personal Information to protect ourselves against any liability, to defend ourselves against any claim or otherwise to protect our rights, consistent with applicable laws;`,
              `we have a legal or contractual obligation to share such Personal Information with a third party;`,
              `such sharing is required to protect the safety or security of any individual or the general public; or`,
              `the provision of such Personal Information is required for risk or fraud prevention purposes.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={12.2}
            listItems={[`You agree that we may disclose certain Personal Information:`]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={12.2}
            listItems={[
              `to Contracted Third Parties, provided that such Contracted Third Parties shall at all times agree to keep such information confidential and only use your Personal Information in connection with the services that they supply to you or the services that they perform for us; and`,
              `to certain of our employees and officers, who require access to such Personal Information to do their jobs, provided that such employees and officers will be subject to stringent internal policies which ensure that the confidentiality of your Personal Information is at all times be maintained.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={12.3}
            listItems={[
              `You also agree that we may disclose aggregate statistics (information about the customer population in general terms) about the Personal Information to advertisers or our business partners.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="TRANSFER OF YOUR PERSONAL INFORMATION TO ANOTHER COUNTRY"
            startIndex={13.1}
            listItems={[
              `In certain instances, we may need to transmit or transfer your Personal Information outside of the country in which it was collected (in most cases, South Africa) to a foreign country, and Process it in that country.`,
              `Personal Information may also be stored on servers located outside of the country in which it was collected (in most cases, South Africa), in a foreign country.`,
              `If we need to send any Personal Information outside of South Africa (including if such information is hosted offshore), we will take all reasonable steps to ensure that we comply with all legislative requirements in this regard, including the requirements contained in POPIA and the GDPR.`,
              `Data protection is a worldwide objective and, in most countries, the protection of Personal Information is prioritised and carefully governed. In some cases, however, the foreign country where the Personal Information is transmitted or transferred to, Processed in and/or stored in, may have laws protecting Personal Information that may not be as stringent as the laws in the country in which it was collected. In this regard, you Consent to us Processing your Personal Information in a foreign country whose laws regarding the Processing of Personal Information may be less stringent than those governing the Processing of Personal Information in South Africa.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title=" STORAGE AND SECURITY OF YOUR PERSONAL INFORMATION"
            startIndex={14.1}
            listItems={[
              `We take the security of Personal Information very seriously and will always do our best to comply with applicable data protection laws and keep your Personal Information confidential.`,
              `We have adopted a security model that complies with generally accepted information security practices and procedures, designed to secure the integrity and confidentiality of all Personal Information that is submitted through the Website. Should you require any further information on how your Personal Information is stored, including information on password controls, encryption processes, fire-wall technology, and anti-virus software, please do not hesitate to contact us via the Information and Privacy Officer listed in clause 24 below. `,
              `We will at all times take all reasonable organisational and technical measures to prevent any damage or loss to your Personal Information, or any unlawful access to or Processing of your Personal Information. `,
              `The Organisation has a stringent security policy in place that every employee, officer and supplier of the organisation must adhere to.`,
              `At all times, we undertake to you that we will use all reasonable endeavours to: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={14.5}
            listItems={[
              `identify all reasonably foreseeable risks to any Personal Information in our possession or under our control;`,
              `establish and implement appropriate safeguards against any of the risks that we identify; and`,
              `verify on an ongoing basis that all safeguards are effectively maintained and updated in response to any new risks that may come to our attention`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={14.6}
            listItems={[
              `Please note that despite our very best endeavours to secure the integrity and confidentiality of your Personal Information, the absolute security of any information transmitted through the internet or via a mobile device can never be guaranteed. You are responsible for maintaining the security of any user identity, passwords or other form of identification required to access password-protected or secure areas of the Website. We are not responsible for any interruption to or interception of any communications through the internet or for changes to or losses of data and you agree to indemnify us in this regard.`,
              `In order to protect you and your Personal Information, you agree that we may suspend your use of any of our Services or your access to the Website, without notice to you, pending an investigation if any breach of security is suspected. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={15.1}
            title="RETENTION OF PERSONAL INFORMATION"
            listItems={[
              `All Personal Information that is retained by us, including information obtained through the use of the Website, is retained in accordance with the provisions contained in POPIA, the GDPR and all other applicable legislation.`,
              `We will retain your Personal Information only for as long as is required to fulfil the purposes set out in this Policy and to the extent necessary to comply with all of our legal obligations.`,
              `In general, you agree that we may keep your Personal Information until:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={15.3}
            listItems={[
              `all of the purposes for which it was collected have been fulfilled;`,
              `we have complied with all of our legal obligations; or`,
              `you request that we delete or destroy it. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={15.4}
            listItems={[
              `During any period of retention of your Personal Information, we will continue to protect your Personal Information and abide by all of our privacy obligations.`,
              `If you feel that we have retained your Personal Information for longer than is reasonably necessary, given its purpose, you may ask us to delete or destroy it. This is unless the law requires us to keep your Personal Information for a prescribed period.`,
              `We may retain your Personal Information in physical or electronic records, at our discretion.`,
              `If you believe that any Personal Information that we have about you is excessive or has been unlawfully obtained, you may ask us to delete or destroy it. `,
              `Where we are unable to delete your Personal Information, we will take all reasonable steps to make it anonymous. `,
              `We try to respond to all legitimate requests as soon as is reasonably practicable and, in any event, within applicable statutory time-limits. It could take us longer if your request is particularly complex, or you have made a number of requests. In such a case, we will notify you and keep you updated at all times.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={16.1}
            title="YOUR RIGHTS REGARDING YOUR PERSONAL INFORMATION"
            listItems={[
              `It is very important that you are aware of the rights that you have in relation to your Personal Information.`,
              `The rights that are available to you will depend on your country of residency or citizenship and our reason for Processing your Personal Information. Please note that this clause 16 contains a general list of all rights that might apply to you and is not a guarantee that all rights do apply to you under applicable law.`,
              `As regards, your Personal Information, you have the following general rights: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={16.1}
            title="YOUR RIGHTS REGARDING YOUR PERSONAL INFORMATION"
            listItems={[
              `It is very important that you are aware of the rights that you have in relation to your Personal Information.`,
              `The rights that are available to you will depend on your country of residency or citizenship and our reason for Processing your Personal Information. Please note that this clause 16 contains a general list of all rights that might apply to you and is not a guarantee that all rights do apply to you under applicable law.`,
              `As regards, your Personal Information, you have the following general rights: `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.1"
            subHeading="Your right of access:"
            listItems={[
              `You have the right to ask us for copies of your Personal Information. This right always applies. There are some exemptions, which means you may not always receive all of the information that we Process.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.2"
            subHeading="Your right to rectification:"
            listItems={[
              `You have the right to ask us to rectify Personal Information you think is inaccurate. You also have the right to ask us to complete Personal Information you think is incomplete.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.3"
            subHeading="Your right to erasure:"
            listItems={[
              `You have the right to ask us to erase your Personal Information in certain circumstances. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.4"
            subHeading="Your right to restriction of Processing:"
            listItems={[
              `You have the right to ask us to restrict the Processing of your Personal Information in certain circumstances.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.5"
            subHeading="Your right to data portability:"
            listItems={[
              `You have the right to remove your Consent to Processing or object to Processing if we are able to Process your Personal Information because the Process forms part of our public tasks or is in our legitimate interests. In some circumstances, you may not be able to fully experience the features of the Website or of Services that require the Processing of Personal Information. Such restriction is based directly upon the relation between the Processing of Personal Information and the value provided to you by the Personal Information.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            customStartIndex="16.3.6"
            listItems={[
              `This only applies to Personal Information that you have given us. You have the right to ask that we transfer the Personal Information that you gave us from one organisation to another or give it to you. This right only applies if we are Processing Personal Information based on your Consent or under, or in talks about entering into an agreement and the Processing is automated.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={16.4}
            listItems={[
              `We will try to keep the Personal Information that we collect as accurate, complete and up-to-date as is necessary for the purposes defined in this Policy. From time to time, we may request you to update your Personal Information on the Website. You are able to review or update any of your Personal Information held by us by accessing your User account online.`,
              `If you are of the view that any Personal Information held by us about you is incorrect in any way, including that it is outdated, irrelevant, inaccurate, incomplete or misleading, you may ask us to correct, update or delete it by contacting the Information and Privacy Offer, whose details are set out in clause 24 below.`,
              `You have the right at all times to know what Personal Information we hold about you. At any time, you may contact us in order to:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={16.6}
            listItems={[
              `confirm that we hold Personal Information about you;`,
              `confirm what Personal Information we hold about you; `,
              `request a copy or a description of the Personal Information that we hold about you; or`,
              `confirm the identity or categories of third parties who have had, or currently have, access to your Personal Information. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={16.7}
            listItems={[
              `You also have the right to object to the Processing of any of your Personal Information and to appeal any rejection of access to your Personal Information.`,
              `Please use the contact details listed in clause 24 below, in relation to any request regarding your Personal Information.`,
              `We may charge a prescribed or reasonable fee should you request copies of your Personal Information from us, and we will inform you at the time when the request is made what this fee is`,
              `When you make a request regarding your Personal Information, please note that we may need additional information from you in order to verify the legitimacy of your request, verify your identity, or accurately match the Personal Information to the party to whom it belongs. In such cases, we will contact you for additional details. In order for us to attend to your requests in a timely manner, you will need to provide such additional information as promptly as possible. Information provided to us for the purposes of exercising one of your rights will only be used to carry out your request under such right.`,
              `There may be certain times when we will not be able to grant you access to your Personal Information. These times may include, but are not limited to, instances when:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            orderIndex={16.11}
            listItems={[
              `granting you access will result in a breach of confidentiality; or`,
              `access to the Personal Information will threaten the privacy of others. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={16.12}
            listItems={[
              `Whenever we are unable to grant you access to your Personal Information, we will always provide you with comprehensive reasons as to why this is the case. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="APPLICATION OF THE GDPR"
            orderIndex={17.1}
            listItems={[
              `If you are situated in a jurisdiction where the principles and provisions contained in the GDPR apply to you, we are committed to assisting you in using the Website and our Services in a GDPR-compliant manner. `,
              `The GDPR has robust requirements and obligations for data controllers and data processors, and we are committed to ensuring that we are at all times compliant with our obligations, insofar as they relate to the Processing of your Personal Information in terms of the GDPR.`,
              `Our legal basis for the collection and Processing of Personal Information depends on the Personal Information concerned and the context in which we collect it. You can read more about why we collect and Process your Personal Information in clause 10 above. In general, we may Process your Personal Information because:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={17.3}
            listItems={[
              `we need to perform in terms of our agreement with you;`,
              `you have given us Consent to Process your Personal Information;`,
              `the Processing is in our legitimate interest to offer you the Services, when that legitimate interest is not overridden by your rights; and`,
              `we are required to comply with any law or regulation.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            startIndex={17.4}
            listItems={[
              `When Processing your Personal Information, BacklinkIt is at all times committed to complying with the GDPR principles of lawfulness, fairness and transparency, purpose limitation, data minimisation, accuracy, storage limitation, integrity and confidentiality (security) and accountability.`,
              `If you have a complaint regarding the Processing of your Personal Information in terms of the GDPR, you may contact our Information and Privacy Officer, as set out in clause 24 below. Alternatively, you may contact the appropriate European Union Data Protection Authority (“DPA”), depending on where you are located. A list of DPA’s can be found on the website of the European Data Protection Board.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="IP ADDRESSES AND COOKIES"
            orderIndex={18.1}
            listItems={[
              `We may collect information about your computer, including (where available) your IP address, operating system, and browser type for system administration and statistical purposes.`,
              `For the same reason, we may obtain information about your general internet usage by placing small text files called “cookies” on your device when you visit our Website. Cookies contain information that is transferred to your computer’s hard drive. On revisiting the Website, our computer server will recognise the cookie, giving us information about your last visit. `,
              `Cookies help us to improve the Website and to deliver better and more personalised Services. For more information on cookies and how to personalise your cookie settings, please see our Cookie Policy , which is available on the Website.Alternatively, you may contact our Information and Privacy Officer on the e-mail address set out in clause 24 below and request a copy of our Cookie Policy.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="MESSAGES, UPDATES AND E-MAIL COMMUNICATIONS"
            startIndex={19.1}
            listItems={[
              `If you are a customer of BacklinkIt, a User of the Website, or have purchased or subscribed to any Services or products offered by us, we may use your contact details to send you information  regarding any new, similar services or products, which we may offer from time to time. In doing so, we will at all times comply with all applicable legislation, as it relates to direct marketing or otherwise.`,
              `We may send you communication from time to time from our Website, via e-mail, WhatsApp, SMS and other channels (sometimes through automated means), which may include news, special offers, updates and other information relating to BacklinkIt and its affiliates. This forms part of the Services that we offer to our customers.`,
              `You have the right to opt-out of any direct marketing that we send to you. Should you wish to optout of any of the communications referred to in clauses 19.1 and 19.2 above, or to limit the number and type of communications that you receive, you may do so by following the instructions set out in such communications, free of charge. If you experience any difficulty in this regard, you may contact us using the e-mail address listed in clause 24 below.`,
              `In addition to the communication described above, you may receive third-party marketing communication from providers that we have engaged to promote and market our Services. Please note that these providers may be using communications lists that they have acquired on their own and that you may have opted into those lists from other channels. If you no longer wish to receive communication from such third parties, you will need to contact them directly in order to opt-out. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="LINKS TO THIRD PARTIES"
            startIndex={20.1}
            listItems={[
              `The Website may contain links to third party Websites. It is important that you understand that if you follow a link to any of these Websites, they have their own use and privacy policies. `,
              `BacklinkIt is not responsible or liable for any information, representations, warranties, and/or content on any Website of any third party (including websites linked to our Website) as we do not exercise control over third parties’ privacy policies. The onus is on you to refer to the privacy policy of any such third party before providing them with any Personal Information. If you do not want these third parties to collect your Personal Information, please contact them directly.`,
              `You agree to indemnify BacklinkIt regarding any loss that you may sustain, should you fail to take the steps set out in clause 20.2 above.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="POLICY MODIFICATIONS"
            startIndex={21.1}
            listItems={[
              `BacklinkIt, in its sole discretion, reserves the right to amend, modify or update this Policy from time to time, with or without notice to you and you waive the right to receive specific notice of each such change or modification. Whenever reasonably possible, we will notify you of any changes by placing a notice in a prominent place on the Website or by sending you an e-mail detailing the changes that we have made and indicating the date that the Policy was last updated.`,
              `The most updated version of this Policy will always be available on the Website.`,
              `You agree and undertake to review this Policy whenever you visit the Website.`,
              ` Save as expressly provided to the contrary in this Policy, any amended version of this Policy will supersede and replace previous versions thereof. If you continue to use the Website or our Services following a change to the Policy, the changed Policy will apply to you and you will be deemed to have accepted such updated Policy.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="MERGERS AND ACQUISITIONS"
            startIndex={22.1}
            listItems={[
              `Should BacklinkIt become involved in a proposed or actual merger, acquisition or any other sale of business or sale of assets, BacklinkIt shall have the right to share your Personal Information with third parties in connection with such transaction. `,
              `In the case of an acquisition, merger or sale, the new entity will have access to your Personal Information, and we may assign our rights in and to the Personal Information to a successor, purchaser, or separate entity. In such a case, the terms of this Policy will continue to be applicable.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="NOTICES AND DOMICILIUM"
            startIndex={23.1}
            listItems={[
              `Notices to you shall be given to the e-mail address on file associated with your User account. You agree that this e-mail address shall be your domicilium citandi et executandi ("Domicilium") for all purposes relating to this Policy and your agreement with us, including the giving of any notice, the payment of any sum and the serving of any process.`,
              `To change the email address on file in relation to your User account, please e-mail us at the address specified in clause 24 below. All notices will be deemed to have been received by you on the day that they are sent by us. `,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="UPDATES, FEEDBACK AND CONTACT INFORMATION"
            startIndex={24.1}
            listItems={[
              `This Policy was last updated on 1 March 2024.`,
              `If you have any questions, concerns or comments regarding this Policy, or your Personal Information,
                please send them to: `,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            ml={6}
            listItems={[
              `Attention: The Information and Privacy Officer`,
              `E-mail: info@backlinkit.com.`,
              `Address: 2nd Floor, New Link Centre, 1 New Street, Esterville, Paarl, Western Cape, 7646.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            itemAlignemnt="start"
            title="COMPLAINTS AND DISPUTES"
            startIndex={25.1}
            listItems={[
              `If you believe that we have used your Personal Information contrary to this Policy, you have a right to complain, or to dispute the Personal Information that we hold.`,
              `We encourage you to first follow our internal complaints process. To do so, please contact the Information and Privacy Officer, as listed in clause 24 above. `,
              `If you feel that your complaint or dispute has not been resolved adequately by us, please feel free to contact the Information Regulator in South Africa, as established in terms of POPIA. The contact details for the Information Regulator are set out on the Website of the Information Regulator.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="GOVERNING LAW"
            listItems={[
              `This Policy and any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with this Policy is governed by and construed in accordance with South African law. The parties irrevocably agree that the South African courts have exclusive jurisdiction to settle any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with this Policy.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="GENERAL"
            listItems={[
              `This Policy does not create or imply any agency, partnership or franchise relationship. Nothing in this Policy, express or implied, is intended to or shall confer on any third party any right, benefit, or remedy of any nature whatsoever.`,
              `If any provision of this Policy is found by any court or other authority of competent jurisdiction to be invalid, unenforceable, or illegal, this shall not impair the operation of this Policy, or affect the other provisions which are valid.`,
            ]}
          />
          {/* <Flex>
            <Flex
              align={variant !== 'mobile' ? 'center' : 'start'}
              justify={'space-between'}
              width={'100%'}
              flexDir={variant !== 'mobile' ? 'row' : 'column'}
              gap={4}
            >
              {repeatables.map((item, idx) => (
                <Flex
                  key={`${idx}-${item.label}`}
                  p={4}
                  borderRadius={'2xl'}
                  align={'center'}
                  gap={2}
                  bgColor={'rgba(3, 125, 252, 0.1)'}
                >
                  <Flex borderRadius={'full'} bgColor={'rgba(3, 125, 252, 0.2)'} p={2}>
                    <Icon color={AppColors.secondary2} as={item.icon} boxSize={'15px'} />
                  </Flex>
                  <AppText color={AppColors.secondary2} fontWeight={'600'}>
                    {item.label}
                  </AppText>
                </Flex>
              ))}
            </Flex>
          </Flex> */}
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
