import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { AppText } from '../../components';

export enum DialogPosition {
  Top = 1,
  Bottom = 2,
  Middle = 3,
  Full = 4,
}

export interface DialogModalOptions {
  title?: string;
  blocking?: boolean;
  position?: DialogPosition;
  size?: string;
  maxWidth?: string;
  showCancel?: boolean;
  showOverlay?: boolean;
  centeredHeading?: boolean;
  render?: (onSubmit: () => void, onCancel: () => void) => React.ReactElement;
}

interface DialogModalProps extends DialogModalOptions {
  onClose: () => void;
  onSubmit: () => void;
}

export const DialogModal: React.FC<DialogModalProps> = ({
  title,
  render,
  size = 'md',
  maxWidth = 'md',
  onSubmit,
  onClose,
  position = DialogPosition.Full,
}) => {
  return position == DialogPosition.Middle ? (
    render ? (
      render(onSubmit, onClose)
    ) : (
      <AppText>No Render Method</AppText>
    )
  ) : (
    <Flex position={'absolute'} top={0} bottom={0} right={0} left={0} zIndex={1}>
      {render ? render(onSubmit, onClose) : <AppText>No Render Method</AppText>}
    </Flex>
  );
};
