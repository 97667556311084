import { Flex, useBreakpointValue, Image, Box } from '@chakra-ui/react';
import { AppText, AppButton } from '@backlinkit/shared';
import { AppColors as SiteColors } from '../../theme/index';
import { AppBanner, BannerCta } from '../../components/v2-banner.tsx/app-banner';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppFaqSection } from '../../components/app/app-faq-section/app-faq-section';
import { useFetchFeaturedFaqsQuery } from '../../store/api/faqApi';
import { AppBannerList } from '../../components/app/app-banner-list/app-banner-list';
import { manageBenefits, ourApproach } from '../../constants/list-mocks';
import { baseUrl } from '../../constants/nav-items';
import { AsFeaturedInComponent } from '../../components/as-featured-in/as-featured-in';

export const ManagedServices: React.FC = () => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const { data: faqs, refetch: refetchFaqs } = useFetchFeaturedFaqsQuery({
    refetchOnMountArgChange: true,
  });

  const bannerButton: BannerCta[] = [
    {
      label: 'Demo Request',
      bgColor: SiteColors.ctaColor,
      borderColor: 'transparent',
      color: 'white',
      onClick: () => {
        window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_blank');
      },
    },
    {
      label: 'View Services',
      bgColor: SiteColors.primary,
      borderColor: 'white',
      color: 'white',
      onClick: () => {},
    },
  ];

  const approachBackground =
    'https://backlinkitprod.blob.core.windows.net/assets/4f3c7b43-0886-4695-856a-97ddd1b6623a_home-hero-background.webp-1736498886458';
  const approachImage =
    'https://backlinkitprod.blob.core.windows.net/assets/0cb52f32-5c25-44be-83c3-8aea5624dc0c_our-approach-banner-image.webp-1736503206159';
  const ctaImage =
    'https://backlinkitprod.blob.core.windows.net/assets/20258199-93c4-4476-a306-73657234b003_cta-image-banner.webp-1736503207625';
  const audienceImage =
    'https://backlinkitprod.blob.core.windows.net/assets/5391df82-7c41-4ccd-870b-27e8233a7144_test-banner-image.webp-1736503207991';
  const bannerImage =
    'https://backlinkitprod.blob.core.windows.net/assets/6f0bde6b-bc51-4a36-9ae1-88b44d82f0f1_ms-banner-image.webp-1736503208687';
  const seoImage =
    'https://backlinkitprod.blob.core.windows.net/assets/25631386-1737-457d-9089-09c2db21a40a_seo-image.webp-1736503208458';
  const benefitsBackground =
    'https://backlinkitprod.blob.core.windows.net/assets/758d0d40-50a6-4c11-b296-01c99f0180e7_benefits-background.webp-1736503208221';
  const bannerbackground =
    'https://backlinkitprod.blob.core.windows.net/assets/71963711-0c21-44d4-abd3-1dd5b7b599c9_banner-background.webp-1736509442025';

  // const [faqs, setFaqs] = useState<AppFaq[]>([]);
  // const getBacklinkitFaqs = async () => {
  //   try {
  //     const backlinkitFaqs = await fetch('https://api.backlinkit.com/faq/featured').then((res) =>
  //       res.json()
  //     );
  //     setFaqs(backlinkitFaqs);
  //   } catch (e) {
  //     console.log('error:', e);
  //   }
  // };

  // useEffect(() => {
  //   getBacklinkitFaqs();
  // }, []);

  return (
    <>
      <AppComponentWrapper bgColor={SiteColors.primary} px={{ base: 7, xl: 0 }}>
        <AppBanner
          tag="Home / Article"
          title="Managed Services Increase Search Engine Rankings by 288%"
          description="With over 20 years of combined experience, our agency stands out for our innovative approach to SEO. We've worked with global corporations and startups, consistently delivering exceptional results."
          backgroundImage={bannerbackground}
          image={bannerImage}
          imageSize="medium"
          btns={bannerButton}
        />
      </AppComponentWrapper>
      <AsFeaturedInComponent />

      <AppComponentWrapper px={{ base: 7, xl: 0 }}>
        <AppBannerList
          tag="Benefits"
          title={'How can we help you?'}
          items={manageBenefits}
          imagePosition="left"
          itemDirection={variant === 'desktop' ? 'row' : 'column'}
          minH={'800px'}
          backgroundImage={benefitsBackground}
          backgroundSize={'cover'}
          backgroundPosition={'center'}
        />
      </AppComponentWrapper>
      <AppComponentWrapper px={{ base: 7, xl: 0 }}>
        <AppBanner
          title="Form is this service intended?"
          description={`We serve a wide spectrum of customers, from start-ups to major organizations looking to improve their online presence. Furthermore, we provide a white-labeled service that allows us to work effortlessly with consultants or agencies that want to provide their clients premium SEO solutions.`}
          image={audienceImage}
          color="black"
          imageSize="large"
          ctaImage={ctaImage}
          textGap="50px"
        />
      </AppComponentWrapper>
      <AppComponentWrapper
        backgroundImage={approachBackground}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        px={{ base: 7, xl: 0 }}
      >
        <AppBannerList
          tag={'Our Approach'}
          title={`We don't do SEO the conventional way.`}
          image={approachImage}
          variant="dark"
          imagePosition="right"
          items={ourApproach}
          itemTextSize={'md'}
          itemBgColor={'rgba(255, 255, 255, 0.2)'}
        />
      </AppComponentWrapper>
      <AppComponentWrapper bgColor={SiteColors.primary} px={{ base: 7, xl: 0 }}>
        <Flex
          maxW={'1140px'}
          width={'100%'}
          minH={'300px'}
          align={'center'}
          gap={variant === 'desktop' ? '50px' : 4}
          justify={'space-between'}
          flexDir={variant !== 'desktop' ? 'column' : 'row'}
          py={12}
          px={variant === 'desktop' ? 0 : 7}
        >
          <Image src={seoImage} width={'auto'} height={'300px'} />
          <Flex flexDir={'column'} gap={'30px'} width={'100%'}>
            <AppText variant={'h2'} fontWeight={'700'} color={'white'}>
              Learn about SEO's power
            </AppText>
            <AppText color={'white'}>
              We value your feedback. It helps us improve the service you receive. Please let us
              know how we can improve this page.
            </AppText>
            <Box>
              <AppButton
                bgColor={'white'}
                color={SiteColors.primary}
                height={'60px'}
                borderRadius={'full'}
              >
                Get A Free Consultation
              </AppButton>
            </Box>
          </Flex>
        </Flex>
      </AppComponentWrapper>
      <AppComponentWrapper bgColor={'white'} pb={12}>
        <AppFaqSection
          title="Frequently asked questions"
          description="We have put together some commonly asked questions"
          faqs={faqs || []}
          variant="light"
        />
      </AppComponentWrapper>
    </>
  );
};
