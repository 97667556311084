export interface AppChange {
  title: string;
  dateCreated: Date;
  changes: string[];
}

export const data: AppChange[] = [
  {
    title: 'V2.0.0 — Live Release',
    dateCreated: new Date('2025-01-13'),
    changes: ['New portal design (UI & UX)', 'Increased server capacity'],
  },
  {
    title: 'v1.0.0',
    dateCreated: new Date('2024-12-01'),
    changes: ['Beta feedback alignment', 'Beta bug fixes'],
  },
  {
    title: 'BETA',
    dateCreated: new Date('2024-10-20'),
    changes: ['Real user testing'],
  },
];
