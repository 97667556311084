import {
  Flex,
  Image,
  Icon,
  FlexProps,
  Box,
} from '@chakra-ui/react';
import { AppText, AppButton, AppColors } from '@backlinkit/shared';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { FaStar } from 'react-icons/fa';
import { months } from '../../../constants/months';
import { FaLinkedinIn } from 'react-icons/fa6';
import { ReactNode } from 'react';

export type ReviewAuthor = {
  name: string;
  image: string;
};

export type AppReview = {
  content: string;
  rating: number;
  source: string;
  author: ReviewAuthor;
  dateCreated: string;
};

export interface AppReviewsBannerProps extends FlexProps {
  title: string;
  description: string;
  reviews: AppReview[];
  btnLabel?: string;
  onBtnClick?: () => void;
  brands?: string[];
  theme?: 'light' | 'dark';
}

export const AppReviewsBanner: React.FC<AppReviewsBannerProps> = ({
  title,
  description,
  reviews,
  btnLabel = 'Start Your Free Trial Now',
  onBtnClick,
  brands,
  theme,
  ...props
}) => {
  const textColor = theme === 'dark' ? 'white' : 'black';

  const formatDate = (date: Date): string => {
    const dateString = date.toISOString();
    const string = dateString.split('T')[0];
    const year = string.split('-')[0];
    const day = string.split('-')[2];
    const month = string.split('-')[1];
    const monthIndex = month[0] === '0' ? month.slice(0) : month;
    const monthName = months[Number(monthIndex) - 1];
    return `${monthName} ${day}, ${year}`;
  };

  const getRating = (rating: number): ReactNode[] => {
    const stars: ReactNode[] = [];
    for (let i = 0; i <= rating; i++) {
      stars.push(<Icon as={FaStar} boxSize={'25px'} color={'#F5A623'} />);
    }
    return stars;
  };

  return (
    <Flex
      {...props}
      flexDir={'column'}
      gap={{ base: '20px', lg: '50px' }}
      width={'100%'}
      maxW={'1140px'}
      align={'center'}
      py={{ base: '40px', lg: '0' }}
      pt={{ base: '40px', lg: '80px' }}
    >
      <AppText variant={'h2'} color={textColor} textAlign={{ base: 'center', lg: 'left' }}>
        {title}
      </AppText>
      <AppText color={textColor} textAlign={'center'}>
        {description}
      </AppText>
      <Flex
        flexDir={{ base: 'column', xl: 'row' }}
        justify={'center'}
        align={'center'}
        w={'100%'}
        gap={5}
      >
        {reviews.map((review, idx) => (
          <Flex
            flexDir={'column'}
            p={6}
            borderRadius={'2xl'}
            key={`${review.author.name}-${idx}`}
            bgColor={'white'}
            maxW={'370px'}
            minH={'320px'}
            h={'100%'}
            w={'100%'}
            gap={4}
            justify={'space-between'}
          >
            <Box>
              <Icon as={RiDoubleQuotesL} boxSize={'20px'} />
              <AppText mt={4} fontSize={'18px'}>
                {review.content}
              </AppText>
            </Box>
            <Flex>{getRating(review.rating)}</Flex>
            <Flex w={'100%'} align={'center'} justify={'space-between'} pos={'relative'}>
              <Flex gap={4} align={'center'} pos={'relative'}>
                <Image src={review.author.image} boxSize={'30px'} />
                <Box>
                  <AppText>{review.author.name}</AppText>
                  <AppText>{review.dateCreated}</AppText>
                </Box>
              </Flex>
              <Box
                display={'flex'}
                bgColor={'#0072b1'}
                w={'35px'}
                minW={'35px !important'}
                h={'35px'}
                borderRadius={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Icon as={FaLinkedinIn} color={'white'} />
              </Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
      {onBtnClick && (
        <AppButton
          bgColor={AppColors.ctaColor}
          color={'white'}
          borderRadius={'full'}
          borderColor={'transparent !important'}
          h={'55px'}
          onClick={onBtnClick}
        >
          {btnLabel}
        </AppButton>
      )}
      <Flex
        align={'center'}
        gap={2}
        justify={'center'}
        w={'100%'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <AppText
          fontSize={{ base: '22px', lg: '28px' }}
          fontWeight={'black'}
          color={'white'}
          minW={{ base: 'auto', lg: '212px' }}
        >
          Trusted Clients
        </AppText>
        <Flex
          align={'center'}
          overflow={{ base: 'auto', lg: 'hidden' }}
          w={'100%'}
          justify={'space-between'}
          gap={{base: 3, lg: 0}}
        >
          {brands?.map((brand, idx) => (
            <Image
              key={`${brand}-${idx}`}
              src={brand}
              height={{ base: '75px',  md: '120px', lg: '140px' }}
              maxW={'175px'}
              objectFit={'contain'}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
