export interface Feature {
  title: string;
  description: string;
  bgImage?: string;
}

export const featuredIns = [
  'https://backlinkitprod.blob.core.windows.net/assets/e33e9c3b-e736-4933-bcc6-7762d396a69a_news-break.webp-1736500663895',
  'https://backlinkitprod.blob.core.windows.net/assets/25f0c241-a388-4c4f-b1d8-df16d176f980_MSN-2.webp-1736500663655',
  'https://backlinkitprod.blob.core.windows.net/assets/bfcdd624-0615-43c9-9a86-e3e61ec5d09a_ap-press.png-1736500663219',
  'https://backlinkitprod.blob.core.windows.net/assets/00f6298b-54c8-4828-be92-5ac618b22cdf_abcnews.webp-1736500661768',
  'https://backlinkitprod.blob.core.windows.net/assets/471390de-f8c5-422c-b0e8-f61504605ac1_big-news-network.webp-1736500663431',
];

export const appFeatures: Feature[] = [
  {
    title: 'Authority Link Building',
    description:
      'We acquire backlinks from high-authority websites relevant to your niche, ensuring that the links are valuable and positively impact your rankings.',
  },
  {
    title: 'Broken Link Recovery',
    description:
      'We identify broken links on relevant websites and suggest your content as a replacement, ensuring you gain powerful backlinks while helping other webmasters fix.',
  },
  {
    title: 'Broken Blogging',
    description:
      'Our outreach team connects with top-tier blogs and websites in your field, providing original, high-quality content with backlinks to your site.',
  },
];

export const gridFeatures: Feature[] = [
  {
    title: '148',
    description: 'Country databases',
    bgImage:
      'https://backlinkitprod.blob.core.windows.net/assets/d227ee9b-257d-4588-8966-c9e93d8ac737_tech-data-card-1.webp-1736500497732',
  },
  {
    title: 'AI',
    description: 'Powered algorythms',
    bgImage:
      'https://backlinkitprod.blob.core.windows.net/assets/0f23eb5e-7b33-457e-816e-9de0f1b5fb58_tech-data-card-2.webp-1736500498996',
  },
  {
    title: '3.5B',
    description: 'Keyword database',
    bgImage:
      'https://backlinkitprod.blob.core.windows.net/assets/ecb7d853-659d-46ac-ae7f-8cf074f82636_tech-data-card-3.webp-1736500499219',
  },
  {
    title: '1.7B',
    description: 'Backlinkit profiles',
    bgImage:
      'https://backlinkitprod.blob.core.windows.net/assets/5962de4e-c878-4c4f-84fc-b94211b30545_tech-data-card-4.webp-1736500499452',
  },
];
