import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import PaginationItem from "./pagination-item";

interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  onPrev: () => void;
  onNext: () => void;
  onPageClick: (page: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ currentPage, totalPages, onPrev, onNext, onPageClick }) => {
  const [pages, setPages] = useState<number[]>([]);

  const variant = useBreakpointValue({
    base: "mobile",
    md: "desktop",
  });

  const initializePaginationControls = () => {
    if (totalPages <= 3) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    switch (currentPage) {
      case 1:
        return [1, 2, 3];
      case totalPages:
        return [totalPages - 2, totalPages - 1, totalPages];
      default:
        return [currentPage - 1, currentPage, currentPage + 1];
    }
  };

  useEffect(() => {
    const newPages = initializePaginationControls();
    setPages(newPages);
  }, [totalPages, currentPage]);

  return (
    <Box
      mt="30px"
      height={{ md: "48px", base: '40px' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={variant === 'desktop' ? "10px" : '8px'}
      maxW={variant === 'desktop' ? '517px' : '320px'}
    >
      <Button
        bg={"#4A4F540D"}
        height="100%"
        color={"#3D424D"}
        onClick={onPrev}
        _hover={{
          color: currentPage === 1 ? "" : "white",
          backgroundColor: currentPage === 1 ? "" : "#037DFC",
        }}
        isDisabled={currentPage === 1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="7px"
        padding={variant === "desktop" ? "10px 14px" : "8px 12px"}
        fontWeight="400"
        mr={variant === "desktop" ? "22px" : "5px"}
      >
        <FaChevronLeft size="14px" />
        Prev
      </Button>
      <Flex gap={variant === 'desktop' ? "10px" : '8px'}>
        {pages.map((page, index) => (
          <PaginationItem
            key={index}
            page={page}
            isActive={currentPage === page}
            variant={variant === "desktop" ? "desktop" : "mobile"}
            onClick={onPageClick}
          />
        ))}
        {totalPages > 3 &&
          variant === "desktop" &&
          currentPage + 1 !== totalPages &&
          currentPage !== totalPages && (
            <>
              <Box
                width="48px"
                height="48px"
                bg={"#4A4F540D"}
                borderRadius="6px"
                padding={variant === "desktop" ? "10px" : "8px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                color={"#3D424D"}
              >
                ...
              </Box>
              <PaginationItem
                page={totalPages}
                isActive={false}
                variant={variant}
                onClick={onPageClick}
              />
            </>
          )}
      </Flex>
      <Button
        bg={"#4A4F540D"}
        _hover={{
          color: currentPage === totalPages ? "" : "white",
          backgroundColor: currentPage === totalPages ? "" : "#037DFC",
        }}
        height="100%"
        color={"#3D424D"}
        onClick={onNext}
        isDisabled={currentPage === totalPages}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="7px"
        padding={variant === "desktop" ? "10px 14px" : "8px 12px"}
        fontWeight="400"
        ml={variant === "desktop" ? "22px" : "5px"}
      >
        Next
        <FaChevronRight size="14px" />
      </Button>
    </Box>
  );
};

export default PaginationControls;
