export const articlePagePaddingLeft = {
  base: '16px',
  lg: 7,
  xl: '70px',
  '1xl': '100px',
  '2xl': '135px',
  '3xl': '0px'
};

export const articlePagePaddingRight = {
  base: '16px',
  lg: 7,
  xl: '70px',
  '1xl': '100px',
  '2xl': '232px',
  '3xl': '0px'
};