import { Flex, Link, Icon } from '@chakra-ui/react'
import { AppText } from '@backlinkit/shared'
import { FaCircleChevronRight } from "react-icons/fa6";

interface TableOfContentsProps {
  toc: string[]
  onSectionClick: (section: string) => void
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ toc, onSectionClick }) => {
  return (
    <Flex flexDirection="column" as="nav" gap={'16px'}>
      <AppText fontSize={'18px'} fontWeight={'600'} color={'#171B26'}>
        TABLE OF CONTENTS
      </AppText>
      <Flex flexDirection={'column'} gap={'18px'}>
        {toc.map((item, index) => (
          <Flex key={index} gap={'5px'} alignItems={'center'}>
            <Icon as={FaCircleChevronRight} boxSize={'21'} />
            <Link
              onClick={() => onSectionClick(item)}
              cursor="pointer"
              _hover={{ textDecoration: 'none' }}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 1,
                textOverflow: 'clip',
                whiteSpace: 'normal',
              }}
              fontSize={'16px'}
            >
              {item}
            </Link>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
