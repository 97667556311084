import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppTextContent } from '../../components/app/app-text-content/app-text-content';
import { AppText } from '@backlinkit/shared';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageDisclaimerPage } from '../../constants/meta-data';
import { heroBackground, mobileBackground } from '../../constants/heroImages';
import { RoutesList } from '../../router/router';

export const DisclaimerPage: React.FC = () => {
  usePageMeta(PageDisclaimerPage);
  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  const title = 'DISCLAIMER ';

  const crumbs = [
    { name: 'Legal', href: RoutesList.Legal },
    { name: 'Disclaimer', href: RoutesList.Disclaimer },
  ];

  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName={'Disclaimer'}
          breadCrumbs={crumbs}
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={12} px={{ base: 7, xl: 0 }}>
        <Flex
          bgColor={'rgba(238, 238, 238, 0.3)'}
          borderRadius={'2xl'}
          p={variant === 'mobile' ? 7 : 6}
          flexDir={'column'}
          maxW={'1140px'}
          width={'100%'}
          gap={6}
        >
          <AppText variant={'h3'}>{title}</AppText>
          <AppTextContent
            itemAlignemnt="start"
            title="1 INTRODUCTION"
            subHeading="This Disclaimer governs the use of the Website. By using the Website, you accept this Disclaimer in full."
          />
          <AppTextContent
            itemAlignemnt="start"
            title="2 DEFINITIONS AND INTERPRETATION"
            startIndex={2.1}
            alignItems={'start'}
            listItems={[
              `Please see our Definitions Key for a full explanation of all of the important terms that are used in this Disclaimer.`,
              `Unless the context clearly indicates a contrary intention, words and expressions defined in the Definitions Key shall bear corresponding meanings in this Disclaimer.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="3 NO ADVICE"
            subHeading="Any opinion or advice emanating from this Website must be read subject to our Terms of Use and the nature of the Services that we provide."
          />
          <AppTextContent
            itemAlignemnt="start"
            title="4 NO REPRESENTATIONS OR WARRANTIES"
            startIndex={4.1}
            alignItems={'start'}
            listItems={[
              `The use of this Website, for whatever purpose, is solely at your own risk.`,
              `Whilst all reasonable steps are taken to ensure the accuracy and integrity of any information transmitted electronically from this Website, BacklinkIt does not represent, warrant, undertake or guarantee that:`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            orderIndex={4.1}
            alignItems={'start'}
            listItems={[
              `the information on this Website is correct, up-to-date, accurate, complete and not misleading; or `,
              `the use of recommendations or guidance on the Website will lead to any particular outcome or result.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            startIndex={4.3}
            alignItems={'start'}
            listItems={[
              `To the maximum extent permitted by applicable law and subject to clause 6 below, we exclude all representations, warranties, undertakings and guarantees relating to the Website.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="5 LIMITATIONS AND EXCLUSIONS OF LIABILITY "
            startIndex={5.1}
            alignItems={'start'}
            listItems={[
              `The limitations and exclusions of liability set out in this clause 5 and elsewhere in this Disclaimer are subject to the provisions of clause 6 below; and govern all liabilities arising under this Disclaimer or in relation to the Website, including liabilities arising in contract, in delict (including negligence) and for breach of statutory duty.`,
              `We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.`,
              `We will not be liable to you in respect of any business losses, including, without limitation: loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill.`,
              `We will not be liable to you in respect of:`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            orderIndex={5.4}
            alignItems={'start'}
            listItems={[
              `any information or data on the Website that is, for whatever reason, incorrect; and/or`,
              `any loss or corruption of any data, database or software.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            startIndex={5.5}
            alignItems={'start'}
            listItems={[
              `We will not be liable to you in respect of any special, indirect or consequential loss or damage.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="6 EXCEPTIONS"
            subHeading="Nothing in this Disclaimer shall: "
            startIndex={6.1}
            alignItems={'start'}
            listItems={[
              `limit or exclude our liability for death or personal injury resulting from our negligence;`,
              `limit or exclude our liability for fraud or fraudulent misrepresentation; `,
              `limit any of our liabilities in any way that is not permitted under applicable law; or `,
              `exclude any of our liabilities that may not be excluded under applicable law.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="7 SEVERABILITY"
            startIndex={6.1}
            alignItems={'start'}
            listItems={[
              `If a section of this Disclaimer is determined by any court or other competent authority to be unlawful and/or unenforceable, the other sections of this Disclaimer shall continue in effect.`,
              `If any unlawful and/or unenforceable section would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the section will continue in effect.`,
            ]}
          />
          <AppTextContent
            itemAlignemnt="start"
            title="8 GOVERNING LAW"
            subHeading="This Disclaimer shall in all respects be governed by and construed in accordance with the prevailing laws of the Republic of South Africa."
          />
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
