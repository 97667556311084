import {
  Collapse,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { AppColors } from '../../theme';
import { AppText } from '../app-text/app-text';
import { ColumnVisibilityPopover } from './components/column-visibility-popover';
import { ColumnSearchSelectPopover } from './components/column-search-select-popover';
import {
  ColumnDefinitionType,
  TableRowsSelect,
  CellClickEvent,
  TableProps,
  TableCsvExporterConfig,
} from './app-data-table-types';
import { TableRows } from './components/app-table-row';
import { TableHeader } from './components/app-table-header';
import { BiSearch, BiSolidDownload } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { getColumnSortDir } from '../../utils/appTableHelpers';
import { format } from 'date-fns';
import { useLoading } from '../../providers';
const { JSONtoCSVConverter } = require('react-json-csv-convert');

export const AppDataTable = <T extends {}>({
  data,
  columns,
  selectableRows = false,
  searchBar = true,
  showColumnVisibility = true,
  showExportButton = false,
  showExpanding = false,
  exportFileName,
  noDataMessage = 'No data',
  onRowSelected,
  onTableCellClick,
  onSortByColumnClick,
  onSearchInputChange,
  isLoading,
  customFilter,
}: TableProps<T>): JSX.Element => {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { setLoading } = useLoading();
  const { isOpen, onToggle } = useDisclosure();
  const [tableData, setTableData] = useState<T[]>(data);
  const [exportConfig, setExportConfig] = useState<TableCsvExporterConfig>();

  const [tableColumns, setTableColumns] = useState<ColumnDefinitionType<T>[]>([]);
  const [tableSearchColumns, setTableSearchColumns] = useState<ColumnDefinitionType<T>[]>([]);
  const [filteredSearchColumns, setFilteredSearchColumns] = useState<ColumnDefinitionType<T>[]>([]);

  const selectedRowsRef = useRef<T[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [submittedGlobalFilterValue, setSubmittedGlobalFilterValue] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const setupExportLoader = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setTableData(data);

    if (showExportButton) {
      setupExportConfig(data);
    }

    if (selectedRowsRef && selectableRows) {
      selectedRowsRef.current = [];
    }
  }, [data]);

  // SETUP EXPORT CONFIG

  const setupExportConfig = (data: T[]) => {
    // const exportData = paginatedOrders.items?.map((item) => {
    //   return {
    //     orderNumber: item.orderNumber,
    //     customerPoNumber: item.poNumber || '',
    //     total: `${item.currency} ${item.totalOrderAmount}` || '',
    //     createdDate: format(new Date(item.orderDate), 'yyyy/MM/dd, HH:MM') || '',
    //     division: item.division,
    //     plantCode: item.plant,
    //     transportGroup: item.transportGroup,
    //     createdBy: item.createdBy,
    //   };
    // });
    const headers: string[] = [];
    columns.forEach((column) => {
      if (column.key != 'custom') {
        headers.push(column.header);
      }
    });

    const keys: string[] = [];
    columns.forEach((column) => {
      if (column.key != 'custom') {
        keys.push(column.key);
      }
    });

    const csvConfig = {
      headers,
      actions: Object.keys(headers).map((x) => null),
      keys: keys,
    };

    setExportConfig({
      csvConfig,
      exportData: data,
      exportFileName: exportFileName,
    });
  };

  useEffect(() => {
    if (columns) {
      const initTableColumns = [...columns];
      initTableColumns.forEach((col) => {
        if (col.isVisible === undefined) {
          col.isVisible = true;
        }

        if (col.headerSortable === undefined) {
          col.headerSortable = false;
        }

        if (col.columnSearchable === undefined) {
          col.columnSearchable = false;
        }
      });
      setTableColumns(initTableColumns);
      setTableSearchColumns(initTableColumns.filter((x) => x.columnSearchable === true));
      setFilteredSearchColumns(initTableColumns.filter((x) => x.columnSearchable === true));
    }
  }, []);

  const handleGlobalFilterChange = (search: string) => {
    setGlobalFilterValue(search);
  };

  const handleGlobalFilterSubmit = (searchValue: string) => {
    setSubmittedGlobalFilterValue(searchValue);
    onSearchInputChange &&
      onSearchInputChange({
        search: searchValue,
        selectedColumns: filteredSearchColumns,
      });
  };

  const onTableRowsSelectedChange = useCallback(
    (tableRowSelect: TableRowsSelect<T>) => {
      if (selectableRows && onRowSelected) {
        if (tableRowSelect.checked) {
          const selectedRows = selectedRowsRef.current;
          const rows = [...selectedRows, tableRowSelect.row];
          selectedRowsRef.current = rows;
          onRowSelected(rows);
        } else {
          const rows = [...selectedRowsRef.current.filter((x) => x !== tableRowSelect.row)];
          selectedRowsRef.current = rows;
          onRowSelected(rows);
        }
      }
    },
    [selectedRowsRef]
  );

  const handleColumnSearchSelect = (searchColumns: ColumnDefinitionType<T>[]) => {
    setFilteredSearchColumns(searchColumns);
  };

  const handleCellOnClickEvent = (clickEvent: CellClickEvent<T>) => {
    if (onTableCellClick) {
      onTableCellClick(clickEvent);
    }
  };

  const onSortByColumn = (column: ColumnDefinitionType<T>) => {
    const columnsCopy = [...tableColumns];
    const findColumnIndex = columnsCopy.findIndex((x) => x.key === column.key);

    if (findColumnIndex !== -1) {
      columnsCopy[findColumnIndex].headerSortDir = getColumnSortDir(
        columnsCopy[findColumnIndex].headerSortDir
      );
    }

    setTableColumns(columnsCopy);
    onSortByColumnClick && onSortByColumnClick(columnsCopy);
  };

  const visibleColumns = useMemo(() => {
    return tableColumns.filter((col) => col.isVisible);
  }, [tableColumns]);

  const renderTableContent = useMemo(() => {
    if (isLoading) {
      return (
        <Tbody>
          <Tr alignItems={'center'} justifyContent={'center'}>
            <Td colSpan={visibleColumns.length + (selectableRows ? 1 : 0)} py={3}>
              <AppText textAlign={'center'}>{noDataMessage}</AppText>
            </Td>
          </Tr>
        </Tbody>
      );
    }

    if (tableData.length > 0) {
      return (
        <TableRows
          selectableRows={selectableRows}
          selectedRows={selectedRowsRef.current}
          showExpanding={showExpanding}
          data={tableData}
          columns={visibleColumns}
          onRowsSelectedChange={(value) => onTableRowsSelectedChange(value)}
          onCellClick={handleCellOnClickEvent}
        />
      );
    }

    return (
      <Tbody>
        <Tr alignItems={'center'} justifyContent={'center'}>
          <Td colSpan={visibleColumns.length + (selectableRows ? 1 : 0)} py={3}>
            <AppText textAlign={'center'}>{noDataMessage}</AppText>
          </Td>
        </Tr>
      </Tbody>
    );
  }, [visibleColumns, tableData, selectAll, selectedRowsRef.current]);

  return (
    <>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        {searchBar && (
          <Flex flex={1} height={'55px'} gap={4}>
            <InputGroup size="md" maxW={'350px'}>
              <Input
                ref={searchInputRef}
                placeholder={'Search'}
                paddingRight={'35%'}
                borderRadius={'2xl'}
                height={'55px'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setGlobalFilterValue(e.currentTarget.value);
                    handleGlobalFilterSubmit(e.currentTarget.value);
                  }
                }}
                onChange={(e) => handleGlobalFilterChange(e.currentTarget.value)}
              />
              <InputRightElement w={'30%'} justifyContent={'end'} height={'55px'}>
                <Icon
                  mr={4}
                  boxSize={'25px'}
                  onClick={() => {
                    handleGlobalFilterSubmit(globalFilterValue);
                  }}
                  color={AppColors.primary}
                  as={BiSearch}
                />
                {globalFilterValue && globalFilterValue.length > 0 && (
                  <Icon
                    mr={4}
                    onClick={() => {
                      setGlobalFilterValue('');
                      handleGlobalFilterSubmit('');
                      if (searchInputRef && searchInputRef?.current) {
                        searchInputRef.current.value = '';
                      }
                    }}
                    color={AppColors.primary}
                    as={AiOutlineClose}
                    height={'55px'}
                  />
                )}
              </InputRightElement>
            </InputGroup>

            {tableSearchColumns && (
              <ColumnSearchSelectPopover
                columns={tableSearchColumns}
                filteredSearchColumns={filteredSearchColumns}
                onSubmit={handleColumnSearchSelect}
              ></ColumnSearchSelectPopover>
            )}

            {customFilter && (
              <Tooltip label={'Advanced Filters'} height={'55px'}>
                <IconButton
                  ml={4}
                  height={'55px'}
                  width={'55px'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  bgColor={'rgba(3, 125, 252, 0.2)'}
                  color={'white'}
                  aria-label="column visibility button"
                  borderRadius={'lg'}
                  onClick={onToggle}
                >
                  <Icon as={FaFilter} boxSize={'1em'} color={AppColors.secondary2} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        )}
        <Box mr="4" height={'55px'}>
          {selectedRowsRef.current && selectedRowsRef.current.length > 0
            ? `Selected ${selectedRowsRef.current.length} rows`
            : null}
        </Box>

        <Flex>
          {showExportButton && exportConfig && (
            <JSONtoCSVConverter
              csvConfig={exportConfig.csvConfig}
              data={exportConfig.exportData}
              styleProp={{ display: 'inline-block' }}
              fileName={`${exportConfig.exportFileName}-${format(new Date(), 'dd MMM yyyy')}`}
            >
              <Tooltip label={'Export to CSV'}>
                <IconButton
                  mr={4}
                  aria-label={'download'}
                  bg={AppColors.appBackground}
                  isRound={true}
                  onClick={() => {
                    setupExportLoader();
                  }}
                >
                  <Icon as={BiSolidDownload} boxSize={'6'} color={AppColors.primary} />
                </IconButton>
              </Tooltip>
            </JSONtoCSVConverter>
          )}

          {showColumnVisibility && (
            <ColumnVisibilityPopover
              columns={tableColumns}
              onSubmit={setTableColumns}
            ></ColumnVisibilityPopover>
          )}
        </Flex>
      </Flex>
      <TableContainer
        w={'100%'}
        mt={4}
        mb={4}
        borderRadius={8}
        border={'1px'}
        borderColor={AppColors.appBorder}
      >
        <Collapse in={isOpen} animateOpacity style={{ overflow: 'unset' }}>
          <Flex>{customFilter && customFilter}</Flex>
        </Collapse>
        <Table size={'sm'}>
          <TableHeader
            columns={visibleColumns}
            onSortByColumn={onSortByColumn}
            selectAll={selectAll}
            selectableRows={selectableRows}
            onSelectAll={() => {
              const rows = selectedRowsRef.current.length > 0 ? [] : tableData;
              selectedRowsRef.current = rows;
              setSelectAll(rows.length > 0);
              // console.log('Select All', rows.length > 0, selectedRowsRef.current);
              onRowSelected && onRowSelected(rows);
            }}
          />
          {renderTableContent}
        </Table>
      </TableContainer>
    </>
  );
};
