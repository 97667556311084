import { Box, Flex, Icon, useBreakpointValue } from '@chakra-ui/react';
import { AppText, AppButton, AppColors } from '@backlinkit/shared';
import { AppColors as SiteColors } from '../../../theme/index';
import { FaCheckCircle } from 'react-icons/fa';
import { ReactNode } from 'react';
import { baseUrl } from '../../../constants/nav-items';
import { FaArrowRightLong } from 'react-icons/fa6';

export type ItemFeature = {
  title: string;
  value: boolean | string | ReactNode;
};

export type ComparisonItem = {
  title?: string;
  price?: number | string;
  btnLabel: string;
  onClick?: () => void;
  features: ItemFeature[];
};

export interface AppComparisonTableProps {
  title: string;
  tag?: string;
  description?: string;
  comparisonItems: ComparisonItem[];
  footerTexts?: string[];
}

export const AppComparisonTable: React.FC<AppComparisonTableProps> = ({
  comparisonItems,
  title,
  description,
  footerTexts,
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });
  const renderFeatureItem = (feat: ItemFeature) => {
    if (typeof feat.value === 'string') {
      return <AppText>{feat.value}</AppText>;
    }
    if (typeof feat.value === 'boolean' && feat.value === true) {
      return <Icon as={FaCheckCircle} color={SiteColors.primary} />;
    }
    if (typeof feat.value === 'boolean' && feat.value === false) {
      return <AppText>-</AppText>;
    }
    if (typeof feat.value !== 'string' && typeof feat.value !== 'boolean') {
      return <>{feat.value}</>;
    }
  };

  const textSize = variant !== 'desktop' ? '24px' : '40px';

  return (
    <Flex
      width={'100%'}
      justify={'center'}
      flexDir={'column'}
      maxW={'100%'}
      alignItems={{ base: 'start', lg: 'center' }}
      px={variant === 'desktop' ? 0 : 7}
    >
      <Flex
        width={'100%'}
        justify={'center'}
        align={'center'}
        flexDir={'column'}
        textAlign={'center'}
        gap={4}
      >
        <AppText variant={'h2'} fontWeight={'700'}>
          {title}
        </AppText>
        {description && (
          <Box maxW={{ base: '100%', lg: '75%' }}>
            <AppText>{description}</AppText>
          </Box>
        )}
      </Flex>

      {variant !== 'desktop' && (
        <Flex w={'100%'} justify={'end'} align={'center'} mt={4} gap={2}>
          <AppText>Scroll</AppText>
          <Icon as={FaArrowRightLong} />
        </Flex>
      )}

      <Flex
        flexDirection={'column'}
        width={'100%'}
        overflowX={{ base: 'scroll', lg: 'hidden' }}
        alignItems={{ base: 'start', lg: 'center' }}
      >
        <Flex
          mt={{ base: 4, lg: 8 }}
          w={'1200px'}
          maxW={'1200px'}
          borderTopRadius={'3xl'}
          pt={4}
          bgColor={'rgba(3, 125, 252, 0.1)'}
        >
          <Flex minH={'110px'} align={'center'} width={'1200px'}>
            <Flex w={'240px'} height={'100%'} justify={'center'} align={'center'} py={4}>
              <AppText fontWeight={'bold'} fontSize={textSize} mb={8}>
                Features
              </AppText>
            </Flex>
            {comparisonItems.map((item, idx) => (
              <Flex
                w={'240px'}
                height={'100%'}
                key={`column-${item.title}-${idx}`}
                flexDir={'column'}
                justify={'space-between'}
                gap={4}
                p={4}
                borderLeft={'1px solid'}
                borderColor={'rgba(0, 0, 0, 0.1)'}
                minH={'110px'}
                align={'center'}
              >
                <AppText>{item.title}</AppText>
                {item.title === 'Pay As You Go' && <AppText>From</AppText>}
                <Flex align={'center'} gap={2}>
                  <AppText fontWeight={'bold'} fontSize={textSize}>
                    {typeof item.price === 'number' ? `$${item.price}` : item.price}
                  </AppText>
                  <AppText fontSize={variant === 'desktop' ? '22px' : '18px'} fontWeight={'500'}>
                    {item.title === 'Pay As You Go' ? '/ check' : '/ month'}
                  </AppText>
                </Flex>
                <Box>
                  <AppButton
                    bgColor={SiteColors.primary}
                    height={'50px'}
                    borderRadius={'full'}
                    color={'white'}
                    onClick={() => window.open(baseUrl, `_self`)}
                  >
                    {item.btnLabel}
                  </AppButton>
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex gap={0}>
          <Flex width={'240px'} height={'100%'} flexDir={'column'}>
            {comparisonItems[0].features.map((item, idx) => (
              <Flex
                key={`${item.title}-${idx}-${idx}`}
                w={'240px'}
                minH={'110px'}
                justify={'center'}
                border={`1px solid ${AppColors.appBorder}`}
                align={'center'}
                textAlign={'center'}
                px={2}
              >
                <AppText fontWeight={'500'}>{item.title}</AppText>
              </Flex>
            ))}
          </Flex>
          {comparisonItems.map((item, idx) => (
            <Flex flexDir={'column'} key={`${item.title}-${idx}`} w={'240px'} height={'100%'}>
              {item.features.map((feat, i) => (
                <Flex
                  key={`${feat.title}-${i}-${idx}`}
                  w={'240px'}
                  minH={'110px'}
                  justify={'center'}
                  border={`1px solid ${AppColors.appBorder}`}
                  align={'center'}
                  textAlign={'center'}
                >
                  {renderFeatureItem(feat)}
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Box
        w={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        flexDir={'column'}
        my={8}
      >
        <Flex maxW={{ base: '100%', lg: '75%' }} flexDir={'column'} gap={4}>
          {footerTexts?.map((item, idx) => (
            <AppText key={`${item}-${idx}`} textAlign={'center'}>
              {item}
            </AppText>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
