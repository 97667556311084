import { theme } from './theme/index';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './App.css';
import { PanelServiceProvider, DialogServiceProvider } from '@backlinkit/shared';
import { WebPanelServiceProvider } from './providers/panel/web-panel-service';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PanelServiceProvider>
          <WebPanelServiceProvider>
            <DialogServiceProvider>
              <Router></Router>
            </DialogServiceProvider>
          </WebPanelServiceProvider>
        </PanelServiceProvider>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
