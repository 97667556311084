import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { FAQ } from '@backlinkit/shared';

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchFeaturedFaqs: build.query<FAQ[], any>({
      query: () => ({
        url: '/faq/featured',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchFeaturedFaqsQuery } = faqApi