import { AppColors } from '../../theme';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
} from '@chakra-ui/react';
import * as React from 'react';


export interface WebPanelOptions {
  catchOnCancel?: boolean;
  title?: string;
  noTitle?: boolean;
  size?: 'full' | 'xl' | 'lg' | 'md' | 'sm';
  render?: (onSubmit: () => void, onCancel: () => void) => React.ReactNode;
  noPadding?: boolean;
  closeButtonColor?: string;
  showClose?: boolean;
  showOverlay?: boolean;
}

interface WebPanelProps extends WebPanelOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const WebPanel: React.FC<WebPanelProps> = ({
  open,
  title,
  noTitle = false,
  size = 'md',
  closeButtonColor = 'black',
  noPadding = false,
  showClose = true,
  showOverlay = false,
  render,
  onSubmit,
  onClose,
}) => {
  const logo = 'https://backlinkitprod.blob.core.windows.net/assets/897aaefd-bea5-4393-b53d-db2ab3a8267c_backlinkit-white-logo.webp-1736509443818'
  return (
    <Drawer
      isOpen={open}
      placement="left"
      onClose={onClose}
      size={size}
      closeOnOverlayClick={showClose ? true : false}
    >
      {showOverlay ? (
        <DrawerOverlay w={'full'} bg="blackAlpha.300" backdropFilter="blur(10px)" />
      ) : (
        <DrawerOverlay w={'full'} />
      )}
      <DrawerContent h={'100%'} bgColor={AppColors.primary}>
        {showClose && <DrawerCloseButton zIndex={5} color={'white'} />}
        <DrawerHeader>
          <Image src={logo} height={'30px'} />
        </DrawerHeader>

        <DrawerBody p={noPadding ? 0 : 3}>{render && render(onSubmit, onClose)}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
