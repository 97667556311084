import {
  Flex,
  Stack,
  Text,
  Box,
  List,
  Icon,
  useBreakpointValue,
  SimpleGrid,
} from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AppButton, ProductDto } from '@backlinkit/shared';
import { useEffect } from 'react';
import { baseUrl } from '../../constants/nav-items';

export interface CardProps {
  products: ProductDto[];
  annaulPrices?: boolean;
}

const PriceCards: React.FC<CardProps> = ({ products, annaulPrices }) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    xl: 'desktop',
  });
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8,
  });

  const gridColumns = useBreakpointValue({
    base: 1,
    lg: 3,
  });

  const getColor = (x: ProductDto): string => (x.name === 'Basic Plan' ? 'white' : 'black');

  useEffect(() => {}, [annaulPrices]);

  return (
    <SimpleGrid columns={gridColumns} gap={cardGap}>
      {products.map((x) => {
        return (
          <Flex
            key={x.id}
            justify={'space-evenly'}
            pos={'relative'}
            boxShadow={'xl'}
            gap={8}
            w={variant === 'mobile' ? '100%' : '280px'}
            flex={1}
            direction={'column'}
            borderRadius={'2xl'}
            border={
              x.name === 'Basic Plan'
                ? `1.5px solid ${AppColors.primary}`
                : `1px solid ${AppColors.appBorder}`
            }
            bgColor={x.name === 'Basic Plan' ? AppColors.primary : 'white'}
            p={variant === 'mobile' ? 4 : 6}
          >
            <Flex
              flexDir={'column'}
              align={'start'}
              w={'100%'}
              borderTopRadius={'2xl'}
              bgColor={x.name === 'Basic Plan' ? `${AppColors.primary}` : `white`}
              pos={'relative'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
              pb={4}
            >
              <Text fontWeight={'600'} textAlign={'center'} color={getColor(x)}>
                {x.name}
              </Text>
              <Flex align={'center'} justify={'center'} pos={'relative'} gap={2}>
                {x.price > 0 && (
                  <Text
                    pos={'absolute'}
                    color={x.name === 'Basic Plan' ? 'white' : 'black'}
                    left={0}
                    top={0}
                  >
                    $
                  </Text>
                )}
                <Text
                  textAlign={'center'}
                  fontSize={'2xl'}
                  fontWeight={'700'}
                  color={getColor(x)}
                  marginLeft={'15px'}
                >
                  {annaulPrices ? `${(x.price * 12) / 0.75}/` : `${x.price}/`}
                </Text>
                <Text fontWeight={'500'} color={getColor(x)} mt={2}>
                  {annaulPrices ? 'Yearly' : 'Monthly'}
                </Text>
              </Flex>
            </Flex>
            <Stack spacing={3}>
              <List spacing={3}>
                <Text color={getColor(x)}>{x.description}</Text>
                <Flex align={'center'} gap={3}>
                  <Icon
                    as={CheckCircleIcon}
                    color={x.name === 'Basic Plan' ? 'white' : AppColors.primary}
                  />
                  <Text color={getColor(x)}>{`${x.price} links`}</Text>
                </Flex>
              </List>
            </Stack>
            <Box width={'100%'}>
              <AppButton
                borderRadius={'full'}
                borderColor={`${AppColors.primary} !important`}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                color={AppColors.primary}
                onClick={() => {
                  window.open(
                    `${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`,
                    '_self'
                  );
                }}
              >
                {x.isInitial ? 'Start 7 Day Trial' : 'Get Started'}
              </AppButton>
            </Box>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
export default PriceCards;
