import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppColors, AppText } from '@backlinkit/shared';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppTextContent } from '../../components/app/app-text-content/app-text-content';
import { webDefitions } from './terms';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageDefinitionsPage } from '../../constants/meta-data';
import { heroBackground, mobileBackground } from '../../constants/heroImages';
import { useEffect } from 'react';
import { RoutesList } from '../../router/router';

export const DefinitionsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  usePageMeta(PageDefinitionsPage);

  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  const title = 'DEFINITIONS ';

  const crumbs = [
    { name: 'Legal', href: RoutesList.Legal },
    { name: 'Definitions', href: RoutesList.Definitions },
  ];

  return (
    <>
      <AppComponentWrapper px="0">
        <AppBasicHero
          pageName={'Definitions'}
          breadCrumbs={crumbs}
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={12} justify={'center'}>
        <Flex
          bgColor={'rgba(238, 238, 238, 0.3)'}
          borderRadius={'2xl'}
          p={variant === 'mobile' ? 7 : 6}
          flexDir={'column'}
          justify={'start'}
          maxW={'1140px'}
          width={'100%'}
          gap={6}
        >
          <AppText variant={'h3'}>{title}</AppText>
          <AppTextContent
            itemAlignemnt="start"
            title="1 INTRODUCTION"
            startIndex={1.1}
            listItems={[
              `We are required, on our Website, and throughout all of our terms, agreements, policies and procedures, to make use of certain legal terms. Certain words will also have a very specific meaning that is only applicable within the context of the page or the document that you are reading. We appreciate that this can be confusing, and it is for this reason that we have created this Definitions Key to assist you.`,
              `This Definitions Key contains an explanation of all of the important terms and words that are used on our Website, in the Terms of Use and in the Ancillary Terms and gives each of them a specific meaning within the context of the document that you are reading. Please refer back to this Definitions Key whenever you are uncertain about the meaning or context of any word that you come across on our Website, or in any of our legal documents.`,
              `In addition to other terms that have been defined on the page or in the body of the document that you are reading, the following words have the following meanings: `,
            ]}
          />
          <Flex
            width={'100%'}
            overflow={'auto'}
            justify={{ base: 'start', lg: 'center' }}
            flexDir={'column'}
            p={4}
          >
            {webDefitions.map((item, idx) => (
              <Flex
                align={'center'}
                width={'100%'}
                borderTop={`1px solid ${AppColors.appBorder}`}
                borderBottom={`1px solid ${AppColors.appBorder}`}
                key={`${item.term}-${idx}`}
                minW={'600px'}
                overflow={'auto'}
              >
                <Flex
                  w={'100%'}
                  height={'100%'}
                  borderLeft={`1px solid ${AppColors.appBorder}`}
                  borderRight={`1px solid ${AppColors.appBorder}`}
                  p={2}
                >
                  <AppText fontWeight={'500'} textAlign={'center'}>
                    {item.term}
                  </AppText>
                </Flex>
                <Flex
                  w={'100%'}
                  height={'100%'}
                  borderLeft={`1px solid ${AppColors.appBorder}`}
                  borderRight={`1px solid ${AppColors.appBorder}`}
                  p={2}
                >
                  <AppText fontWeight={'500'} textAlign={'center'}>
                    {item.definition}
                  </AppText>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
