import { Box, Flex, Icon, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { LegalCardProps } from '../../../models/legal';
import { AppText } from '@backlinkit/shared';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { NavLink as RouterLink } from 'react-router-dom';

const LegalCard: React.FC<LegalCardProps> = ({ icon, title, link }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  return (
    <Flex
      px={variant === 'desktop' ? '35px' : '14px'}
      py={variant === 'desktop' ? '30px' : '17px'}
      w={{ base: '100%', md: '176px', lg: '100%', xl: '100%', '1xl': '370px' }}
      height={'100%'}
      minHeight={'118px'}
      border="1px solid #EBEBEB"
      borderRadius="24px"
      backgroundColor={isHovered ? '#037DFC' : 'transparent'}
      flexDir={'column'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon
        as={icon}
        boxSize={variant === 'desktop' ? '56px' : '17px'}
        color={isHovered ? '#FFFFFF' : '#037DFC'}
        mb={variant === 'desktop' ? '38px' : '18px'}
      />
      <AppText
        fontSize={variant === 'desktop' ? '24px' : '14px'}
        fontWeight={'600'}
        height={'100%'}
        mb={variant === 'desktop' ? '18px' : '8px'}
        color={isHovered ? '#FFFFFF' : '#171B26'}
      >
        {title}
      </AppText>

      <Flex flexDirection={'row'} width={'100%'} justifyContent={'space-between'} gap={2}>
        <Box
          as={RouterLink}
          to={link ?? ''}
          textDecoration={'underline'}
          color={isHovered ? '#EBEBEB' : '#037DFC'}
          fontSize={variant === 'desktop' ? '18px' : '10px'}
        >
          Read the document
        </Box>
        <Flex
          width={variant === 'desktop' ? '30px' : '15px'}
          minWidth={variant === 'desktop' ? '30px' : '15px'}
          height={variant === 'desktop' ? '30px' : '15px'}
          minHeight={variant === 'desktop' ? '30px' : '15px'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'50%'}
          backgroundColor={isHovered ? '#FFFFFF' : '#037DFC'}
        >
          <Icon
            as={FaExternalLinkAlt}
            width={variant === 'desktop' ? '15px' : '7px'}
            color={isHovered ? '#037DFC' : '#FFFFFF'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LegalCard;
