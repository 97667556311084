import { Box, Flex, FlexProps, Icon, useBreakpointValue } from '@chakra-ui/react';
import { AppChangelogCard } from '../app-change-log-card/app-changelog-card';
import { AppChange } from '../../../constants/change-mocks';
import { AppColors as SiteColors } from '../../../theme/index';
import { AppText } from '@backlinkit/shared';
import { IoMdArrowDropup } from 'react-icons/io';
import { IoMdArrowDropdown } from 'react-icons/io';

export interface AppChangeListProps extends FlexProps {
  changes: AppChange[];
}

export const AppChangeList: React.FC<AppChangeListProps> = ({ changes, ...props }) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <Flex
      align={'center'}
      justify={'center'}
      width={'100%'}
      maxW={'1140px'}
      flexDir={'column'}
      px={variant !== 'desktop' ? 7 : 0}
      {...props}
      pos={'relative'}
      height={'100%'}
      overflowY={'auto'}
      gap={4}
    >
      <Icon
        as={IoMdArrowDropup}
        color={SiteColors.primary}
        pos={'absolute'}
        top={'0px'}
        left={variant !== 'desktop' ? '26px' : '-3px'}
        boxSize={'40px'}
      />
      <Icon
        as={IoMdArrowDropdown}
        color={SiteColors.primary}
        pos={'absolute'}
        bottom={'0px'}
        left={variant !== 'desktop' ? '26px' : '-3px'}
        boxSize={'40px'}
      />
      <Box
        width={'30px'}
        height={'calc(100% - 50px)'}
        borderLeft={`3px dashed ${SiteColors.primary}`}
        pos={'absolute'}
        left={variant !== 'desktop' ? '45px' : '15px'}
        zIndex={5}
      />
      {changes.map((change, idx) => (
        <Flex
          key={`${change.title}-${idx}`}
          align={'center'}
          height={'100%'}
          w={'full'}
          gap={variant === 'desktop' ? '80px' : 4}
          zIndex={6}
        >
          <Flex
            boxSize={'35px'}
            p={4}
            justify={'center'}
            align={'center'}
            bgColor={SiteColors.primary}
            borderRadius={'full'}
          >
            <AppText color={'white'}>{idx + 1}</AppText>
          </Flex>
          <AppChangelogCard
            title={change.title}
            dateUpdated={change.dateCreated}
            changes={change.changes}
          />
        </Flex>
      ))}
    </Flex>
  );
};
