import { Repeatable } from '../components/app/app-banner-list/app-banner-list';

export const manageBenefits: Repeatable[] = [
  {
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/f85d6a90-f941-47eb-b324-cddfe887d546_icon-1.webp-1736505051488',
    title: 'Methodology',
    description: `Utilise our renowned "OCF" Analysis (Opportunity, Competition, Forecast) to revolutionise your SEO approach. Acquire a thorough understanding of the content and ranking of your website, enabling you to make customised recommendations to increase visibility from a technical and content standpoint. It's your roadmap to enhanced online presence and success.
`,
  },
  {
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/606e1c05-ab70-46a6-a17b-0c5bbf8cca09_icon-2.webp-1736505053174',
    title: 'Execution',
    description: `We provide complete assistance in putting the recommendations into practice once the research is finished. We can help with every aspect of your SEO plan, from generating content (using Keyword Insights, of course) to link building, communicating with developers on technical issues, and supporting internal SEO teams.`,
  },
  {
    icon: 'https://backlinkitprod.blob.core.windows.net/assets/87e43fb9-5450-4477-af92-534b7855c5af_icon-3.webp-1736505053454',
    title: 'Instruction',
    description: `Utilise our training offerings to empower your staff. We offer thorough training to improve your team's SEO proficiency and promote long-term success, whether it's optimising internal content processes, developing link-building techniques, or proficiently using the Keyword Insights program.`,
  },
];

export const ourApproach: Repeatable[] = [
  {
    title: 'We are quite adaptable',
    description: "Whether you need quick training for your team, a thorough investigation into a specific challenge, or a long-term partner..."
  },
  {
    title: 'We develop our own technology',
    description: "We've developed our own, industry-leading software meaning we work more effeciently and with better insights. Oh yes, don't..."
  },
  {
    title: 'We pay attention to meaningful measures.',
    description: "Drawing from our own experience as business ownwers, we understand the importance of revenue. Our priority is driving..."
  },
]