import { BacklinkGoogleData } from './backlink-google-data';
import { BacklinkTag } from './backlinkTag';
import { Base, PaginationFilter, SearchParam, SortParam } from './base';
import { BacklinkScraperValueDifference, LinkMeta, ScrapeError } from './scrapeModels';

export type BacklinkLink = {
  id?: number;
  parent_backlink: BackLink;
  backlink: BackLink;
};

export type BacklinkFormData = {
  id?: string;
  url: string;
  price?: number;
  priceCurrency?: string;
  expectedLandingPage?: string;
  expectedAnchor?: string;
  expectedRel?: string;
  notes?: string;
  tempId?: number;
};

export interface BackLink extends Base {
  url: string;
  landingPage?: string;
  anchor?: string;
  relValue?: string;
  expectedLandingPage?: string;
  expectedAnchor?: string;
  expectedRelValue?: string;
  notes?: string;
  isManual?: boolean;
  backlinkType: BacklinkTypes;
  scrapeCount?: number;
  domainId: string;
  backlinkBatchId?: string;
  backlinkEfl?: BacklinkEFL;
  backlinkGoogle?: BacklinkGoogleData;
  parentId?: string;
  backlinkMeta?: LinkMeta;
  errors?: ScrapeError[];
  previousLinkMeta?: PreviousBacklinkDto;
  backlinks?: BackLink[];
  currency?: string;
  price?: string;
  backlinkTags?: BacklinkTag[];
}

export interface PreviousBacklinkDto {
  relStatus?: string;
  relValue?: string;
  landingPage?: string;
  anchor?: string;
  anchorContextInfo?: string;
  robotsIndexStatus?: string;
  robotsIndexValue?: string;
  cacheCreatedDate?: Date | string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  cacheState?: boolean;
}

// export interface BacklinkDto extends Base {
//   url: string;
//   landingPage: string | null;
//   anchor: string | null;
//   relValue: string | null;
//   expectedLandingPage: string | null;
//   expectedAnchor: string | null;
//   expectedRelValue: string | null;
//   notes: string | null;
//   isManual: boolean | null;
//   backlinkType: BacklinkTypes;
//   scrapeCount: number | null;
//   domainId: string;
//   backlinkBatchId: string | null;
//   backlinkEfl: BacklinkEFL | null;
//   backlinkGoogle: BacklinkGoogleData | null;
//   backlinkCategory: BacklinkCategory | null;
//   backlinkCategoryId: string | null;
//   parentId: string | null;
//   backlinkMeta: LinkMeta | null;
//   errors: ScrapeError[] | null;
//   previousLinkMeta: LinkMeta | null;
//   backlinkTags: BacklinkTag[] | null;
//   backlinks: BacklinkDto[] | null;
//   batchId?: string;
// }

export interface BacklinkUpdateRequest extends BackLink {
  tagChanges: boolean;
  tagIds?: string[];
}

export enum BacklinkTypes {
  tierOne = 0,
  tierTwo = 1,
}

export enum BacklinkEFLLinkType {
  'follow' = 'follow',
  'nofollow' = 'nofollow',
}

export type BacklinkEFLLink = {
  id?: string;
  href: string;
  type: BacklinkEFLLinkType;
};

export type BacklinkEFL = {
  backlink: string;
  links: BacklinkEFLLink[];
};

export type BacklinkLinkCreate = {
  parent_backlink: string;
  backlink: string;
};

export interface BackLinkCreate {
  url: string;
  domainId: string;
  expectedLandingPage?: string;
  expectedAnchor?: string;
  expectedRelValue?: string;
  dateExpires?: Date;
  dateLive?: Date;
  currency?: string;
  price?: string;
  notes?: string;
  parentId?: string;
  backlinkType: BacklinkTypes;
  tagIds?: string[];
  backlinkCategoryId?: string;
  backlinkBatchId?: string;
}

// export interface BackLinkEdit extends BackLinkCreate {
//   id: string;
//   isManual?: boolean;
//   landingPage?: string;
//   anchor?: string;
//   backlinkMeta?: LinkMeta;
//   errors?: ScrapeError[];
// }

export type BackLinkClearAlertsParams = {
  id: string;
  errors: ScrapeError[];
};

export type BackLinkSetManualParams = {
  id: string;
  alerts: ScrapeError[];
  landingPage: string;
  anchor: string;
  backlinkMeta: LinkMeta;
  isManualMode?: boolean;
};

export type BackLinkListItem = BackLink & {
  secondTierLinkCount: number;
  errorCount: number;
  scraperDifference: BacklinkScraperValueDifference;
  savedDifferences?: boolean;
};

export type BacklinkCSVImport = {
  Url: string;
  Expected_Anchor?: string;
  Expected_Landing_Page?: string;
  'Follow(True/False)'?: string;
  'NoFollow(True/False)'?: string;
  'UGC(True/False)'?: string;
  'Sponsored(True/False)'?: string;
};

export type BacklinkCSVImportValidated = {
  lineNumber: number;
  value: string | boolean | number;
  message?: string;
};

export type BackLinkBulkCreateResponse = {
  createdBacklinkIds: string[];
};

export interface BackLinkUpdateBulkRequest {
  backlinks: BacklinkBulkScrapeItem[];
}

export interface BacklinkBulkCreateRequest {
  domainId: string;
  backlinks: BackLink[];
  backlinkBatchId?: string;
  backlinkBatchPrice?: number;
  tagIds: string[];
}

export interface BacklinkBulkDeleteRequest {
  backlinks: string[];
}

export interface BacklinkBulkScrapeItem extends BacklinkQueueModel {
  isManual: boolean;
}

export interface BacklinkQueueModel {
  backlinkId: string;
  parentUrl: string;
  url: string;
  expectedLandingPage: string | null;
  expectedAnchor: string | null;
  expectedRel: string | null;
  backlinkType: BacklinkTypes;
}

export interface BacklinkScrapeRequest {
  backlinkId: string;
  parentUrl: string;
}

export interface BacklinkGoogleBulkRequest {
  domainId: string;
  checkGoogleIndex: boolean;
  checkGoogleCache: boolean;
  parentUrl: string;
  backlinks: BacklinkGoogleRequest[];
}

export interface BacklinkGoogleRequest {
  backlinkId: string;
  backlinkUrl: string;
}

export interface BacklinkByOptionsRequest extends PaginationFilter {
  domainId?: string;
  backlinkBatchId?: string;
  searchParam?: SearchParam;
  sortParams?: SortParam[];
  filterOptions?: BacklinkFilter;
}

export interface BacklinkFilter {
  backlinkBatchId?: string;
  addedDateFrom?: string;
  addedDateTo?: string;
  tags?: string[];
  linkType?: string;
  robotsIndexStatus?: string;
  linkStatuses?: string[];
  relStatuses?: string[];
  hasPageIndex?: boolean;
  hasDomainIndex?: boolean;
  withNotes?: boolean;
  hasGoogleCache?: boolean;
  hasErrors?: boolean;
}

export enum GiOptionsValues {
  PageIndexed = 'Page Indexed',
  DomainIndexed = 'Domain Indexed',
  NoIndex = 'No Index',
}

export enum NotesFilterValues {
  WithNotes,
  WithoutNotes,
}

export interface CheckerBacklinkByOptionsRequest {
  domainId: string;
  urls: string[];
}

export interface CheckerBacklinkByOptionsResponse {
  linkCheckerData: LinkCheckerData[];
}

export interface LinkCheckerData {
  url: string;
  backlinks: BackLink[];
  status: TrackedStatus;
}

export enum TrackedStatus {
  NOT_TRACKED,
  DOMAIN_TRACKED,
  TRACKED,
}
