import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  FlexProps,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { AppText } from '../app-text/app-text';
import { useEffect } from 'react';
import { PageChangeEvent, Pagination } from '../../models/shared';
import usePagination from '../../hooks/usePagination';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiChevronRight, FiChevronsRight, FiChevronLeft, FiChevronsLeft } from 'react-icons/fi';

export type AppPaginatorProps = {
  pagination?: Pagination;
  onPageChange: (event: PageChangeEvent) => void;
} & FlexProps;

const PaginationDataSchema = yup.object({
  page: yup.number().min(1),
});

type PaginationFormData = {
  page: number;
};

export const AppPaginator: React.FC<AppPaginatorProps> = ({
  pagination = { page: 1, pageSize: 25, pageCount: 1, total: 0 },
  bgColor = AppColors.tertiary,
  onPageChange,
  ...props
}) => {
  const {
    currentPage,
    currentPageSize,
    currentPageCount,
    goToNextPage,
    goToPreviousPage,
    goToFirstPage,
    goToLastPage,
    setPage,
    canGoPreviousPage,
    canGoNextPage,
    currentPageSizes,
    setPageSize,
    setNewPaginationProps,
  } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    pageCount: pagination.pageCount,
    total: pagination.total,
  });
  const { control, setValue, getValues } = useForm<PaginationFormData>({
    defaultValues: { page: currentPage },
    resolver: yupResolver(PaginationDataSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    setNewPaginationProps(pagination);
  }, [pagination]);

  useEffect(() => {
    onPageChange({
      currentPage: currentPage,
      currentPageSize: currentPageSize,
      currentPageCount: currentPageCount,
    });
  }, [currentPage, currentPageSize]);

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);

    if (!Number.isNaN(value)) {
      setPageSize(value);

      setValue('page', 1);
      setPage(1);
    }
  };

  const handlePageNavigationSubmit = (value: string) => {
    const valueParsed = parseInt(value);
    const currentForm = getValues();

    if (!Number.isNaN(valueParsed) && valueParsed > 0) {
      if (currentPage !== currentForm.page) {
        setPage(Number(valueParsed));
      }
    }
  };

  return (
    <Flex position={'relative'} justifyContent="space-between" m={4} alignItems="center" {...props}>
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => goToFirstPage()}
            isDisabled={!canGoPreviousPage}
            icon={<FiChevronsLeft />}
            boxSize={'40px'}
            mr={4}
            aria-label={'First Page'}
            color={'white'}
            bg={AppColors.secondary2}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={goToPreviousPage}
            isDisabled={!canGoPreviousPage}
            icon={<FiChevronLeft />}
            boxSize={'40px'}
            aria-label={'Previous Page'}
            color={'white'}
            bg={AppColors.secondary2}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <AppText fontSize={'16px'} flexShrink="0" mr={8}>
          Page{' '}
          <AppText fontSize={'16px'} fontWeight="bold" as="span">
            {currentPage}
          </AppText>{' '}
          of{' '}
          <AppText fontSize={'16px'} fontWeight="bold" as="span">
            {currentPageCount}
          </AppText>
        </AppText>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={goToNextPage}
            isDisabled={!canGoNextPage}
            icon={<FiChevronRight />}
            boxSize={'40px'}
            aria-label={'Next Page'}
            color={'white'}
            bg={AppColors.secondary2}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => goToLastPage()}
            isDisabled={!canGoNextPage}
            icon={<FiChevronsRight />}
            boxSize={'40px'}
            ml={4}
            aria-label={'Last Page'}
            color={'white'}
            bg={AppColors.secondary2}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            _active={{
              bg: `${AppColors.tertiary}`,
            }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
