import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { AppAlertItemList } from '../app-alert-list';
import { FiAlertCircle } from 'react-icons/fi';
import { AppColors } from '../../theme';

export type AppInputType = 'text' | 'password' | 'number' | 'file';
export type AppInputVariants = 'default' | 'solid' | 'blue' | 'grayed' | 'rounded' | 'blueSquared';

export type AppInputProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  placeHolder?: string;
  label?: string;
  textArea?: boolean;
  inputType?: AppInputType;
  labelColor?: string;
  variant?: AppInputVariants;
  tooltip?: string;
};

export const AppInput = <T extends FieldValues>({
  control,
  name,
  error,
  placeHolder,
  label,
  textArea = false,
  inputType = 'text',
  labelColor,
  variant = 'default',
  tooltip,
  ...props
}: AppInputProps<T>) => {
  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            {label && (
              <Flex justifyContent={'start'} gap={1}>
                <FormLabel fontSize={'14px'} color={labelColor}>
                  {label}
                </FormLabel>
                {tooltip && (
                  <Tooltip aria-label="tooltip" placement="bottom" label={tooltip}>
                    <Box>
                      <Icon as={FiAlertCircle} boxSize={'14px'} color={AppColors.primary} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            )}

            {!textArea ? (
              <Input
                type={inputType}
                name={name}
                placeholder={placeHolder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                h={'60px'}
                _placeholder={{
                  color: 'gray.500',
                }}
                // borderRadius={'full'}
                variant={variant}
              />
            ) : (
              <Textarea
                name={name}
                placeholder={placeHolder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                _placeholder={{
                  color: 'gray.500',
                }}
                variant={variant}
              />
            )}
            {/* {error && <div style={{ color: 'red' }}>{error?.message}</div>} */}

            {error && error?.message && (
              <Box mt={2}>
                <AppAlertItemList
                  messages={[error?.message]}
                  alertType={'error'}
                ></AppAlertItemList>
              </Box>
            )}
          </FormControl>
        )}
      />
    </Flex>
  );
};
