import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppInput,
  AppText,
  AppButton,
  AppColors,
  CreateUserLead,
  AppAlertItem,
} from '@backlinkit/shared';
import { BaseFormProps } from '../../../models/base';
import { Flex, Box, Link, Checkbox, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../../router/router';
import { useState } from 'react';
import { ImWarning } from 'react-icons/im';

const contactDefaults: CreateUserLead = {
  displayName: '',
  email: '',
  phoneNumber: '',
  subject: '',
  message: '',
  agreeToTerms: false,
};

const contactDataSchema = yup.object({
  displayName: yup.string().required('Field is required'),
  email: yup.string().required('Field is required'),
  phoneNumber: yup.string(),
  subject: yup.string(),
  message: yup.string().required('Field is required'),
  agreeToTerms: yup.string(),
});

type ContactFormProps<T> = { form?: CreateUserLead } & BaseFormProps<T>;

export const ContactForm: React.FC<ContactFormProps<CreateUserLead>> = ({ form, onSubmit }) => {
  const navigate = useNavigate();
  const [warning, setWarning] = useState<boolean>(false);
  const {
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
    reset
  } = useForm<CreateUserLead>({
    defaultValues: form || contactDefaults,
    resolver: yupResolver(contactDataSchema),
    mode: 'onChange',
  });
  const handleFormSubmit = () => {
    const formValues = getValues();
    if (!formValues.agreeToTerms) {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 9000);
    }
    if (formValues.agreeToTerms) {
      onSubmit && onSubmit(formValues);
    }
  };
  return (
    <Flex flexDir={'column'} p={{base: 0, lg: 4}} gap={6} w={'100%'}>
      <AppInput<CreateUserLead>
        name={'displayName'}
        error={errors.displayName}
        control={control}
        label={'Full Name'}
        placeHolder={'Enter your name here'}
      />
      <Flex width={'100%'} gap={4} flexDir={{ base: 'column', lg: 'row' }}>
        <AppInput<CreateUserLead>
          name={'email'}
          control={control}
          error={errors.email}
          label={'Email'}
          placeHolder="Enter your email here"
          w={'100%'}
        />
        <AppInput<CreateUserLead>
          name={'phoneNumber'}
          control={control}
          error={errors.phoneNumber}
          label={'Phone no'}
          placeHolder="Phone number"
          w={'100%'}
        />
      </Flex>
      <AppInput<CreateUserLead>
        name={'subject'}
        control={control}
        error={errors.subject}
        label={'Subject'}
        placeHolder="Subject"
        w={'100%'}
      />
      <AppInput<CreateUserLead>
        name={'message'}
        control={control}
        error={errors.message}
        label={'Message'}
        placeHolder="Message"
        textArea
      />
      <Flex align={'center'} gap={4}>
        <Checkbox
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('agreeToTerms', e.currentTarget.checked);
          }}
        />
        <AppText>
          I agree to the{' '}
          <Link
            color={AppColors.secondary2}
            onClick={() => {
              navigate(RoutesList.Legal);
            }}
          >
            Terms of Services
          </Link>{' '}
          and{' '}
          <Link color={AppColors.secondary2} onClick={() => navigate(RoutesList.PrivacyPolicy)}>
            Privacy Policy
          </Link>
        </AppText>
      </Flex>
      <Box mt={6}>
        <AppButton
          borderRadius={'full'}
          bgColor={AppColors.secondary2}
          color={'white'}
          disabled={!isValid}
          onClick={handleFormSubmit}
        >
          Send Message
        </AppButton>
      </Box>
      {warning && (
        <Flex w={'100%'} p={4} bgColor={'rgba(255, 136, 0, 0.2)'} borderRadius={'md'} align={'center'} gap={4}>
          <Icon as={ImWarning} color={AppColors.ctaColor} />
          <AppText color={AppColors.ctaColor} fontWeight={'500'}>
            Please read through and agree to our terms and conditions and privacy policies before
            you request a demo
          </AppText>
        </Flex>
      )}
    </Flex>
  );
};
