import { Box } from '@chakra-ui/react';
import * as DOMPurify from 'dompurify';
import { AppColors } from '../../theme';

type TextRenderProps = {
  markdown: string;
  className?: string;
  color?: string;
  isArticle?: boolean;
};

export const AppTextRenderer: React.FC<TextRenderProps> = ({
  markdown,
  className,
  color,
  isArticle,
}) => {
  const safeMarkdown = DOMPurify.sanitize(markdown);
  return (
    <Box
      height={'100%'}
      width={'100%'}
      px={isArticle ? '' : 4}
      paddingBottom={isArticle ? '' : '100px'}
      overflow={'visible'}
      margin={0}
    >
      <Box
        color={AppColors.textColor}
        dangerouslySetInnerHTML={{ __html: markdown }}
        style={{
          color: color ?? AppColors.textColor,
          margin: 0,
        }}
        className={className}
        sx={{
          '& h1': {
            fontSize: '2xl',
            fontWeight: 'bold',
          },
          '& h2': {
            fontSize: 'xl',
            fontWeight: 'semibold',
          },
          '& h3': {
            fontSize: 'lg',
            fontWeight: 'medium',
          },
          '& h4': {
            fontSize: 'md',
            fontWeight: 'medium',
          },
          '& h5': {
            fontSize: 'sm',
            fontWeight: 'medium',
          },
          '& h6': {
            fontSize: 'xs',
            fontWeight: 'medium',
          },
        }}
        m={4}
      ></Box>
    </Box>
  );
};
