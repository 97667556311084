import { NavbarLink, NavLink, SocialLink } from '../models/nav-bar';
import { FaLinkedin } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { FaYoutube } from 'react-icons/fa';

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const navItems: NavbarLink[] = [
  {
    title: 'Home',
    href: '/',
  },
  // {
  //   title: 'Pages',
  //   linkedItems: [
  //     {
  //       href: '/',
  //       title: 'Home',
  //     },
  //     {
  //       href: '/pricing',
  //       title: 'Pricing',
  //     },
  //     {
  //       href: '/changelog',
  //       title: 'Change Log',
  //     },
  //     {
  //       href: '/about-us',
  //       title: 'About Us',
  //     },
  //     {
  //       href: '/contact',
  //       title: 'Contact',
  //     },
  //     {
  //       href: '/article/what-are-backlinks-in-seo',
  //       title: 'Article Details',
  //     },
  //     {
  //       href: '/knowledge',
  //       title: 'Knowledge',
  //     },
  //     {
  //       href: '/legal',
  //       title: 'legal',
  //     },
  //   ],
  // },
  {
    title: 'Pricing',
    href: '/pricing',
  },
  {
    href: '/changelog',
    title: 'Changelog',
  },
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
];

export const socials: SocialLink[] = [
  {
    icon: FaLinkedin,
    href: 'https://linkedin.com',
  },
  {
    icon: RiInstagramFill,
    href: 'https://google.com',
  },
  {
    icon: FaYoutube,
    href: 'https://google.com',
  },
];

export const insights: NavLink[] = [
  {
    title: 'Article Hub',
    href: '/',
  },
  {
    title: 'Case Studies',
    href: '/',
  },
];

export const coreTools: NavLink[] = [
  {
    title: 'Google Index Checker',
    href: '/',
  },
  {
    title: 'Domain Age Checker',
    href: '/',
  },
  {
    title: 'Backlink Intelligence',
    href: '/',
  },
];

export const supportLinks: NavLink[] = [
  {
    title: 'Contact us',
    href: '/contact',
  },
  {
    title: 'Live Chat',
    href: '/',
  },
  {
    title: 'Knowledge base',
    href: '/',
  },
];

export const legalLinks: NavLink[] = [
  {
    title: 'Privacy Policy',
    href: '/policies/privacy',
  },
  {
    title: 'Cookie Policy',
    href: '/cookies',
  },
  {
    title: 'Legal Hub',
    href: '/legal',
  },
];
