import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppTextContent } from '../../components/app/app-text-content/app-text-content';
import { usePageMeta } from '../../hooks/usePageMeta';
import { PageTermsConditionsPage } from '../../constants/meta-data';
import { heroBackground, mobileBackground } from '../../constants/heroImages';
import { RoutesList } from '../../router/router';

export const WebTerms: React.FC = () => {
  usePageMeta(PageTermsConditionsPage);
  const variant = useBreakpointValue({
    base: 'mobile',
    xl: 'desktop',
  });
  const title = 'GENERAL WEBSITE TERMS AND CONDITIONS';

  const crumbs = [
    { name: 'Legal', href: RoutesList.Legal },
    { name: 'Website Privacy Policy', href: RoutesList.TermsAndConditions },
  ];

  return (
    <>
      <AppComponentWrapper px={0}>
        <AppBasicHero
          pageName={'Website Privacy Policy'}
          breadCrumbs={crumbs}
          backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
        />
      </AppComponentWrapper>

      <AppComponentWrapper py={12} justify={'center'}>
        <Flex
          bgColor={'rgba(238, 238, 238, 0.3)'}
          borderRadius={'2xl'}
          p={variant === 'mobile' ? 7 : 6}
          flexDir={'column'}
          maxW={'1140px'}
          width={'100%'}
          gap={6}
        >
          <AppText variant={'h3'}>{title}</AppText>
          <AppTextContent
            title="INTRODUCTION"
            isUnordered={false}
            orderIndex={1}
            itemAlignemnt="start"
            listItems={[
              `Welcome to BacklinkIt and thank you for visiting our Website. The Website offers an all-in-one solution for the tracking of links, combined with other comprehensive SEO tools.`,
              `You can access the Website as a guest or as a registered User. Should you decide to register on the Website, you will be required to create a User account for yourself. Once this User account has been created, you will then be able to make use of various Services offered by us. BacklinkIt is not responsible for the accuracy of your User account. It is your responsibility to ensure that:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={1.2}
            itemAlignemnt="start"
            listItems={[
              `your User account is accurate and up-to-date and that you do not misrepresent yourself, either through providing false information on your User account, or through any omission by you; and`,
              `you comply with all applicable laws, including but not limited to privacy laws, intellectual property laws, electronic and digital laws, tax laws and regulatory requirements`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={1.3}
            itemAlignemnt="start"
            listItems={[
              `These General Website Terms and Conditions (“Terms of Use”), together with the Ancillary Terms, set out the terms of our relationship with you. They govern your use of the Website, whether as a guest or a registered User, with effect from the Commencement Date.`,
              `We provide content on the Website (which we regularly review and update, where necessary) and supply the Services to you. All of the content on our Website and the Services that we provide to you are made available to you subject to the disclaimers and limitations of liability set out in these Terms of Use and the Ancillary Terms.`,
              `Please read through these Terms of Use carefully and in full before you use the Website. By using the Website, you undertake that:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={1.5}
            listItems={[
              `you have read and understood these Terms of Use;`,
              `you accept these Terms of Use; and`,
              `you agree to abide by these Terms of Use with effect from the Commencement Date.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={1.6}
            itemAlignemnt="start"
            listItems={[
              `When you register for any of our Services, you are deemed to have accepted and Consented to the Processing of your Personal Information and to the use practices set out in these Terms of Use and the Ancillary Terms. Such acceptance is voluntary, but it is required in order for us to provide the Services to you. If you do not agree with anything in these Terms of Use and/ or the Ancillary Terms, or any of the changes made to them, then you may not use the Website, or register for or use any of the Services.`,
              `We take our legal obligations very seriously, which is why we have made sure that you can easily access these Terms of Use and the Ancillary Terms on our Website, at any time. Some of our terms, policies and procedures are unfortunately quite lengthy, but it is important to us that we cover all of the relevant issues, as they pertain to your rights and obligations, so that you are always clear as to where you stand with us.`,
              `If you have any questions about these Terms of Use, or any of the Ancillary Terms, please do not hesitate to contact our Information and Privacy Officer. The details of our Information and Privacy Officer are set out in clause 25 below.`,
            ]}
          />
          <AppTextContent
            title="DEFINITIONS"
            isUnordered={false}
            startIndex={2.1}
            listItems={[
              `Please see our Definitions Key for a full explanation of all of the important terms that are used inthese Terms of Use.`,
              `Unless the context clearly indicates a contrary intention, words and expressions defined in the Definitions Key shall bear corresponding meanings in these Terms of Use.`,
            ]}
          />
          <AppTextContent
            title="WHO DO THESE TERMS OF USE APPLY TO"
            isUnordered={false}
            startIndex={3.1}
            content="These Terms of Use apply to you if you:"
            listItems={[
              `are a guest on our Website (being a customer, business associate or other interested party);`,
              `register for any Services on our Website;`,
              `contact or communicate with us via our Website;`,
              `provide feedback, send messages, or make available any other content on our Website; or`,
              `opt-in to any marketing communication from us.`,
            ]}
          />
          <AppTextContent
            title="LEGAL CAPACITY"
            isUnordered={false}
            startIndex={4.1}
            listItems={[
              `In line with the provisions of the Children’s Act and POPIA, we define any individual under the age of 18 (Eighteen) years old as a child. `,
              `Our Website is not targeted at children, nor is it intended for use by anyone under the age of 18 (Eighteen) years old. We have no intention of Processing information about children through the Website, and we will use our best endeavours not to knowingly Process information from children through the Website. You may not use our Services if you are younger than 18 (Eighteen) years old or do not have legal capacity to conclude legally binding agreements. By registering to use the Services, you warrant that you are over the age of 18 (Eighteen) years old and have the necessary authority to register for the Services and to agree to these Terms of Use.`,
            ]}
          />
          <AppTextContent
            title="GEOGRAPHIC RESTRICTIONS"
            startIndex={5.1}
            isUnordered={false}
            listItems={[
              `BacklinkIt is incorporated and based in the RSA. Whilst our Services may be accessed from anywhere in the world, access to the Services may be restricted, limited, or prohibited in certain jurisdictions, in terms of legislation. If you access the Services from outside of the RSA, it is your responsibility to ensure that in doing so you comply with all local legal and regulatory requirements, and we will accept no responsibility or liability in this regard.`,
            ]}
          />
          <AppTextContent
            title="ACCESSING OUR WEBSITE"
            startIndex={6.1}
            isUnordered={false}
            listItems={[
              `You will be able to access some of the Website without having to register any details with us. Whilst accessing the Website, you will have the option to register for our Services.`,
              `You are responsible for making any and all arrangements necessary in order to access the Website. This includes acquiring, maintaining, and licensing all computer hardware, software, telecommunications facilities and internet connections required in order for you to access the Website. It is your responsibility to ensure that all hardware, software and facilities procured by you are compatible with the Website.`,
              `You are responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and the Ancillary Terms, and that they agree to comply with them.`,
              `If you choose, or are provided with a User identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have the right to disable any User identification code or password (whether chosen by you or allocated by us), at any time, if, in our opinion, you have failed to comply with any of the provisions of these Terms of Use or the Ancillary Terms.`,
              `We aim to update our Website regularly, and we may change the content at any time. If the need arises, we may suspend access to our Website, or close it indefinitely (such as in the unlikely event of a security breach). `,
              `When accessing the Website, you must at all times comply with the provisions of our acceptable use policy outlined in clause 8 below.`,
            ]}
          />
          <AppTextContent
            title="AVAILABILITY"
            isUnordered={false}
            startIndex={7.1}
            listItems={[
              `Although we will take all reasonable steps to ensure that the Website is available 24 (Twenty Four) hours, every day, the Website may sometimes be unavailable as a result of maintenance, upgrades and/or other technical issues. We will not be:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={7.1}
            listItems={[
              `obliged to notify you of any downtime or unavailability of the Website; or`,
              `liable for any claims, losses, damages or expenses incurred by you if the Website is unavailable at any time.`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            startIndex={7.2}
            listItems={[`We reserve the right, in our sole discretion, to:`]}
          />
          <AppTextContent
            orderIndex={7.2}
            isUnordered={false}
            listItems={[
              `terminate the Website,or to change the domain name or address of the Website,without any notice to you;`,
              `restrict access to the Website (or any part of it) to Users who have registered to use the Services;`,
              `deny or terminate all or part of your access to the Website where, in our opinion, there are concerns regarding unreasonable use, security or unauthorised access, or where you have breached any provisions of these Terms of Use or the Ancillary Terms; or `,
              `block or suspend your account, remove your default settings, or part thereof, without prior notice to you. `,
            ]}
          />
          <AppTextContent
            title="ACCEPTABLE USE OF THE WEBSITE"
            isUnordered={false}
            startIndex={8.1}
            itemAlignemnt="start"
            listItems={[
              `You may only access and use the Website in a lawful manner and in accordance with all applicable legislation.`,
              `You may print and download material from the Website,provided that you do not collect, reproduce, publish, republish, adapt, decompile, reverse engineer, disassemble, rent, lease, loan, sell, resell, sublicense, or create derivative works from the Website or the content on the Website,without our prior, written Consent;`,
              `You may not attempt to override any security feature or bypass or circumvent any access controls or use limits of the Website or the Services`,
              `Save for your Personal Information, any material that you send or post to the Website, or in connection with the Services, shall be considered non-proprietary and not confidential. `,
              `When using the Website, you shall not post or send to or from the Website any material:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={8.5}
            itemAlignemnt="start"
            listItems={[
              `which you are not permitted to post or send, or for which you have not obtained all necessary Consents, permissions, permits and/or licences;`,
              `that is discriminatory, obscene, pornographic or defamatory;`,
              `that is liable to incite racial hatred; `,
              `that is in breach of confidentiality or privacy;`,
              `which may cause harm, annoyance or inconvenience to others;`,
              `which encourages or constitutes conduct that would be deemed a criminal offence or give rise to civil liability;`,
              `that is in contravention of any applicable local, national or international law or regulation;`,
              `that has the purpose of harming or attempting to harm children in any way; or`,
              `which is harmful in nature including, without limitation: damaging code, computer viruses, Trojan horses, worms, logic bombs, corrupted data, malware, spyware or any other form  of code which is malicious or designed to cause harm or nuisance to hardware or software , or to obtain data without Consent.`,
            ]}
          />
          <AppTextContent
            startIndex={8.6}
            isUnordered={false}
            listItems={[
              `You may only use our Website for lawful purposes. You must not use the Website:`,
            ]}
          />
          <AppTextContent
            isUnordered={false}
            orderIndex={8.6}
            itemAlignemnt="start"
            listItems={[
              `to create a false identity, misrepresent your identity or create a profile for any third party without their Consent;`,
              `to use or attempt to use a third party’s account without their Consent;`,
              `to access data not intended for your access or use;`,
              `to log into a server or User account which you are not authorised to access;`,
              `to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (i.e. spam);`,
              `to submit, upload, post, e-mail, collect, disclose or store Personal Information with respect to third parties, other than as contemplated by these Terms of Use, and then only for lawful purposes;`,
              `that is in contravention of any applicable local, national or international law or regulation;`,
              `to delete or revise any content posted by another person or entity without obtaining their Consent to do so;`,
              `to violate the Intellectual Property Rights of BacklinkIt or any third party;`,
              `as a means to scrape the Services or otherwise copy User accounts and other data and information from the Services;`,
              `to monitor the availability, performance or functionality of the Services for any competitive purposes;`,
              `for communications or behaviours aimed at soliciting any of BacklinkIt’s clients;`,
              `to imply or state that you are in any way affiliated to or endorsed by BacklinkIt, without our Consent;`,
              `in any way which may put BacklinkIt in breach of a contractual or other obligation owed by us to any third party (including any ISP); or`,
              `to link to any other websites or systems hosting any material described above.`,
            ]}
          />
          <AppTextContent
            startIndex={8.7}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `We shall not be liable for any material which you post or send to or from the Website and we reserve the right to remove or modify any such material.`,
              `We will:`,
            ]}
          />
          <AppTextContent
            orderIndex={8.8}
            isUnordered={false}
            listItems={[
              `fully co-operate with any law enforcement authorities or any court order requiring us to disclose the identity or other details of any person posting material to the Website in breach of this clause 8; and `,
              `be entitled to claim from you all damages, losses, liabilities and expenses incurred by us and/or our affiliates resulting from or attributable to a breach by you of this clause 8, in addition to any other rights which we may have, including (without limitation) the right to lay criminal charges.`,
            ]}
          />
          <AppTextContent
            startIndex={8.9}
            isUnordered={false}
            listItems={[
              `You also agree not to access (without authority), interfere with, damage or disrupt:`,
            ]}
          />
          <AppTextContent
            orderIndex={8.9}
            isUnordered={false}
            listItems={[
              `any part of our Website;`,
              `any equipment, network or server on which our Website is stored;`,
              `any computer, server or database connected to the Website;`,
              `any software used in relation to the Website or in the supply of the Services; or`,
              `any equipment, network or software owned or used by any third party.`,
            ]}
          />
          <AppTextContent
            startIndex={8.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Your use of our Site means that you accept, and agree to abide by, all of the provisions contained in this clause 8, from the Commencement Date.`,
            ]}
          />
          <AppTextContent
            startIndex={9.1}
            itemAlignemnt="start"
            isUnordered={false}
            title="USE OF OUR SERVICES"
            listItems={[
              `You may visit the Website as a guest or a registered User. Guests will have access to limited functionality and Services. Registered Users will have access to additional functionality and features, and the bulk of the Services offered by us. `,
              `BacklinkIt grants you permission to access and use the Services for your own personal and business purposes. The limitations of use will be subject to the Subscription Plan that you select, to these Terms of Use and the Ancillary Terms.`,
              `Should we detect that you are accessing the Website from multiple locations or IP addresses in violation of your Subscription Plan, we reserve the right, in our sole discretion, to suspend or terminate your User account. `,
              `You may not, directly or indirectly: `,
            ]}
          />
          <AppTextContent
            orderIndex={9.4}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `sublicense, resell, rent, lease, transfer, assign, or otherwise commercially exploit or make the Services; `,
              `use the Services for any purpose, or in any manner, that is unlawful, unauthorised or prohibited by these Terms of Use and/or the Ancillary Terms;`,
              `read or attempt to read or derive the source code of the Services or the software underlying the Services;`,
              `interfere, or attempt to interfere with, or disrupt the integrity, security, functionality or performance of the Services;`,
              `hack or gain unauthorised access to the Services; `,
              `access the Services if you are a competitor of BacklinkIt; or`,
              `use the Services to build a similar offering. `,
            ]}
          />
          <AppTextContent
            startIndex={9.5}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Although we take all reasonable measures to ensure the safety and security of this Website, we do not warrant or represent that this Website is at all times safe and secure.`,
              `When you register to use the Services, you will be required to fill in certain Personal Information and general information. You warrant that:`,
            ]}
          />
          <AppTextContent
            orderIndex={9.6}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `your registration information is accurate, complete, up-to-date and truthful;`,
              `you will maintain the accuracy of all of your Personal Information and general information; and`,
              `your use of the Service does not violate any law or regulation.`,
            ]}
          />
          <AppTextContent
            startIndex={9.7}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `You will at all times be responsible for maintaining the security of your account and passwords. You acknowledge and agree that you should protect your passwords carefully and that you are fully responsible for your own, as well as any third-party use of your account or accounts`,
              `You are solely responsible for all access to and use of the Services by anyone using your password s and identification, whether or not such access is actually authorised by you, including, without\ limitation, all communications, transmissions, payments, financial and other obligations incurred through such access or use. You agree to notify us immediately upon learning of any unauthorised use of your account or password, or any other breach of security. We will not be liable for any loss and/or damage resulting from your failure to comply with this security obligation.`,
              `You agree that we may disable or remove any content that we, in good faith, determine violates these Terms of Use, the Ancillary Terms, any law or any third-party rights. You acknowledge and agree that you bear sole responsibility for adequate security, protection, and backup of any content that you post to the Website or Services. In addition, you agree that we may use the information that you submit to the Services or the Website (without identifying you) for purposes of improving the Services.`,
              `You acknowledge that,from time to time, our authorised employees may be required to log in to the Services under your User account, in order to maintain or improve the Services (including providing you with assistance on technical or billing issues). You hereby Consent to such access.`,
              `We reserve the right to modify the Services ,from time to time,in our sole discretion. If any change to the Services is not acceptable to you, or if any change we make to the Services is a material reduction in functionality, you may, as your sole remedy for such change, stop using the Services and send a cancellation request to us at the e-mail address set out in clause 25 below.`,
              `Please be aware that if you subscribe to any additional services offered by BacklinkIt, such services may be subject to separate terms and conditions. Please ensure that you understand which Services fall within the ambit of these Terms of Use, and which services are subject to separate terms and conditions.`,
            ]}
          />
          <AppTextContent
            startIndex={10.1}
            title="DURATION AND CANCELLATION"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `You shall be deemed to have accepted these Terms of Use on the Commencement Date , upon which time an agreement will come into being between you and BacklinkIt. The agreement will remain in place for as long as you continue to make use of the Services. If there are multiple Users making use of one account, pursuant to your agreement with us, each User agrees to be bound by these Terms of Use.`,
              `Should you wish to delete your User account, you may do so at any time by sending notice,in writing, to the e-mail address set out in clause 25 below. In such a case, we may delete all of your data and information stored on our servers. We will not be held liable for the deletion of such data and information, or any loss that you may suffer as a result of such deletion.`,
              `If you select a Subscription Plan, the duration of such plan will be clearly specified. Your Subscription Plan may be monthly or annual, or another specified time period. `,
              `You may cancel your Subscription Plan at any time by sending notice, in writing, to the e-mail address set out in clause 25 below. You are solely responsible for cancelling your Subscription Plan. Any cancellation by you will only take effect at the end of your existing Subscription Plan period. Please note that cancellation by you does not entitle you to a refund of any prepaid Fees or unused Services. Upon cancellation, all of your data, information and reports may be deleted. This information cannot be recovered once your Subscription Plan is cancelled.`,
              `Upon expiry of your Subscription Plan (“the Initial Period”), it will automatically renew for successive monthly, annual or other specified time periods (“the Renewal Periods”), depending on the Subscription Plan that you have selected. Should you not wish to renew your Subscription Plan for a further period, please send us notice, in writing, of your intention not to renew such Subscription Plan not less than 7 (Seven) days prior to the expiry of the Initial Period or successive Renewal Period (as the case may be). Such notice can be sent to the e-mail address set out in clause 25 below. If notice of cancellation is received less than 7 (Seven) days prior to the expiry of the Initial Period or successive Renewal Period, (as the case may be), such notice will not be effective until the end of the following Renewal Period.`,
            ]}
          />
          <AppTextContent
            startIndex={11}
            title="BILLING AND FEES"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `When you register as a User on the Website and select a Subscription Plan, you will be taken to a secure platform where you will be required to provide your billing details. `,
              `Fees are charged based on the relevant Subscription Plan that you have agreed to with us and are stipulated exclusive of VAT or any other applicable taxes. You agree to pay such Fees either monthly, or annually in advance, depending on the Subscription Plan that you have selected. In certain circumstances, we may, in our sole discretion, agree on a customised, alternative payment plan or Subscription Plan with you.`,
              `Fees will be collected by credit card, or by other available payment methods, as set out when you select your Subscription Plan. Other than in the case of cancellation as a result of a breach by us, all Fees are non-refundable. `,
              `At any given time, we may offer different service levels and Subscription Plans at various monthly or annual rates. Should you subscribe to any additional Services, or upgrade or downgrade your Subscription Plan at any time, the price of your new chosen Subscription Plan will be charged in any subsequent Renewal Periods. We will always notify you of any additional Fees before they become payable by you.`,
              `We may run promotions in relation to the Services from time to time, where we may offer a reduction in Fees for a particular reason or for a particular period. Please be aware that we may, in our sole discretion, refuse repeated registrations for promotional Services.`,
              `You are responsible for the payment of any Fees and applicable taxes associated with the Services in a timely manner, with a valid payment method. Unless otherwise stated, all Fees are quoted in United States Dollars (“USD”). You agree to pay for all of the Fees that are due and owing in terms of any Subscription Plan that you select. You further agree that we may charge your selected payment method for any such Fees that are due and owing to us.`,
              `As set out in clause 10.5 above, unless we receive timeous notice of cancellation from you, your Subscription Plan will be automatically renewed at the end of the Initial Period, or each Renewal Period, as the case may be. In the case of each Renewal Period, your credit card (or other payment method that you may have selected) will be charged each month or each year, without further authorisation from you or notification to you, until you cancel your Subscription Plan.`,
              `It is your responsibility to keep your billing information current, complete, and accurate and to notify us if your selected payment method is cancelled (e.g. for loss or theft), or if any of the details relating to your selected payment method have changed (e.g. a change in billing address, credit card number, or expiration date).`,
              `You are responsible for:`,
            ]}
          />
          <AppTextContent
            orderIndex={11.9}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `all Fees incurred in terms of your Subscription Plan;`,
              `all additional charges incurred in terms of your Subscription Plan, such as bank charges and governmental taxes imposed on your use of the Services, including, but not limited to: sales tax, use tax, or VAT; and`,
              `any charges incurred by accounts associated with, or created through your User account, by you or anyone who makes us of your User account (including your co-workers, colleagues, team-members, etc.).`,
            ]}
          />
          <AppTextContent
            startIndex={11.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `If your payment method fails and any amounts remain due and owing by you to us, we will be entitled to charge you collection fees in respect of such outstanding amounts. In such a case, we shall also be entitled to suspend any Services to you, without further notice to you.`,
            ]}
          />
          <AppTextContent
            startIndex={11.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `If your payment method fails and any amounts remain due and owing by you to us, we will be entitled to charge you collection fees in respect of such outstanding amounts. In such a case, we shall also be entitled to suspend any Services to you, without further notice to you.`,
            ]}
          />
          <AppTextContent
            title="UPLOADING OF CONTENT ONTO THE WEBSITE"
            startIndex={12.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `You are solely responsible for any information, data, text or content (collectively “Content”) that you upload, provide or transmit in connection with the Services. By providing Content through the Services, you grant BacklinkIt a worldwide, non-exclusive, perpetual, fully paid, royalty-free and transferable license to use, modify, reproduce, display, publish, distribute and disclose such Content, in connection with the provision of the Services. We have the right, but not the obligation, to monitor the Content in connection with the provision of the Services.`,
              `Any Content that you upload onto the Website or in connection with the Services will be considered non-confidential and non-proprietary. We have the right to disclose your identity to any third party who is claiming that any material posted or uploaded by you to our Website, or in connection with the Services, constitutes a violation of their intellectual property rights, or of their right to privacy.`,
              `Whenever you make use of a feature that allows you to upload or add Content to our Website, or in connection with the Services, you must comply with the standards set out in our acceptable use policy set out in clause 8 above. You warrant that any upload of Content does comply with the standards set out in clause 8, and you indemnify us for any breach of such warranty.`,
              ` We may, from time to time, host forums, discussions and information sessions on our Website. Please note that any posting of information in forums, discussions and information sessions appearing on our Website is the opinion of the person posting only, and in no way reflects our opinions, attitudes or beliefs. Although we maintain that forums, discussions and information sessions may contain a wealth of information which is of benefit to many Users, you should never rely on any information contained within such forums, discussions and information sessions as being accurate, up-to-date, or complete. If you do, you do so at your own risk. We will not be responsible, or liable to any third party for the Content or the accuracy of any materials posted by you or any other User of the Website.`,
              `We do not undertake or assume any duty to monitor our Website for inappropriate or unlawful Content, and we rely on you to inform us if you spot any abuse or inappropriate or unlawful behaviour. If you feel that you have been threatened, abused, insulted or had your reputation damaged in our forums, or via any of our communication systems, or if you believe any infringement of your rights may have occurred through our Website, please contact us on the e-mail address set out in clause 25 below`,
            ]}
          />
          <AppTextContent
            title="RELIANCE ON INFORMATION"
            startIndex={13.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `This Website and any Services provided by us in terms of this Website are provided on an “as is” basis. Whilst we have taken all reasonable steps to ensure that the material and information on this Website is correct and up-to-date, we cannot and do not guarantee the correctness or completeness of any material or information on this Website.`,
              `Any of the material on our Website may, at any given time, be out of date. We are under no obligation to update such material within any specific time periods. We may also make changes to the material on this Website, at any time, and without any prior notice to you.`,
              `The information, viewpoints and/or opinions set out on this Website and forming part of any Services offered in terms of this Website,have been expressed in good faith and are provided for educational and business purposes only, without taking into account any individual person’s or entity’s particular needs, requirements or financial situation. You utilise the information, viewpoints, content and/or opinions expressed on this Website, and through any Services offered to you in terms of this Website, at your own risk.`,
              `The material on this Website is provided without any warranties of any kind. To the maximum extent permitted by law, we provide access and use of this Website on the basis that it excludes all representations, warranties and conditions which, but for these Terms of Use, may have effect in relation to this Website.`,
              `Neither we, nor any of our affiliates, subsidiaries, directors, officers, employees, agents, contractors or suppliers can be held liable for any damages, losses or causes of action of whatsoever nature arising from the reliance upon any information, viewpoints and/or opinions contained on the Website or the use of the Website by you for any purpose whatsoever`,
              `Nothing on this Website shall be construed as a solicitation, recommendation to engage in any transaction or to offer to provide advice to you. No advisory, fiduciary or other relationship is created between us and you or any third party accessing or otherwise utilising the Website or any Services provided in terms of this Website.`,
            ]}
          />
          <AppTextContent
            title="CONFIDENTIALITY "
            startIndex={14.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Notwithstanding the cancellation or termination of this agreement for any reason whatsoever, neither Party ("Receiving Party") shall any time after the Commencement Date, and without the other Party's ("Disclosing Party ") prior Consent thereto, disclose to any person or use in any manner whatsoever the Disclosing Party's Confidential Information, provided that the Receiving Party may disclose the Disclosing Party 's Confidential Information:`,
            ]}
          />
          <AppTextContent
            orderIndex={14.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `to the extent required by law (other than in terms of a contractual obligation of the Disclosing Party ); `,
              `to its employees, representatives and professional advisers to the extent strictly necessary for the purposes of implementing or enforcing this agreement, or obtaining professional advice or conducting its business, it being specifically agreed that any disclosure or use by any such employee, representative or adviser of such Confidential Information for any other purpose shall constitute a breach of this clause 14 by the Receiving Party; and `,
              `if the provisions of this clause 14 cease to apply to any Confidential Information of a Party, due to the fact that such Confidential Information: `,
            ]}
          />
          <AppTextContent
            subIndex={`14.1.3`}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `is or becomes generally available to the public other than as a result of a breach by the Receiving Party of its obligations in terms of this clause 14; or`,
              `is also received by the Receiving Party from a third party who did not acquire such Confidential Information subject to any duty of confidentiality in favour of the Disclosing Party.`,
            ]}
          />
          <AppTextContent
            startIndex={14.2}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `For the purposes of this clause 14, "Confidential Information" shall mean any information disclosed by either Party to the Receiving Party prior to the Commencement Date, in terms of this agreement or otherwise in connection with this agreement and which is not available to the public or competitors of the Disclosing Party during the ordinary course of business.`,
            ]}
          />
          <AppTextContent
            startIndex={15.1}
            title="PRIVACY AND YOUR PERSONAL INFORMATION"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `In performing our Services in the ordinary course of business, we may Process your Personal Information. If we collect Personal Information from you, you have the right to expect that such Personal Information will be lawfully protected, as far as is reasonably possible. Any use of, or other dealing with your Personal Information is subject to Consent, where this is required by law. This is in line with the general privacy practices of the Organisation. `,
              `When we Process your Personal Information, we will always do so for legitimate business purposes and in line with the provisions of POPIA, the GDPR and other relevant legislation.`,
              `By using the Services, you authorise us and provide your Consent for us to Process your Personal Information, in accordance with our Privacy Policy. Our Privacy Policy supplements these Terms of Use and provides contractual safeguards to our Users for the Processing of the Personal Information sent through BacklinkIt, so you can be confident your data and Personal Information are processed in a lawful manner. A copy of our Privacy Policy is available on our Website. Alternatively, you may contact us on the e-mail address set out in clause 24 below to request that we send you a copy of our Privacy Policy.`,
              `Wherever applicable, you undertake that you will comply with your obligations in terms of POPIA, the GDPR and other relevant legislation, while you are subject to these Terms of Use and the Ancillary Terms`,
              `We may send you communication, from time-to-time, via our Website, e-mail, WhatsApp, SMS and other channels (sometimes through automated means), which may include news, special offers, updates, videos and other information relating to BacklinkIt and its affiliates. This forms part of the Services that we offer to our clients. You may control, limit or opt-out of any communication that we send you at any time.`,
            ]}
          />
          <AppTextContent
            startIndex={16.1}
            title="THIRD PARTIES"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `The Website and Services may contain links to third party websites, products and services. We may also use third party websites to provide payment services, information and communication services, analytics and advertising services. It is important that you understand that if you follow a link to any of these websites, they have their own use and privacy policies.`,
              `We are not responsible or liable for any information, representations, warranties, and/or content on any website of any third party (including websites linked to our Website) as we do not exercise control over third parties’ privacy policies. The onus is on you to refer to the privacy policy of any such third party before providing them with any Personal Information. If you do not want these third parties to collect your Personal Information, please contact them directly`,
              `You agree to indemnify BacklinkIt regarding any loss that you may sustain should you fail to take the steps set out in clause 16.2 above`,
              `If you provide access to your User account or any portion thereof to any third party or use the Services to collect information on behalf of any third party ("Third Party"), whether or not you are authorised to do so, the provisions of this clause 16 shall apply to you.`,
              `If you use the Services on behalf of any Third Party, you represent and warrant that:`,
            ]}
          />
          <AppTextContent
            orderIndex={16.5}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `you are authorised to act for and on behalf of that Third Party;`,
              `that Third Party has read and has agreed to be bound by these Terms of Use and the Ancillary Terms;`,
              `as between the Third Party and you, the Third Party owns any rights to any data in the applicable account; and`,
              `if you are disclosing any data or Personal Information of a Third Party, you have obtained the necessary Consent to disclose such data or Personal Information.`,
            ]}
          />
          <AppTextContent
            startIndex={16.6}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `BacklinkIt makes no representations or warranties for the direct or indirect benefit of any Third Party. With respect to Third Parties, you shall take all measures necessary to disclaim any and all representations or warranties that may pertain to BacklinkIt, the Website and the Services, or the use thereof. `,
            ]}
          />
          <AppTextContent
            startIndex={17.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Please be aware that whilst we will endeavour to provide the highest quality of service to you at all times, there are no warranties, claims or representations made by BacklinkIt, either express, implied, or statutory, with respect to the Website and/or the Services, including warranties of quality, performance, non-infringement, merchantability, or fitness for a particular purpose.`,
              `BacklinkIt does not warrant that the Website and/or the Services will meet your needs or requirements, be free from errors, be accurate and complete, be secure, be available in all locations, that all defects will be corrected, or that any software used to provide the Services or to run the Website is free of viruses or other harmful components.`,
              `You specifically agree that BacklinkIt shall not be responsible for unauthorised access to or alteration to your data.`,
              `We do not guarantee the Services will be operable at all times or during any down time :`,
            ]}
          />
          <AppTextContent
            orderIndex={17.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `caused by outages to any public Internet backbones, networks or servers;`,
              `caused by any failures of your equipment, systems or local access services;`,
              `for previously scheduled maintenance; or`,
              `relating to any force majeure events, as set out in clause 22 below.`,
            ]}
          />
          <AppTextContent
            startIndex={17.5}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `You agree to indemnify, hold harmless and defend BacklinkIt, to the fullest extent possible, at your expense, against any and all third-party claims, applications, actions and proceedings brought against BacklinkIt or any of its officers, directors, employees, agents or affiliates, and all related liabilities, damages, settlements, penalties, fines, costs or expenses (including, without limitation, reasonable attorneys' fees and other litigation expenses) incurred by BacklinkIt or any of its officers, directors, employees, agents or affiliates, arising out of or relating to:`,
            ]}
          />
          <AppTextContent
            orderIndex={17.5}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `your breach of any provision of these Terms of Use;`,
              `your use of the Services and/or the Website;`,
              `your violations of any applicable laws, rules or regulations in connection with the Services and/or the Website.`,
            ]}
          />
          <AppTextContent
            startIndex={17.6}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `In such a case, BacklinkIt will provide you with written notice of such claim, application or action. You shall cooperate as fully as reasonably required in the defence of any claim. We reserve the right, at our own expense, to assume the exclusive defence and control of any matter subject to indemnification by you.`,
              `Insofar as the Third Parties referred to in clause 16 are concerned, you agree to indemnify, hold harmless and defend us, at your expense, against any and all Third-Party claims, applications, actions and proceedings brought against BacklinkIt or any of its officers, directors, employees, agents or affiliates, and all related liabilities, damages, settlements, penalties, fines, costs or expenses (including, without limitation, reasonable attorneys' fees and other litigation expenses) incurred by BacklinkIt, or any of its officers, directors, employees, agents or affiliates, arising out of or relating to:`,
            ]}
          />
          <AppTextContent
            orderIndex={17.7}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `any representations and warranties made by you to a Third Party concerning any aspect of the Services or the Website;`,
              `any claims made by or on behalf of any Third Party pertaining directly or indirectly to your use of the Services or the Website;`,
              `any violations of your obligations of privacy to any Third Party; and`,
              `any claims with respect to acts or omissions of Third Parties in connection with the Services and/or the Website.`,
            ]}
          />
          <AppTextContent
            startIndex={17.8}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `The exclusions and disclaimers set out in this clause 17 are an essential part of these Term of Use. The defence and indemnification obligation set out in this clause 17 will survive the termination of this agreement and your use of the Services and the Website.`,
            ]}
          />
          <AppTextContent
            startIndex={18.1}
            title="OUR LIABILITY TO YOU"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `BacklinkIt shall not be liable for any loss (including but not limited to loss of data, profits and goodwill), liability, damages (whether direct, indirect or consequential) or expense of whatsoever nature and howsoever arising, where such loss is due to the occurrence of any of the following events:`,
            ]}
          />
          <AppTextContent
            orderIndex={18.1}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `your failure to comply with the provisions of these Terms of Use and/or the Ancillary Terms; or`,
              `circumstances that constitute an event of force majeure, as contemplated in clause 22 below; or`,
              `all telecommunications infrastructure and communication line faults; or`,
              `your failure or unreasonable delay in reporting any report faults/problems to us; or`,
              `your failure to adequately confirm the accuracy and integrity of any data or information processed for and on behalf of you by BacklinkIt; or`,
              `your failure to protect your Proprietary Information; or`,
              `the failure of any hardware, software programme, applications/s or any other computer systems (or any component thereof) or product or service of any third party on whom you rely (whether directly or indirectly) to access the Website and/or use the Services and/or on which BacklinkIt relies to grant access to the Website and/or provide the Services; or`,
              `a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material, due to your use of the Website and/or Services or to your downloading of any material posted on the Website or on any website linked to it.`,
            ]}
          />
          <AppTextContent
            startIndex={18.2}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Notwithstanding what is contained in clause 18.1 above, BacklinkIt’s maximum total liability for loss, liability or damage in terms of or arising out of this agreement, howsoever arising or caused, shall be limited to direct damages proven, which amount shall not exceed the equivalent of 6 (Six) months’ subscription to the Services, in accordance with your Subscription Plan.`,
              `Subject to the provisions of any applicable legislation, neither BacklinkIt, nor our affiliates and their respective directors, shareholders, agents, consultants or employees or any other party (whether or not involved in producing, maintaining or delivering this Website or providing the Services), shall be liable or responsible to you or any third party for any loss, damage or expense of whatsoever nature that you or any third party may suffer as a result of accessing and/or using this Website and or using the Services, regardless of whether BacklinkIt has been advised as to the possibility of such damages and/or losses occurring. This exclusion shall include, without limitation, any direct, incidental, consequential, or any other indirect loss or damage (including, but not limited to: loss of profits or revenue or loss of data) or any exemplary or punitive damages. These limitations of liability shall apply regardless of the form of action, whether in contract, delict or otherwise.`,
            ]}
          />
          <AppTextContent
            startIndex={19.1}
            title="BREACH AND TERMINATION "
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Should either Party ("the Defaulting Party") commit a breach of any of the provisions of this agreement, then the other Party ("the Aggrieved Party") shall be obliged to give the Defaulting Party 30 (Thirty) days' written notice, or such longer period as may be reasonable in the circumstances, to remedy the breach. If the Defaulting Party fails to comply with such notice, the Aggrieved Party shall be entitled, without prejudice to its other rights in law, to cancel this agreement or to claim immediate specific performance of all of the Defaulting Party's obligations whether or not due for performance, in either event without prejudice to the Aggrieved Party's right to claim damages. `,
              `If you terminate this agreement because of a breach caused by Backlinkit, and such breach is not disputed by us, we will promptly refund all applicable Fees through to the end of the subscription term. If we terminate this agreement for a breach caused by you, no refund will be due to you.`,
              `BacklinkIt, in its sole discretion, has the right to suspend or terminate your User account and refuse any and all current or future use of the Services, if we believe that you have acted, or are acting, in a manner that may materially affect our business or customers. We also reserve the right to suspend or terminate Services that are unpaid for any reason whatsoever and without notice to you.`,
              `Upon termination or cancellation of this agreement by us for any reason whatsoever:`,
            ]}
          />
          <AppTextContent
            orderIndex={19.4}
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `all rights in respect of this agreement will immediately terminate, save for those rights set out in clause 17;`,
              `we may erase all of your data from our servers within a reasonable time but may retain such copies of data to the extent required by law or created by automatic computer backup and archived; and `,
              `any amounts due by you to us shall immediately become payable to us, and we shall debit your selected payment method accordingly.`,
            ]}
          />
          <AppTextContent
            startIndex={20.1}
            title="INTELLECTUAL PROPERTY RIGHTS"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `All Intellectual Property Rights vested in any material forming part of the Website and/or the Services or in any software and/or any other documentation and works (whether stored in printed or electronic form) furnished by us to you, and/or developed by or created by BacklinkIt or any of its employees, agents, or sub-contractors in the course of or for the purpose of granting access to the Website and/or providing the Services to you (whether or not created by BacklinkIt in connection with this agreement), shall be the property of and vest solely and absolutely in BacklinkIt on the date upon which same is created. All software, documentation and works created by us are protected by intellectual property laws and treaties around the world. All such rights are reserved.`,
              `You shall only be entitled to utilise the Intellectual Property Rights referred to in clause 20.1 above, or any portion thereof, for the purposes of the accessing and utilising the Website and/or the Services in terms of this agreement, and shall not publish, circulate or copy same for use by any third party whomsoever`,
              `To the extent necessary, you hereby cede, assign, transfer and make over to BacklinkIt any Intellectual Property Rights in respect of any of the software, documents and/or works referred to in clause 20.1 above.`,
              `You warrant that you shall not use the Website and/or the Services to produce, host or present any content in contravention of any person’s Intellectual Property Rights and, in particular, warrant that you shall recognise, acknowledge and use any content in accordance with any third party’s Intellectual Property Rights. You furthermore warrant that you have received all necessary permissions to make use of any Intellectual Property Rights relating to third parties.`,
              `You hereby indemnify and hold BacklinkIt and any of its affiliates, holding company, fellow subsidiaries, representatives, directors, officers or employees harmless against all losses, damages, liablity, costs and expenses, including reasonable attorney fees, suffered or incurred by them as a result of any claim by any third party arising out of the provisions of clause 20.4.`,
              `If you contribute any original, written content to the Website, then you are deemed to own any copyright in that content. However, when you post content, you expressly grant us a perpetual, unlimited, royalty free license to republish that text on our Website and to redistribute/make available and/or sell that text in print or electronic form anywhere in the world, as part of an edited compilation or otherwise.`,
              `If you print off, copy, distribute or download any part of our Website in breach of these Terms of Use, your right to use our Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.`,
            ]}
          />
          <AppTextContent
            startIndex={21.1}
            title="TRADEMARKS"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `You shall not acquire any right, title or interest entitling you to use the name, service marks, trade marks or logos of BacklinkIt.`,
              `In this regard, you undertake in favour of BacklinkIt, not to perform any act which would injure the reputation or goodwill attaching to our name and trade marks, or which would prejudice our rights in and to such names and trade marks.`,
            ]}
          />
          <AppTextContent
            startIndex={22.1}
            title="TRADEMARKS"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Neither Party shall be liable to the other Party for any inability to perform or for delayed performance in terms of this agreement, should such inability or delay arise from any cause beyond the reasonable control of such Party (hereinafter referred to as a “force majeure event”), provided that the existence of such force majeure event has been drawn to the attention of the other Party within a reasonable period of time of the occurrence thereof.`,
              `If the force majeure event continues for more than 60 (Sixty) days from the date of notification thereof and such event prevents the affected Party from performing its obligations in terms of this Agreement, the unaffected Party shall be entitled to terminate this Agreement by giving the other Party not less than 30 (Thirty) days’ written notice.`,
              `For the purposes of this clause 22, "force majeure event" shall mean any event or circumstance whatsoever which is not within the reasonable control of the affected Party including vis major, casus fortuitus, earthquake, natural disaster, strike, theft, fire, explosion, riot, insurrection or other civil disorder, war (whether declared or not) or military operations, international restrictions, any requirement of any international authority, any requirement of any government or other compet ent local authority, any court order, export control and shortage of transport facilities or any interruptions in Internet services to an area where BacklinkIt or your servers are located or colocated.`,
            ]}
          />
          <AppTextContent
            startIndex={23.1}
            title="ASSIGNMENT"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `We may transfer (assign) our rights and obligations under these Terms of Use to any third party without notice to you (this may happen, for example, if we become involved in a proposed or actual merger, acquisition or any other sale of business or sale of assets). If this occurs, you will be informed by us in writing of such assignment. Your rights and obligations under these Terms of Use will not be affected and our obligations under these Terms of Use will be transferred to the third party, who will remain bound by them.`,
              `You may not transfer (assign) your obligations and rights under these Terms of Use to anyone else without prior, written Consent from us. Any assignment or transfer in violation of the foregoing shall be deemed void and of no effect.`,
            ]}
          />
          <AppTextContent
            startIndex={24.1}
            title="ASSIGNMENT"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `BacklinkIt, in its sole discretion, reserves the right to amend, modify or update these Terms of Use from time to time, with or without notice to you, and you waive the right to receive specific notice of each such change or modification. Whenever reasonably possible, we will notify you of any changes by placing a notice in a prominent place on the Website or by sending you an e -mail detailing the changes that we have made and indicating the date that these Terms of Use were last updated. `,
              `The most updated version of these Terms of Use will always be available on the Website.`,
              `You agree and undertake to review these Terms of Use whenever you visit the Website.`,
              `Save as expressly provided to the contrary in these Terms of Use, any amended version of these Terms of Use will supersede and replace previous versions thereof. If you continue to use the Website, or our Services, following a change to these Terms of Use, the changed Terms of Use will apply to you and you will be deemed to have accepted such updated Terms of Use.`,
            ]}
          />
          <AppTextContent
            startIndex={25.1}
            title="UPDATES, FEEDBACK AND CONTACT INFORMATION"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `These Terms of Use were last updated on 1 March 2024.`,
              `If you have any questions, concerns or comments regarding these Terms of Use, the Ancillary Terms, the Website or the Services, please send them to info@backlinkit.com.`,
              `If you have any questions, concerns, or comments regarding the Processing of your Personal Information, please send them to our Information and Privacy Officer, the details of which are as follows:`,
            ]}
          />
          <AppTextContent
            ml={6}
            title="UPDATES, FEEDBACK AND CONTACT INFORMATION"
            itemAlignemnt="start"
            listItems={[
              `Attention: The Information and Privacy Officer.`,
              `E-mail: info@backlinkit.com.`,
              `Address: 2nd Floor, New Link Centre, 1 New Street, Esterville, Paarl, Western Cape, 7646.`,
            ]}
          />
          <AppTextContent
            startIndex={26.1}
            title="NOTICES AND DOMICILIUM"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `Notices to you shall be given to the e-mail address on file associated with your User account. You agree that this e-mail address shall be your domicilium citandi et executandi ("Domicilium") for all purposes relating to these Terms of Use and your agreement with us, including the giving of any notice, the payment of any sum and the serving of any process.`,
              `To change the email address on file in relation to your account, please e-mail us at the address specified in clause 25 above. All notices will be deemed to have been received by you on the day that they are sent by us. `,
            ]}
          />
          <AppTextContent
            startIndex={27.1}
            title="GOVERNING LAW"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `These Terms of Use and any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with these Terms of Use are governed by and construed in accordance with South African law. The parties irrevocably agree that the South African courts have exclusive jurisdiction to settle any dispute or claim (including any non-contractual dispute or claim) that arises out of or in connection with these Terms of Use.`,
            ]}
          />
          <AppTextContent
            startIndex={28.1}
            title="GENERAL"
            itemAlignemnt="start"
            isUnordered={false}
            listItems={[
              `These Terms of Use do not create or imply any agency, partnership or franchise relationship. Nothing in these Terms of Use, express or implied, is intended to or shall confer on any third party any right, benefit, or remedy of any nature whatsoever.`,
              `If any provision of these Terms of Use is found by any court or other authority of competent jurisdiction to be invalid, unenforceable, or illegal, this shall not impair the operation of these Terms of Use or affect the other provisions which are valid.`,
              `These Terms of Use constitute the entire agreement between you and BacklinkIt concerning your use of the Website and the Services. These Terms of Use supersede all other proposals and agreements, whether in oral, written, or electronic form. In the event of any conflict between the se Terms of Use and any other document, these Terms of Use shall prevail.`,
              `BacklinkIt shall not be regarded as having waived, or be precluded in any way from exercising, any right under or arising from this agreement by reason of BacklinkIt having, at any time, granted an extension of time for, or having shown any indulgence to you with reference to any payment or performance hereunder, or having failed to enforce, or delayed in the enforcement of, any right of action against you.`,
              `You hereby warrant that you have the capacity to and are authorised to conclude this agreement with BacklinkIt, and that upon acceptance, these Terms of Use will, and will continue to, bind you in all respects.`,
            ]}
          />
        </Flex>
      </AppComponentWrapper>
    </>
  );
};
