import { SelectOption } from "@backlinkit/shared";

export const languageOptions: SelectOption[] = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
    { value: "Mandarin Chinese", label: "Mandarin Chinese" },
    { value: "Hindi", label: "Hindi" },
    { value: "Arabic", label: "Arabic" },
    { value: "French", label: "French" },
    { value: "Russian", label: "Russian" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "Bengali", label: "Bengali" },
    { value: "German", label: "German" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Italian", label: "Italian" },
    { value: "Turkish", label: "Turkish" },
    { value: "Vietnamese", label: "Vietnamese" },
    { value: "Urdu", label: "Urdu" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Swahili", label: "Swahili" },
    { value: "Dutch", label: "Dutch" },
    { value: "Thai", label: "Thai" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Polish", label: "Polish" },
    { value: "Persian", label: "Persian" },
    { value: "Hebrew", label: "Hebrew" },
    { value: "Malay", label: "Malay" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Czech", label: "Czech" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Greek", label: "Greek" },
    { value: "Finnish", label: "Finnish" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Swedish", label: "Swedish" },
    { value: "Danish", label: "Danish" },
    { value: "Romanian", label: "Romanian" },
    { value: "Ukrainian", label: "Ukrainian" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Afrikaans", label: "Afrikaans" },
    { value: "Tagalog", label: "Tagalog" },
    { value: "Sinhala", label: "Sinhala" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Marathi", label: "Marathi" },
    { value: "Kannada", label: "Kannada" }
  ];
  