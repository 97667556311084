export interface AppDefintion {
  term: string;
  definition: string;
}

export const webDefitions: AppDefintion[] = [
  {
    term: 'Ancillary Terms',
    definition: `all additional terms, conditions, policies and procedures that are applicable to the use of the Website and the Services, including, but not limited to: this Definitions Key; the Privacy Policy, the Cookie Policy, the Disclaimer and the PAIA Manual;`,
  },
  {
    term: `“BacklinkIt”, “the Organisation”, “us”, “our” and “we”`,
    definition: `BacklinkIt (Pty) Limited, a South African company with registration number: 2023/694992, having its registered address at 2nd Floor, New Link Centre, 1 New Street, Esterville, Paarl, Western Cape, 7646 ;`,
  },
  {
    term: `Business Day`,
    definition: `any day other than a Saturday, Sunday or official public holiday in the RSA;`,
  },
  {
    term: `“Children’s Act” `,
    definition: `the Children’s Act 28 of 2005, together with any regulations promulgated in relation thereto, as amended from time to time;`,
  },
  {
    term: `"Commencement Date"`,
    definition: `the first time that you access the Website, for any reason whatsoever, as a guest or as a registered User;`,
  },
  {
    term: `“Consent” `,
    definition: `voluntary, specific and informed consent that is provided by you, of your own free will, and  without any undue influence from any person whatsoever; `,
  },
  {
    term: `“Contracted Third Parties”`,
    definition: `third parties with whom we contract in order to be able to optimally perform our obligations and render our Services to you, including, but not limited to: service providers, affiliates, consultants, credit bureaus (to report account information, as permitted by law), and other companies or individuals that assist with parts of our business operations (including fraud prevention, bill collection, marketing and technology services); `,
  },
  {
    term: `“CPA”`,
    definition: `the Consumer Protection Act 68 of 2008, together with any regulations promulgated in relation thereto, as amended from time to time;`,
  },
  {
    term: `“ECTA”`,
    definition: `the Electronic Communications and Transactions Act 25 of 2002, together with any regulations promulgated in relation thereto, as amended from time to time;`,
  },
  {
    term: `“Fees”`,
    definition: `the monthly, annual or other agreed fees, payable by you to BacklinkIt, in respect of the Services, and in accordance with your agreed Subscription Plan;`,
  },
  {
    term: `“GDPR” `,
    definition: `the General Data Protection Regulation (EU) 2016/679, as amended from time to time;`,
  },
  {
    term: `“IP”`,
    definition: `Internet Protocol, meaning the set of rules governing the format of data sent via the internet or a local network;`,
  },
  {
    term: `“Intellectual Property Rights”`,
    definition: `intellectual property of all kinds and descriptions, together with all rights subsisting therein, whether statutory or at common law, including, all Proprietary Information, all copyrighted works, trade marks (whether registered or not), designs (whether registered or not), inventions (whether patented or not), software programs, procedures, methodologies, data  and flow charts and all statutory registrations and applications therefor as at the Commencement Date, together with all improvements, developments and customisations of the aforegoing;`,
  },
  {
    term: `“ISP”`,
    definition: `Internet Service Provider;`,
  },
  {
    term: `“Internet Usage Information”`,
    definition: `includes, but is not limited to: your IP address, browsing habits, websites visited, click patterns, version of software installed, system type, screen resolutions, colour capabilities, plug-ins, language settings, cookie preferences, search engine keywords, JavaScript enablement, the content and pages that you access on the Website, and the dates and times that you visit the Website, paths taken, and time spent on sites and pages within the Website;`,
  },
  {
    term: `“Optional Information”`,
    definition: `Personal Information that is provided by you to us on a voluntary basis, in addition to the Personal Information that is collected from your browser or collected by us when you Register to use our Services;`,
  },
  {
    term: `“PAIA”`,
    definition: `the Promotion of Access to Information Act 2 of 2002, together with any regulations promulgated in relation thereto, as amended from time to time;`,
  },
  {
    term: `“PAIA Manual”`,
    definition: `a document compiled in terms of section 51 of PAIA, as read with section 17 of POPIA, which explains how people can get access to records held by a body;`,
  },
  {
    term: `“Parties”`,
    definition: `BacklinkIt and the User collectively and “Party” shall mean either one of them, as the context may indicate;`,
  },
  {
    term: `“Personal Information”`,
    definition: `all information or data which may be personal in nature, or information about or relating to an identifiable, natural person (and, where applicable, an identifiable, existing juristic  person) in terms of the CPA, the ECTA, the GDPR, PAIA and POPIA;`,
  },
  {
    term: `“POPIA”`,
    definition: `the Protection of Personal Information Act 4 of 2013, together with any regulations promulgated in relation thereto, as amended from time to time;`,
  },
  {
    term: `“Process/Processing”`,
    definition: `the manual or automated activity of collecting, receiving, using, recording, organising, combining, collating, storing, updating, modifying, retrieving, altering, distributing (or otherwise making available in any form), disseminating, merging, linking, restricting, degrading, deleting, or removing Personal Information and/or general information; `,
  },
  {
    term: `“Proprietary Information”`,
    definition: `any and all know how, trade secrets and data/information of a proprietary and/or confidential nature, including data/information of a Party that the other Party should reasonably have known to be proprietary or confidential in nature;`,
  },
  {
    term: `“RSA”`,
    definition: `the Republic of South Africa;`,
  },
  {
    term: `“SEO”`,
    definition: `the Republic of South Africa;`,
  },
  {
    term: `“Services”`,
    definition: `the services provided by BacklinkIt to you in accordance with the Terms of Use and all Ancillary Terms, consisting of, inter alia, a suite of online management tools for: • research, analysis and the automated tracking of links; • the creation of SEO and other comprehensive tracking reports; • ongoing feedback on link activity and link analytics; and • other SEO-related services, inclusive of all ancillary and related services modifications, enhancements, updates and additions thereto, together with any support  services required by you in relation to the Services;`,
  },
  {
    term: `“Subscription Plan”`,
    definition: `the monthly, annual or other subscription plan that you select and register for, which allows you to make use of the Services;`,
  },
  {
    term: `“URL”`,
    definition: `Uniform Resource Locator;`,
  },
  {
    term: `“User”, you”, “your” and “yourself”`,
    definition: `any person who makes use of the Website and/or the Services offered by BacklinkIt, for any purpose whatsoever, whether or not such use is free of charge or paid for;`,
  },
  {
    term: `“VAT”`,
    definition: `Value Added Tax as levied in terms of the Value Added Tax Act 89 of 1991, together with any regulations promulgated in relation thereto, as amended from time to time; and`,
  },
  {
    term: `“Website” `,
    definition: `the website owned and operated by BacklinkIt, which can be accessed at https://backlinkit.com and https://portal.backlinkit.com.`,
  },
];
