import { AppText } from '@backlinkit/shared';
import { Divider, Flex, Icon, Link, Tooltip } from '@chakra-ui/react';
import { HiChevronDoubleRight } from 'react-icons/hi';
import { FaFacebookF } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaRegEnvelope } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import React from 'react';

export interface SocialShare {
  name: string;
  svgUrl: IconType;
  baseUrl: string;
}

const socialSharingLinks: SocialShare[] = [
  {
    name: 'Facebook',
    svgUrl: FaFacebookF,
    baseUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    name: 'LinkedIn',
    svgUrl: FaLinkedinIn,
    baseUrl: 'https://www.linkedin.com/cws/share?url=',
  },
  {
    name: 'Email',
    svgUrl: FaRegEnvelope,
    baseUrl: 'mailto:?subject=Check out this Backlinkit Article!&body=',
  },
];

interface ShareBannerComponentProps {
  articleLink: string;
}

const ShareBannerComponent: React.FC<ShareBannerComponentProps> = ({ articleLink }) => {
  const handleCopyToClipboardClick = () => {
    navigator.clipboard.writeText(`https://backlinkit.com/articles/${articleLink}`);
  };
  return (
    <Flex flexDirection={'column'} gap={'16px'}>
      <Flex flexDirection={'row'} gap={'21px'} padding={'8px'}>
        <Flex flexDirection={'row'} gap="12px" alignItems={'center'}>
          <AppText color={'#3D424D'} fontWeight={'500'} fontSize={'18px'}>
            Share Now
          </AppText>
          <Icon as={HiChevronDoubleRight} boxSize={'24px'} />
        </Flex>
        <Flex alignItems={'center'} gap={'10px'}>
          {socialSharingLinks.map((link: SocialShare, idx: number) => (
            <Tooltip label={link.name} key={link.name}>
              <Link
                href={`${link.baseUrl}https://backlinkit.com/articles/${articleLink}`}
                target={'_blank'}
                display={'flex'}
                alignItems={'center'}
                padding="8px"
                border={'0.5px solid #D2D3D6'}
                borderRadius={'50%'}
                onClick={handleCopyToClipboardClick}
              >
                <Icon
                  as={link.svgUrl}
                  _hover={{ opacity: 0.5 }}
                  color={'#037DFC'}
                  boxSize={'11px'}
                />
              </Link>
            </Tooltip>
          ))}
        </Flex>
      </Flex>

      <Divider borderColor={'#D2D3D6'} />
    </Flex>
  );
};

export default ShareBannerComponent;
