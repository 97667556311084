import React from 'react';
import { Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';
import { AppText } from '@backlinkit/shared';
import { featuredIns } from '../../constants/featured';

export const AsFeaturedInComponent: React.FC<FlexProps> = ({ ...props }) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  return (
    <Flex
      maxW={'1140px'}
      pt={{ base: '25px', lg: '50px' }}
      align={'center'}
      justify={'center'}
      flexDir={'column'}
      width={'100%'}
      {...props}
    >
      <AppText fontSize={'24px'} fontWeight={'600'} color={'white'}>
        As featured in
      </AppText>
      <Flex
        align={'center'}
        justify={'space-between'}
        width={'100%'}
        gap={variant === 'mobile' ? 8 : 4}
        overflowX={'auto'}
      >
        {featuredIns.map((item, idx) => (
          <Image
            src={item}
            key={`${item}-${idx}`}
            maxH={{ base: '75px', lg: '150px' }}
            width={'auto'}
          />
        ))}
      </Flex>
    </Flex>
  );
};
