import * as React from 'react';
import { Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';
import { AppText, AppButton } from '@backlinkit/shared';

export type BannerCta = {
  label: string;
  bgColor: string;
  color?: string;
  borderColor?: string;
  onClick: () => void;
};

export type ImageSizeOptions = 'small' | 'medium' | 'large';

export interface AppBannerProps extends FlexProps {
  tag?: string;
  title: string;
  description?: string;
  descriptionItems?: string[];
  textGap?: string;
  color?: string;
  image: string;
  imageSize?: ImageSizeOptions;
  btns?: BannerCta[];
  reviewSummary?: React.ReactNode[];
  ctaImage?: string;
}

export const AppBanner: React.FC<AppBannerProps> = ({
  tag,
  title,
  description,
  descriptionItems,
  textGap,
  color = 'white',
  image,
  imageSize = 'medium',
  btns,
  reviewSummary,
  ctaImage,
  ...props
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });
  const getImageStyles = () => {
    if (imageSize === 'large') {
      return {
        height: 'auto',
        width: '100%',
      };
    }
    if (imageSize === 'medium') {
      return {
        height: variant !== 'desktop' ? '100%' : 'auto',
        width: variant !== 'desktop' ? 'auto' : '100%',
      };
    }
    if (imageSize === 'small') {
      return {
        maxW: '480px',
        width: { base: '100%', lg: '480px' },
        height: 'auto',
      };
    }
  };

  return (
    <Flex
      width={'100%'}
      maxW={'1140px'}
      minH={{ base: '600px', md: '100%' }}
      align={'center'}
      justify={'center'}
      {...props}
      gap={8}
      px={variant !== 'desktop' ? 7 : 0}
      py={{ base: '40px', lg: '80px' }}
      flexDir={{ base: 'column', md: 'row' }}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      pos={'relative'}
    >
      <Flex flexDir={'column'} gap={textGap ?? 4} pos={'relative'} height={'100%'}>
        {tag && (
          <AppText fontSize={'18px'} color={color} fontWeight={'700'}>
            {tag}
          </AppText>
        )}
        <AppText as="h2" fontSize={{ base: '30px', lg: '48px' }} color={color} fontWeight={'700'}>
          {title}
        </AppText>
        {description && <AppText color={color}>{description}</AppText>}
        {descriptionItems && (
          <>
            {descriptionItems.map((item, idx) => (
              <AppText key={idx} color={color}>
                {item}
              </AppText>
            ))}
          </>
        )}
        {!ctaImage && (
          <Flex align={'center'} gap={4} flexDir={variant !== 'moible' ? 'row' : 'column'} mt={4}>
            {btns?.map((btn, idx) => (
              <AppButton
                onClick={btn.onClick}
                bgColor={`${btn.bgColor} !important`}
                key={`${btn.label}-${idx}`}
                color={btn.color ?? 'black'}
                rounded={'full'}
                borderColor={`${btn.borderColor ?? 'white'} !important`}
                height={'55px'}
              >
                <AppText>{btn.label}</AppText>
              </AppButton>
            ))}
          </Flex>
        )}
      </Flex>
      {ctaImage && (
        <Flex pos={'absolute'} bottom={{ base: 10, lg: 0, xl: 0 }} left={0} right={0}>
          <Image
            maxH={'230px'}
            width={{ base: '100%', md: 'auto' }}
            src={ctaImage}
            objectFit={'cover'}
          />
        </Flex>
      )}
      <Flex maxW={variant !== 'desktop' ? '100%' : '55%'} justify={'center'}>
        <Image src={image} {...getImageStyles()} marginBottom={ctaImage ? '40px' : 0} />
      </Flex>
    </Flex>
  );
};
