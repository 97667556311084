import * as React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { NavBar } from './header/header';
import { navItems } from '../constants/nav-items';
import SharedFooter from '@backlinkit/shared/lib/components/footer/shared-footer';
import { baseUrl } from '../constants/nav-items';
import Footer from './footer/footer';
export interface BaseLayoutProps extends FlexProps {
  trialFooter?: boolean;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children, trialFooter, ...props }) => {
  const usersImage =
    'https://backlinkitprod.blob.core.windows.net/assets/18aee4a6-cf4b-4776-819e-e385ab83e3ff_Frame%2020433.png-1736514158615';
  const footerImage =
    'https://backlinkitprod.blob.core.windows.net/assets/c2d32153-8fb0-4c05-bb20-06f4a4d11681_backlinkit-color-white-logo.webp-1736509443543';
  const headerImage =
    'https://backlinkitprod.blob.core.windows.net/assets/897aaefd-bea5-4393-b53d-db2ab3a8267c_backlinkit-white-logo.webp-1736509443818';
  return (
    <Flex flexDir={'column'} minH={'100vh'} overflow={'auto'} {...props}>
      <NavBar
        logo={headerImage}
        links={navItems}
        login={() => {
          window.open(baseUrl, '_self');
        }}
        signUp={() => {
          window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_self');
        }}
      />
      <Flex w={'100%'} justify={'center'} flexDir={'column'} align={'center'} pos={'relative'}>
        {children}
      </Flex>
      <Footer
        hasFreeTrialBanner={trialFooter}
        onBtnClick={() => {
          window.open(`${baseUrl}/registration/ae983839-a7cd-4653-b69e-698d1b346b46`, '_blank');
        }}
        footerLogo={footerImage}
        usersImage={usersImage}
      />
    </Flex>
  );
};
