import { AppReview } from '../components/app/app-reviews-banner/app-reviews-banner';

export const reviews: AppReview[] = [
  {
    content:
      'The UI looks amazing. I found backlinkit so helpful just because this tool informs you of lost link without manually checking.',
    rating: 5,
    source: 'LinkedIn',
    author: {
      name: 'Imran Zahid — boringmagazine.com',
      image: 'https://backlinkitprod.blob.core.windows.net/assets/64af27e5-d887-4a99-a53f-645a10c9be71_author-1.webp-1736509946980',
    },
    dateCreated: 'Nov 20, 2024',
  },
  {
    content:
      "I must say Allen Henn you and your team have outdone yourselves - it looks super slick but mostly the reduction in admin time is *chefs kiss*",
    rating: 5,
    source: 'LinkedIn',
    author: {
      name: 'Izzy Brand — /in/izzy-brand-77783ab7/',
      image: 'https://backlinkitprod.blob.core.windows.net/assets/c52ee2d6-328e-4700-8c05-d08273d7b002_author-2.webp-1736509948472',
    },
    dateCreated: 'Nov 29, 2024',
  },
  {
    content:
      'I recently had the pleasure of trying out Backlinkit, and I must say, it’s perfect for SEO in 2025 and beyond. 🔗 ',
    rating: 5,
    source: 'LinkedIn',
    author: {
      name: 'Kira Khoroshilova — diaryofanseo.com',
      image: 'https://backlinkitprod.blob.core.windows.net/assets/6d7729d3-2494-404a-bf4b-2554fa8d9eb0_author-3.webp-1736509948750',
    },
    dateCreated: 'Dec 2, 2024',
  },
];
