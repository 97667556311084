import { ItemFeature } from '../components/app/app-comparison-table/app-comparison-table';

export interface ProductMock {
  title: string;
  btnLabel: string;
  price: number | string;
  features: ItemFeature[];
}

export const mockProducts: ProductMock[] = [
  {
    title: 'Basic Plan',
    btnLabel: 'Start 7 Day Trial',
    price: 42,
    features: [
      { title: 'Monitored Links', value: 1000 },
      { title: 'Monitored Domains', value: 25 },
      { title: 'User Seats', value: 5 },
      { title: 'Included Monthly Credits', value: 200 },
      { title: 'Google Index Checker (Incorrect checks are refunded)', value: true },
      { title: 'Domain Age Checker (Incorrect checks are refunded', value: true },
      { title: 'External Reports', value: true },
      { title: 'Batch Campaigns', value: true },
      { title: 'Domain Profile', value: true },
      { title: 'Link Database', value: true },
      { title: 'Prospective Links', value: 'Coming Soon' },
      // { title: '403 Bypass', value: true },
    ],
  },
  {
    title: 'Standard Plan',
    price: 75,
    btnLabel: 'Start 7 Day Trial',
    features: [
      { title: 'Monitored Links', value: 5000 },
      { title: 'Monitored Domains', value: 100 },
      { title: 'User Seats', value: 15 },
      { title: 'Included Monthly Credits', value: 500 },
      { title: 'Google Index Checker (Incorrect checks are refunded)', value: true },
      { title: 'Domain Age Checker (Incorrect checks are refunded', value: true },
      { title: 'External Reports', value: true },
      { title: 'Batch Campaigns', value: true },
      { title: 'Domain Profile', value: true },
      { title: 'Link Database', value: true },
      { title: 'Prospective Links', value: 'Coming Soon' },
      // { title: '403 Bypass', value: true },
    ],
  },
  {
    title: 'Pro Plan',
    price: 167,
    btnLabel: 'Start 7 Day Trial',
    features: [
      { title: 'Monitored Links', value: 15000 },
      { title: 'Monitored Domains', value: 150 },
      { title: 'User Seats', value: 20 },
      { title: 'Included Monthly Credits', value: 1000 },
      { title: 'Google Index Checker (Incorrect checks are refunded)', value: true },
      { title: 'Domain Age Checker (Incorrect checks are refunded', value: true },
      { title: 'External Reports', value: true },
      { title: 'Batch Campaigns', value: true },
      { title: 'Domain Profile', value: true },
      { title: 'Link Database', value: true },
      { title: 'Prospective Links', value: 'Coming Soon' },
      // { title: '403 Bypass', value: true },
    ],
  },
  {
    title: 'Pay As You Go',
    price: '1¢',
    btnLabel: '200 Credit Trial',
    features: [
      { title: 'Monitored Links', value: 'N/A' },
      { title: 'Monitored Domains', value: 'N/A' },
      { title: 'User Seats', value: 'N/A' },
      { title: 'Included Monthly Credits', value: 'Pay per Check' },
      { title: 'Google Index Checker (Incorrect checks are refunded)', value: true },
      { title: 'Domain Age Checker (Incorrect checks are refunded', value: true },
      { title: 'External Reports', value: true },
      { title: 'Batch Campaigns', value: 'N/A' },
      { title: 'Domain Profile', value: 'N/A' },
      { title: 'Link Database', value: 'N/A' },
      { title: 'Prospective Links', value: 'N/A' },
      // { title: '403 Bypass', value: 'N/A' },
    ],
  },
];
