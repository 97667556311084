import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from '../store';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const AppFetchBaseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/`,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
  },
});
