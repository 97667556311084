import { Box, Flex, FlexProps, useBreakpointValue } from '@chakra-ui/react';
import { AppColors } from '../../../theme/index';
import { AppText } from '@backlinkit/shared';
import { getFormatDate } from '../../../utils/date-format';

export interface AppChangelogCardProps extends FlexProps {
  title: string;
  dateUpdated: Date;
  changes: string[];
}

export const AppChangelogCard: React.FC<AppChangelogCardProps> = ({
  title,
  dateUpdated,
  changes,
}) => {
  const date = getFormatDate(dateUpdated);
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <Flex
      flexDir={'column'}
      p={variant === 'desktop' ? 10 : 6}
      borderRadius={'3xl'}
      bgColor={AppColors.primary}
      w={'100%'}
      maxW={'900px'}
      gap={4}
      height={'100%'}
      minH={'200px'}
    >
      <Flex align={'center'} gap={{base: 2, lg: 4}} color={'white'}>
        <AppText fontSize={variant === 'desktop' ? '36px' : '18px'} fontWeight={'700'}>
          {title}
        </AppText>
        <AppText fontSize={variant === 'desktop' ? '24px' : '14px'} fontWeight={'600'}>
          {date}
        </AppText>
      </Flex>
      <Flex flexDir={'column'} gap={4}>
        {changes.map((change, idx) => (
          <Flex
            align={'center'}
            key={`${change.slice(0.1)}-${idx}`}
            width={'100%'}
            gap={3}
            zIndex={5}
          >
            <Box boxSize={'10px'} bgColor={'white'} borderRadius={'full'} />
            <AppText color={'white'}>{change}</AppText>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
