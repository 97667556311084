import { FAQ } from '@backlinkit/shared';
import { As } from '@chakra-ui/react';
// import { ReactComponent as CtaIcon } from '../../../assets/article-cta-icon.svg';

export type ArticleContentType = 'text' | 'cta' | 'image';

export interface ArticleCtaContentType {
  backgroundImage: string;
  heading: string;
  description: string;
  icon: As | string | any;
}

export interface ArticleImageContentType {
  url: string;
  size?: string;
  alt: string;
  width: string;
  height?: string;
}

export interface ArticleDto {
  id: string;
  title: string;
  author: string;
  authorIcon: string | As | any;
  datePublished: Date;
  readTime: number;
  contributors: string[];
  lastUpdated: Date;
  articleBannerImageUrl?: string;
  slug: string;
  articleContent: {
    type: ArticleContentType;
    content: ArticleCtaContentType | string | ArticleImageContentType;
  }[];
}

const mockContentFirst = `
  <p>
    Prior to establishing your firm, you must be fully informed on the trends in your sector, your target market, and the products and services offered by your rivals.
  </p>
  <p>
    You will have all the necessary tools to conduct market research that enables you to obtain this important data by the end of this post. But first, though, a few fundamentals.
  </p>

  <h2>Market research: What is it?</h2>
  <p>
    Market research is the process of collecting and analyzing data about your target market to determine how successfully your specific product or service can be and decide how best to develop and launch it.
  </p>
  <p>
    This usually involves a variety of research methods, including conducting surveys, reading industry reports, and running focus groups.
  </p>

  <h2>The Importance of Doing Market Research for a Startup</h2>
  <h4>Performing market research allows you to:</h4>
  <ul>
    <li>
      Determine demand: Before spending money on producing and marketing your product or service, find out if there is a substantial market for it.
    </li>
    <li>
      Recognize your clients: To sell to customers more successfully, get to know them and their specific demands and purchase criteria.
    </li>
    <li>
      Develop a successful go-to-market plan: Learn more about your rivals and look for chances to be successful in the market.
    </li>
  </ul>

  <h2>Identify Competitors</h2>
  <h4>
    Industry competitors can roughly be divided into two types: direct and indirect.
  </h4>
    <p>
      <strong>Direct competitors:</strong> sell a product or service similar to yours and to the same market you intend to target.
    </p>
    <p>
      <strong>Indirect competitors:</strong> sell a product or service that isn't identical to yours but could be used as a substitute.
    </p>

  <p>
    How do you identify industry competitors? Well, you probably know about some of them already. But you can use Semrush's Market Explorer to uncover more. Click on the <a href='' target="_blank">"Analyze Category"</a> tab and choose your location and business category. Then, click on "Research a market."
  </p>
`;

const mockContentSecond = `
  <h2>Examine the Advertising Practices of Your Rivals</h2>
  <p>
    Industry competitors can roughly be divided into two types: direct and indirect.
  </p>
  <p>
    The next step is to learn more about your competitors' advertising activities to find out how much they're spending on paid search ads, which keywords they're bidding on, and what their ads look like.
  </p>
  <p>
    <a href='' target="_blank">Backlinkit Advertising</a> Research tool can help here.
  </p>

  <h2>Ensure Your Startup's Success with Market Research</h2>
  <p>
    Market research is an important first step in preparing your startup for success. And while getting started can be intimidating, using tools like Market Explorer and 2nd2Target make it easy to gain valuable insights into the market.
  </p>
  <p>
    Try them today with a <a href='' target="_blank">.Trends subscription</a>.
  </p>
`;

export const articleMocks: ArticleDto[] = [
  {
    id: 'fc17779a-110d-4c56-a938-2ac019ffaf0a',
    title: 'A Complete Guide to Market Research for Startups',
    author: 'Boris Mustapic',
    authorIcon: 'https://i.ibb.co/Xy0S4D1/boris-author-mock.png',
    datePublished: new Date(),
    readTime: 8,
    contributors: ['Cameron Williamson', 'Jenny Wilson'],
    lastUpdated: new Date(),
    slug: 'market-research',
    articleContent: [
      {
        type: 'text',
        content: mockContentFirst,
      },
      {
        type: 'cta',
        content: {
          heading: 'Analyze Your Market',
          description: 'with the Market Explorer Tool',
          backgroundImage:
            'https://backlinkitprod.blob.core.windows.net/assets/399996ea-b6ce-4e50-98c4-4a9c5ee53416_article-cta-background.webp-1735043275204',
          icon: 'https://backlinkitprod.blob.core.windows.net/assets/8a49a573-7c81-41c9-9eb4-3e4fad7b6813_article-cta-icon.svg-1736510811077',
        },
      },
      {
        type: 'text',
        content: mockContentSecond,
      },
      {
        type: 'image',
        content: {
          url: 'https://i.ibb.co/PxV1NwN/analyze-market-2.png',
          alt: 'Test Image',
          width: '100%',
        },
      },
    ],
  },
  {
    id: '3da394c3-6dc7-4fa4-935d-02910b96613c',
    title: 'What Are Backlinks in SEO? — We Explain Backlinking in 2025',
    slug: 'what-are-backlinks-in-seo',
    readTime: 18,
    datePublished: new Date(),
    lastUpdated: new Date(),
    author: 'Allen Henn',
    authorIcon:
      'https://backlinkitprod.blob.core.windows.net/assets/9f32477f-ffe6-427a-adab-0586ec324ed3_Allen%20Henn.webp-1736510089131',
    contributors: ['Cameron Williamson', 'Jenny Wilson'],
    articleBannerImageUrl:
      'https://backlinkitprod.blob.core.windows.net/assets/632ad3de-d4d9-45d0-ad0c-e371fa3a0d2b_article-card.png-1736770323040',
    articleContent: [
      {
        type: 'text',
        content: `<p>The top-ranking result for search queries on Google typically has <a href="https://backlinko.com/search-engine-ranking?utm_source=chatgpt.com" target="_blank">3.8 times more backlinks</a> than the pages ranked in positions (first page) 2 through 10.</p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/e1601801-34a1-4616-ac7f-0d59141a719d_Statistics%20on%20Backlinks%20and%20SEO%20Impact.png-1736770325015',
          alt: 'Stats on Backlinks',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>My career in SEO (search engine optimization), spanning more than a decade, has demonstrated to me and my clients how these ‘digital votes of confidence’ can significantly enhance a website’s search visibility on Google—let’s not forget Bing and Yahoo either.</p>
<p>A backlink is a link from another website to yours (website A linking to website B). </p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/db6436dd-1839-4124-aefd-2d8482fa1538_Definition%20of%20Backlinks.png-1736770328574',
          alt: 'image',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>Take note that this is a one-way outbound link, i.e., website B does not have to link back to website A for the link to be classified as a backlink for website A.</p>
<p>These inbound links (from your prospective links pointing to your website) affect SEO more than you might think.</p>
<h2>Article Highlights</h2>
<ul>
  <li>The foundation of SEO success is backlinks.</li>
  <li>Google's top rankings are dominated by websites with more backlinks.
  </li>
  <li>When it comes to backlinks, quality is more important than quantity.
  </li>
  <li>Link-building was forever changed by Google's Penguin update.</li>
  <li>More than 96% of webpages lack both backlinks and thus traffic.
  </li>
  <li>When it comes to domain authority, editorial links are the goal.
  </li>
  <li>Backlink efforts need to strike a balance between trust and relevancy.
  </li>
  <li>Backlinks increase visibility, traffic, and conversions.
  </li>
  <li>Your SEO efforts should be future-proof.</li>
  <li>Successful link-building demands a combination of strategy and creativity.</li>
</ul>

<p>Search engines use backlinks (also called linking pages) as trust signals to evaluate your content's authority, quality, and relevance, among others (there are over 200 significant factors).</p>
<p>The concept might seem simple enough, but implementation is not for the faint of heart (am I being overdramatic?).</p>

<p>What is step one as an SEO enthusiast?</p>
<p>Learn the skillset to ‘formulate’ a backlink strategy. </p>
<p>The strategy might consist of editorial links, guest posts, resource mentions, and digital PR features (more on that later, promise). </p>
<p>A great example of authoritative backlinks is getting your comment, explanation, or view added to an article in return for a link back to the source (your piece of content).</p>
<p>Let me walk you through everything you should consider and understand about ‘earning’ backlinks (and Google’s rules and policies) in 2025. </p>

<p style="font-weight: 600;">My view, my insight:</p>
<ul>
  <li>Quality over quantity is a non-negotiable factor
  </li>
</ul>
<p>Backlinks from authoritative and niche-relevant websites carry more weight and less risk of future complications when Google pushes out algorithm updates (we just love those).</p>

<h2>History of Backlinks—Then and Now</h2>
<p>Link-building has changed dramatically since the early days of SEO, when quantity mattered more than quality. </p>`,
      },
      // {
      //   type: 'image',
      //   content: {
      //     url: 'https://backlinkitprod.blob.core.windows.net/assets/15549d03-1e75-4ce5-b058-068a48ed20af_Comparison%20of%20Backlinks%20Then%20vs.%20Now.webp-1735043229318',
      //     alt: 'image',
      //     width: '70%'
      //   }
      // },
      {
        type: 'text',
        content: `<p>Trust me when I say it was like the 'Wild West' on the internet.
</p>
<p>I've seen changes happen right before my eyes as Google's algorithm became smarter at identifying backlink quality (authority and revelance come to mind). </p>
<p>Back ‘THEN’, even links from sketchy sources could push poorly designed sites to Google's top spots on SERPs. </p>
<p style="font-weight: 600;">Here is a short list of common link-building tactics used ‘THEN’:
</p>
<ul>
  <li>Blog comment links: Often using keyword-rich anchor text in the name field or within the comment itself, marketers would leave comments on blog entries.</li>
  <li>Forum links: Users would be able to add links to their websites in their forum signatures and forum comments.</li>
  <li>Directory links: Web directories were trendy, so a common link-building strategy was to be listed in these directories.</li>
  <li>Article directories: Articles were often sent to article directories, including links back to the author's website.</li>
  <li>Reciprocal links: Usually shown on "link pages" or in sidebars, reciprocating links would be exchanged between websites.</li>
  <li>Sitewide links: Usually found in the footer or sidebar, sitewide links showed on every page of a website.</li>
  <li>Blogroll links: Blogs sometimes featured a blogroll, a list of suggested sites in their sidebar.</li>
  <li>Redirects: Some SEOs would purchase expired domains, including current inbound links, and direct them to their target website.</li>
</ul>
<p>The above tactics were extremely popular back ‘THEN’, but what can we expect moving into 2025—who knows what Google has planned?</p>
<p>Google's Penguin update of 2012 changed everything about link quality evaluation. </p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/734a1b7f-6b99-4362-8553-02841354d410_Google%20Penguin%20Update%20of%202012.png-1736770331360',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>I was monitoring this update (man o man), and it flipped the industry upside down. </p>
<p>Everyone had to change their ‘best practices’ overnight.
</p>

<h2>Why Are Backlinks Important?</h2>
<p>Backlinks are crucial ranking factors (admit it or not) in the world of search engine optimization, serving as signals of confidence for your website or specific inner page of content.</p>
<p>These incoming links from other web pages (referring pages) to your site play a significant role in determining your website's authority, credibility, and relevance in the eyes (metaphorically, of course) of search engines. </p>
<p>When reputable websites link to your content, it signals to Google and alternate search engines that your information is valuable and trustworthy—the result: higher search engine rankings (we all have our eyes on that first page position).</p>
<p>Backlinks also facilitate the discovery of new web pages, including domains, by search engine crawlers (referred to as 'spiders’ back in the day), helping to index (discover) website content more efficiently.</p>
<p style="font-weight: 500">Popular SEO tools such as Ahrefs, Semrush, and Moz have their own domain rating criteria (backlink focused).</p>
<ul>
  <li>Ahrefs: Domain Rating (DR)</li>
  <li>Semrush: Authority Score (AS)</li>
  <li>Moz: Domain Authority (DA)—arguably the most used term</li>
</ul>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/db84019e-378d-48ff-9cfb-11592cce7979_Backlink%20Metrics%20Explained.png-1736770327673',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>Did you know that these scores range from 1 to 100, but Google doesn't use them as ranking factors? My recommendation is that you shouldn't rely only on these metrics to identify quality (authorative) websites. </p>
<p>Since search engine optimizers, not Google, use these metrics as ranking factors, I view DR, AS, and DA, among others, as synthetic benchmarks.</p>
<p>Google had a visible ranking metric called PageRank (this was actually an indication of how Google valued your website).</p>
<p>What does this tell us about Google’s original search engine algorithm?
</p>
<p>The algorithm was fundamentally flawed towards the volume of backlinks pointing to a website. As you can conclude, backlinks have been part of the algorithm since the ‘get go’.</p>
<p>Yet backlinks currently remain the key ranking signal and for the foreseeable future (in my opinion at least).</p>
<p>Here's something surprising (or is it?): 96.55% of all pages on the internet get zero organic traffic because they have zero backlinks.</p>
<p>How often does Google update their search engine algorithm? The answer is between 500 and 600 times per year, according to <a href="https://www.smartbrief.com/original/how-algorithm-updates-impact-your-seo#:~:text=How%20often%20does%20Google%20update,percentage%20of%20all%20keyword%20searches" target="_blank">smartbrief.com</a>.</p>
<p>In general, website owners only pay attention to larger algorithm updates (what a relief, right?).</p>
<p>Our friends over at searchengineland.com shared this: <a href="https://searchengineland.com/now-know-googles-top-three-search-ranking-factors-245882" target="_blank">Now we know: Here are Google’s top 3 search ranking factors</a></p>
<p>“Google's Andrey Lipattsev reveals links, content and RankBrain are the top three ranking signals in Google's search algorithm.”</p>
<p>Thank you, Andrey Lipattsev! Google will never give a clear, definite answer, but we will take the ‘top 3 comment’ (let’s assume backlinks are number 1 for the sake of this article).</p>
<p>More often than not, the outcome of a well-optimized website is relevant organic traffic from SERPs (search engine results pages, i.e., google.com) to the specific page (URL) that was optimized.
</p>
<p>Relevant traffic translates into more sales, subscriptions, or monotization opportunities and ultimately an increase in online revenue for your e-commerce, SaaS, or blog.</p>
<p>This is every website or business owner's dream outcome—money well spent (return on investment).</p>
<p>Unexpectedly (or unexpectedly), less than 20% of online businesses succeed, according to an article posted by failory.com in 2023.</p>
<p>Is this a lack of SEO knowledge, marketing know-how, or funds? Let me know what you think contributes to this statistic. </p>
<p>Let me keep you out of the 80% statistic by explaining what a backlink is and how it can drive traffic (potential clients) to your website (if you read till the end, that is).</p>
<h3>Backlinks and Website Performance</h3>
<p>I’ll keep this short... I’ll try my best.
</p>
<p>Technical performance and link acquisition share an interesting connection. Websites with 'Good' Core Web Vitals scores attract more root domains than sites with 'Poor' scores.</p>
<p>Nobody wants to link to a page that takes 10 seconds to load or spits out a 500 error</p>
<p>This shows that technically established websites naturally pull in more quality backlinks.</p>
<p>Technical excellence alone won't guarantee high-quality backlinks. 
</p>
<p>You need a complete strategy that combines technical optimization with valuable content (user-first approach) output. </p>
<p>Keep this in mind—just saying...</p>

<h2>Types of Backlinks in SEO</h2>
`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/e2549527-ab1f-439d-a709-db6010209bad_Examples%20of%20Backlink%20Types.png-1736770329224',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>This is the part most people are interested in.
</p>
<p>I bet you have received email after email and message after message on LinkedIn from so-called ‘outreach specialists.’</p>
<p>The two most common opening lines you can surely relate to are:
</p>
<ul>
  <li>Hi sir/madam</li> 
  <li>Hi dear</li>
</ul>
<p>It is worth mentioning that in addition to backlink types, there are other values potentially more important, such as rel link attributes (url tag) and robot meta tag (it has to do with indexiability).</p>
<p>If you are unaware of the meaning and importance of these values, you will end up with a disappointing outcome.</p>
<p>I’ll discuss this in detail in a later article, but let’s bulletpoint key points on the rel link attribute (rel=) values:</p>
<ul>
  <li>Follow (dofollow)</li>
  <li>Nofollow</li>
  <li>UGC (user-generated content, also seen as a nofollow)</li>
  <li>Sponsored (also seen as a nofollow)</li>
</ul>
<p>For a diversified backlink profile, follow is not always the go-to value.
</p>
<p>NOTE: Confirm with the author if the page in question will be added to the sitemap (domain.com/sitemap.xml) or excluded.</p>
<h3>Backlinks Come in Various Forms
</h3>
<p>There are numerous forms of backlinks, and utilizing each affectively can help in achieving a ‘natural’ and ‘diverisifed’ backlink profile (that Google loves).
</p>
<p>Don’t worry, there are many tools that can help you monitor these metrics.
</p>
<h4>Text Links—Most Common
</h4>
<p>Text links (hyperlinks) are the most popular form of backlink.
</p>
<p>These links are clickable text (anchor text) that navigates to another webpage (page on an external domain). </p>
<p>Text links are seen as highly effective for SEO, as the anchor text provides search engines with contextualized context about the outbound linked URL.
</p>
<p>Side note: links can be set to <a href="https://www.freecodecamp.org/news/how-to-use-html-to-open-link-in-new-tab/" target="_blank">open in the same window or a new tab</a> using the target=”_blank” attribute. I highly recommend you always add the rel=”noreferrer noopener” element when opening a link in a new tab.</p>
<p>Why do I recommend this approach? The rel attribute sets the associated relationship between the linking page and the linked URL.</p>
<p>Setting it to noopener noreferrer is to prevent a type of phishing known as <a href="https://en.wikipedia.org/wiki/Tabnabbing" target="_blank">tabnabbing</a>. I’ll let Wikipedia explain in more detail.</p>
<p>We will cover the types of text links in a future article. Yes, this goes deep.
</p>
<ul>
  <li>Branded Anchors</li>
  <li>Naked URL</li>
  <li>Generic Anchors</li>
  <li>Exact Match Anchors</li>
  <li>Partial March Anchors</li>
  <li>Industry Anchors</li>
</ul>
<p>..and the list goes on.</p>
<h4>Image Links—With Alt Text
</h4>
<p>Image links are exactly that: hyperlinks attached to an image (jpg, png, webp, etc.). When a visitor clicks the image (&lt;img&gt;
  tag in HTML), they are redirected to the destination URL just like a text link would.
</p>
<p>Despite their underutilization, I believe they have great SEO value.
</p>
<h4>Redirects—301 and 302
</h4>
<p>Redirects were extremely popular up until the mid-2000s, not to say some don’t incorporate this into their SEO strategy (different approach) today.</p>
<p>Redirects (&lt;meta http-equiv=”refresh” content”0; url=$URL”&gt;)among other methods can be added in the header of a page similar to other meta tags.
</p>
<p>This redirects URL A to URL B like magic.
</p>
<p>Here are the two most common redirects:
</p>
<ul>
  <li>301 Redirect—a permanent redirect that informs search engines and visitors that the page has moved to a new location.</li>
  <li>302 Redirect—a temporary redirect that informs search engines and visitors that the page may return back to the original destination.</li>
</ul>
<p>On to the primary baclink types (time to get some link juice).
</p>
<h3>Guest Post—Blogging
</h3>
<p>Guest posting, also known as guest blogging, is a core SEO strategy where an individual writes (produces) a piece of content for another website or blog.
</p>
<p>The website owner will usually have a set of rules or guidelines that the writer needs to follow to keep a standard in regards to content quality.</p>
<p>The following bullet points are common guidelines:
</p>
<ul>
  <li>Word count</li>
  <li>Topic/Niche</li>
  <li>Content structure</li>
  <li>On-site SEO</li>
  <li>Outbound links</li>
  <li>Rel attribute (folow, nofollow, UGC, Sponsored)</li>
</ul>
<p>The content (article) first and foremost should provide value for the reader and showcase experience and authority on the topic.</p>
<p>A poorly written article will not only discourage user engagement but also risk the possibility of not being indexed on SERPs.
</p>
<p>To counter this, website owners typically make ‘tier 2’ links pointing to the newly published guest post to encourage Google to index the page.</p>
<p>When the content is extremely poor, this is only a temporary fix, if it even works at all. This outcome is a bad ranking signal for the domain that the article was published on.</p>
<p>Google is all for user-first relevant content; failing to tick that box will render this guest post a waste of time.</p>
<p>How does this process benefit the guest author and the host blog?</p>
<ul>
  <li>The guest author gets potential exposure (not the main focus) and a ‘valuable’ backlink.</li>
  <li>The host blog received a 'value-added' piece of content, which helps build out the website’s authority in the respective niche. </li>
</ul>
<p>You’ll notice I put ‘valuable’ and ‘value-added’ in single quotes.
</p>
<p>Here is why—some authors end up going for any website willing to accept the guest post. These situations leave the author with a less than desired backlink, which carries little to no authority.</p>
<p>On the flip side—the blog owner ends up publishing poor content on their website, which negeatively impacts SEO. This is mostly true when the blog owner accepts guest posts purely for the purpose of financial gain.
</p>
<p>I am going to be real with you; the key motivation behind guest posting is backlinks.</p>
<p>Guest post opportunities are easy to come by by simply utilizing Google search.</p>
<p>Input: “write for us” SEO and HIT search.
</p>
<p>There are many variations of this query you can explore to upscale your outreach attempts.</p>
<p>A common route is to outsource your outreach strategy by utilizing services on sites like Fiverr or Upwork. In most cases, this is very affordable and a time-saving tactic.</p>
<p>Here is your basic checklist when it comes to guest posting:
</p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/14049f99-8acd-4df1-bf61-dde2fe60b51f_Guest%20Post%20Workflow.png-1736770326551',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<ol>
  <li>Target authoritative and niche-relevant sites</li>
  <li>Write quality and value-added content (don’t spin, 100% AI generate or plagiarize)</li>
  <li>Diversify anchor text selection strategically</li>
</ol>
<p>When done right, a guest post strategy can be extremely beneficial from an SEO perspective.</p>
<p>Don’t take the lazy approach, as you might be taking one step forward and two steps back!
</p>
<h3>Editorial Links—Source of Information
</h3>
<p>Editorial links are at the top of the list when it comes to impressing Google, signaling authority and trust. </p>
<p>Yes, they are EARNED.
</p>
<p>When a website’s author finds your content or website valuable, it becomes a worthwhile candidate for referencing, i.e., linking back to the source—your domain.
</p>
<q>The bitterness of poor quality remains long after the sweetness of low price is forgotten. </q>
<p>This aligns well with the fact that editorial links are much harder to ‘earn’ than low-effort or paid links, adding long-term value to websites authority and reputation—proving that investment in quality content truly pays off.</p>
<p>On the subject of quality content, let’s talk AI real quick.
</p>
<p>AI is a wonderful technology that can be extremely beneficial in assisting with a lot of tasks, such as content creation.
</p>
<p>Unfortunately, some content writers (even SEO agencies) opt for a shortcut, producing hundreds of articles that are purely AI-formatted, without 'sprinkeling’ any personal input (human touch) into their online content creation.</p>
<p>I can hear you say, ey, but there are tools to humanize the content and pass an AI detection check. That is true.
</p>
<p>But…
</p>
<p>That may fool an algorithm but might not convince another website’s author to use you as a reference. </p>
<p>Your on-page user engagement metrics will paint a picture and signal to Google’s algorithm that the content is poorly written.
</p>
<p>If you are after editorial links, the above is not the way to go!
</p>
<p>Write to impress!
</p>
<p>Below, I have listed five examples of editorial links to best illustrate scenarios where your content can be featured.</p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/81867212-b8de-4393-9570-20d672222785_Editorial%20Link%20Examples.png-1736770328884',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>Citations in Articles:
</p>
<ul>
  <li>A journalist writes a piece about SEO trends and includes a link to your research or case study because it supports their argument or opinion.</li>
</ul>
<p>Expert Mentions:
</p>
<ul>
  <li>A blog about marketing mentions your website or tool as one of the best solutions for content optimization, including a link to your homepage or product page (Yip yip).</li>
</ul>
<p>Infographic Shares:
</p>
<ul>
  <li>You create an engaging infographic about a complicated subject, and another website embeds (displays) it in their content while linking back to your site as the original source of the media.</li>
</ul>
<p>Awards or Rankings:
</p>
<ul>
  <li>Your website is featured in a "Top SEO Tools for Small Businesses" article, and the writer links to your website from that article showcasing your award.</li>
</ul>
<p>Interviews or Quotes:
</p>
<ul>
  <li>You are interviewed as an industry expert (Guru or professional), and the publication links to your website via their bio or content.</li>
</ul>
<p>Before we move on to niche edits, it is important for me to mention that the term “earn media coverage” can really help you standout.</p>
<ul>
  <li>Press releases</li>
  <li>HARO (Helpo a reported out)</li>
</ul>
<p>PR is ‘popping off’.
</p>
<p>Before we go off topic again, let’s discuss this in detail in a future article.
</p>
<h3>Niche Edit—Relevant Content
</h3>
<p>Unlike guest posts, which we discussed earlier, niche edits are backlinks added into existing content (promise me you will check if the page is indexed) to blend and read naturally within the article  it is placed in.
</p>
<p>For this to make sense, I am sure you figured that the page or post’s topic and content need to be relevant to yours.
</p>
<p>Niche edits are also referred to as curated or contextual links.
</p>
<p>It sounds good, right? Niche relevant links without having to write a few thousand-word article. </p>
<p>Not as easy as you might think when it comes to acquiring quality and worthwhile opportunities, trust me.`,
      },
      {
        type: 'cta',
        content: {
          heading: 'Grab Your 7 Day Free Trial',
          description: 'with the BacklinkIt Intelligence tool',
          backgroundImage:
            'https://backlinkitprod.blob.core.windows.net/assets/cda79755-e8c7-4836-ac99-c086aa9d5a21_article-cta-background.png-1736770954770',
          icon: 'https://backlinkitprod.blob.core.windows.net/assets/73a5f13c-7aa6-4878-a886-e8765bb672cd_article-cta.png-1736770953279',
        },
      },
      {
        type: 'text',
        content: `
</p>
<p>Here is how the process could work:
</p>
<p>Content Identification:
</p>
<ul>
  <li>Your friendly neighbourhood SEO or link builder (or you) identify (relevant, high-quality) articles or web pages that align with the target website’s niche or industry.</li>
</ul>
<p>Outreach:
</p>
<ul>
  <li>They (or you) contact the website owner or editor to request (sometimes followed by begging) a link placement. This might involve offering compensation (95% of the time), mutual value (4.9%), or simply highlighting how the link improves the content (0.1%).</li>
</ul>
<p>
  Link Placement:
</p>
<ul>
  <li>The outbound link is inserted within the content with appropriate anchor text, ensuring that it is surrounded by relevant context, thus making sense to the reader.</li>
</ul>
<p>Note, even if the article is relevant, always insist on providing a short paragraph to accommodate the anchor text. This way you control the surrounding content to some extent.</p>
<p>I must admit that niche edits are one of my favourite ways of obtaining backlinks, yet only possible when you have quality content.
</p>
<p>Why are niche edits popular and preferred over other implementations?
</p>
<p>Existing Authority:
</p>
<ul>
  <li>Links are placed on URLs that have already been indexed by search engines (check this on Google first site:seo.com/page-name/), often on established and authoritative domains.</li>
</ul>
<p>Immediate Impact:
</p>
<ul>
  <li>Since the content is already live, niche edits can potentially start influencing search rankings and traffic sooner than other types of backlinks.</li>
</ul>
<p>Contextual Relevance:
</p>
<ul>
  <li>These links are surrounded by related content, making them more relevant and impactful for SEO than unrelated (BIG no no) backlinks.</li>
</ul>
<p>Did you know that when it comes to link placement, it is best to:
</p>
<ol>
  <li>Position anchor within the content
  </li>
  <li>Add anchor in surrounding context relevance
  </li>
  <li>Keep number of other links on the page (EFL) low
  </li>
</ol>
<p>EFL, also known as external follow links, refers to outbound links that enable Google to follow them back to the source, thereby passing link juice.</p>
<p>Without a doubt, valuable backlinks appear naturally within relevant, high-quality content, but don’t take my word for it—put it into practice.
</p>
<p>Note that a few high-quality, topically relevant (and authorative) backlinks carry more weight than many low-quality ones from poor sources.</p>
<p>Or the fancy version:
</p>
<q>"A single gourmet meal is worth more than a thousand fast-food burgers”—and the same goes for backlinks.</q>
<p>These are much easier to obtain and implement yourself.
</p>
<h4>Social Banklinks (tweet tweet)
</h4>
<p>Social backlinks are typically nofollow yet play a role in an SEO strategy, despite not having a direct impact.
</p>
<p>These are links referring back to your website shared on social media platforms by users on Facebook, Twitter, LinkedIn, Instagram, Pinterest, etc.
</p>
<p>They can come from:
</p>
<ul>
  <li>Posts or updates
  </li>
  <li>Profiles or bios
  </li>
  <li>Comments or discussions
  </li>
  <li>Shares, retweets, or repins
  </li>
</ul>
<p>Although the SEO value is not the usual ‘link juice’, social media links indirectly benefit SEO efforts (tick every box) in the following ways:
</p>
<ol>
  <li>Driving Referral Traffic: Social media links can bring in a significant amount of traffic to your site, signaling to search engines that your content is popular and valuable among your audience.
  </li>
  <li>Increasing Brand Visibility: A strong social media presence increases your brand's exposure, which can lead to natural backlinks from other websites in your niche.
  </li>
  <li>Boosting Engagement Metrics: An increase in engagement metrics (time on site, lower bounce rates) from social referral traffic can positively affect rankings.
  </li>
</ol>
<p>I am confident that I left out some 'benefits’ (feel free to let me know), but these are the three I’ve found most valuable in my experience.
</p>
<h4>Discussion Forum (insane amount of traffic)
</h4>
<p>Discussion forums like Reddit can play a valuable role in your backlink strategy when executed correctly. Similar to social links, forum links are nofollow by default (tells Google to ignore the link).
</p>
<p>The time has come to share some traffic stats.
</p>
<ul>
  <li>Reddit: Monthly visits as of March 2024—2.2 billion visits
  </li>
  <li>Quora: Monthly visits in August 2024—837.1 million visits
  </li>
</ul>
<p>Mindblowing right?
</p>
<p>Forums are platforms where users discuss topics, share knowledge, and seek recommendations.
</p>
<p>Links (website URLs) inserted within discussion threads, comments, or posts on forums like Reddit, Quora, and niche-specific forums.
</p>
<p>Here is a simple example:
</p>
<p>Reddit post: "What are the best SEO tools?"
</p>
<p>Comment: "You might find this tool helpful: [https://tool.com]."
</p>
<p>Give this search operator a try: site:reddit.com inurl:(seo | backlinks)
</p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/62474f72-7683-4241-b6d3-d8dacc64fe79_Search%20Operator%20Example.png-1736770325759',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>What is a search operator, and what does it do in this context? 
</p>
<p>Let me explain.
</p>
<p>Special commands and symbols (:””()|) designed to help you refine search results are called Google advanced search operators. 
</p>
<p>Introducing these operators into your search queries will help you to filter out irrelevant content and locate exactly what you need without the fluff.
</p>
<p>Marketers in SEO, digital marketing, and anyone searching for particular outcomes on the internet will especially benefit from these site operators.
</p>
<p>In combination with a tool such as Ahrefs, identifying SERP results with high traffic (in your niche) is displayed below each search result.
</p>
<p>Tah-dah. Reddit threads are ready and waiting for you to engage with!
</p>
<p>Here are some indirect SEO benefits:
</p>
<ol>
  <li>Traffic: Popular topics (threads) can drive targeted traffic from engaged users.
  </li>
  <li>Increased Visibility: When shared in relevant discussions, your content can reach a niche audience that is already looking for your product or service.
  </li>
  <li>Backlink Opportunities: Popular threads might attract bloggers or journalists who can potentially reference (good links to have) your link in their own content.
  </li>
</ol>
<p>When using this approach, remember that adding value is the key to standing out.
</p>
<h4>Blog Comments (WHAT???)
</h4>
<p>Blog comments ‘could/might/has/have’ served as a tool for building backlinks (it was extremely popular back in the day), but their effectiveness has diminished over time due to overuse by spammers automating the posting process. 
</p>
<p>In my professional opinion, I would not recommend spending time on building these links by utilizing blog comments. 
</p>
<p>Let’s look at an example anyway (it is all about education, right?).
</p>
<p>Blog posts' comments contain links, either in the comment's body or in the "Website URL" field the blog's comment form (typically a Wordpress blog) provides.
</p>
<p>Example (note that people had different templates and variations to help get their comments approved):
</p>
<ul>
  <li>Blog post: “Top 10 SEO Link-Building Trends”
    Comment: “Great article! I’ve found that [guest post trend] works really well too. I discussed this in detail here: [Your URL]. Let me know if you found it valuable.”
    </li>
</ul>
<p>The main reason behind blog post comment links was to get backlinks pointing to your website, which at the time was a common tactic within the SEO industry.
</p>
<p>Let me add—it was also extremely cheap (on the black market…)!
</p>
<h4>Brand Mentions (brand clout)
</h4>
<p>Brand mentions, unlike backlinks, which directly link to your website, are simply textual references (https://brand.com in text format is still a brand mention).
</p>
<p>Brand mentions are powerful 'signals’ that, while not always directly linked to your website, play a crucial role in building authority (domain and brand), boosting your online reputation, and influencing organic traffic. 
</p>
<p>Google has evolved to consider both linked (href) and unlinked (text) mentions of brands when evaluating the authority and credibility of a site (hell yeah).
</p>
<p>These mentions signal to search engines that your brand is important, trusted, and talked about, which can have a positive impact on search rankings over time (I experienced this when doing a lot of PR).
</p>

<h2>Measuring Backlink Success</h2>
<p>Measuring your backlink strategy's success (or failure) requires more than just counting links. 
</p>
<p>You need to understand how they affect your SEO performance and ultimately your traffic.
</p>
<p>After managing hundreds of link-building campaigns, I've developed a systematic approach that goes beyond simple 1,2,3 metrics.
</p>
<p>I recommend taking the following three points into account:
</p>
<ul>
  <li>ROI calculation requires multiple metrics
  </li>
  <li>Long-term value exceeds immediate gains (important)
  </li>
  <li>Competitive analysis is significant
  </li>
</ul>
<h3>Key Performance Indicators
</h3>
<p>Successful backlink measurement demands tracking multiple KPIs (key performance indicators). 
</p>
<p>Industry data shows that new backlinks take an average of 90 days to fully affect your rankings, a statement published by Pankaj Yadav over at <a href="https://www.webapex.com.au/blog/seo-backlinks-ranking-time/?utm_source=chatgpt.com" target="_blank">webapex.com</a>.
</p>
<p>In my professional opinion, this is a very broad statement that overlooks so many variables that can push that 90-day timeframe out the window.
</p>
<p>Therefore, take it with a pinch of salt.
</p>
<p>Essential KPIs to keep an eye on:
</p>
<p>Number of referring domains
</p>
<ul>
  <li>The total number of unique domains (websites) that link to your website.
  </li>
</ul>
<p>Domain Authority trends
</p>
<ul>
  <li>We briefly touched on this earlier in the article, but in this use case we look at our own domain’s domain rating (DR, DA, or AS) to see an uptick in value, indicating positive backlink efforts.
  </li>
</ul>
<p>Organic traffic changes
</p>
<ul>
  <li>Monitoring the difference in traffic to your website via search engines from SERPs.
  </li>
</ul>
<p>Conversion rates from referral traffic
</p>
<ul>
  <li>Visitors taking action (e.g., signup, purchase, or lead) after clicking through from a backlink.
  </li>
</ul>
<p>Social media engagement metrics
</p>
<ul>
  <li>Engagement metrics such as shares, likes, comments, and clicks on posts containing your backlink on social media platforms.
  </li>
</ul>
<h3>ROI Calculation Methods
</h3>
<p>The ROI calculation can combine both short-term and long-term metrics, but it depends on how you define and measure both revenue and cost.
</p>
<p>Let me explain:
</p>
<h4>Short-Term Metrics in SEO ROI
</h4>
<ul>
  <li>Revenue from immediate conversions: This includes sales or leads generated shortly after launching SEO campaigns, such as during a promotional period or for time-sensitive content.
  </li>
  <li>Initial costs (startup) of SEO implementation: Expenses such as technical, on-site, initial content creation, and setup fees are part of short-term costs.
  </li>
</ul>
<p>Long-Term Metrics in SEO ROI
</p>
<ul>
  <li>Cumulative organic traffic growth: SEO benefits often compound as time goes on, with older content continuing to drive traffic and generate leads or sales.
  </li>
  <li>Sustained authority and rankings: High-quality backlinks or evergreen content built today might drive revenue for years to come.
  </li>
  <li>Ongoing management costs: Expenses like content updates, outreach, and monitoring tools extend into the long term.
  </li>
</ul>
<p>Here is what a basic formula might look like:
</p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/61596889-a6a4-429f-9483-2969965f157f_ROI%20Calculation%20Example.png-1736770326301',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<a href="https://www.semrush.com/blog/seo-kpis/" target="_blank">ROI = (Revenue from SEO - Cost of SEO) / Cost of SEO x 100
</a>
<p>Example:
</p>
<ul>
  <li>Revenue from SEO: $10,000
  </li>
  <li>Cost of SEO: $2,000
  </li>
</ul>
<p>
  ROI = (($10,000 - $2,000) / $2,000) × 100 <br>
  ROI = (8,000 / 2,000) × 100 <br>
  ROI = 4 × 100 = 400% <br>
</p>
<p>In this case, for every $1 spent on SEO, you earned $4 in return (you are doing something right!). A positive ROI like this indicates that your SEO efforts are profitable.
</p>
<p>Ready to upscale?
</p>
<p>Remember that the true value often extends beyond immediate returns.
</p>
<h3>Competitive Benchmarking
</h3>
<p>Comparing your backlink profile against your competitors provides valuable insights (sneak peak).
</p>
<p>Three key areas stand out in competitive analysis—for me at least:
</p>
<ol>
  <li>Keyword strategy: Understand your close competitor’s keyword targeting and identify opportunities where you can outperform.
  </li>
  <li>Link diversity: Evaluating the big 3: quantity, quality, and relevance of their incoming links, uncovering link-building opportunities.
  </li>
  <li>Content performance: Assess which content types attract the most organic links and resonate with your competitor’s audience to help guide your current content strategy.
  </li>
</ol>
<p>Websites with similar backlink profiles do not always tend to rank closely together in search results for similar keywords. 
</p>
<p>If your competitors have an average of 1,000 referring domains, does that become the benchmark needed to compete effectively?
</p>
<p>Hell no. They might have taken the ‘dirty’ way looking for quick results, usually manipulating the algorithm—short term.
</p>
<p>Remember the quality over quantity discussion we had earlier?
</p>
<p>Tools like Ahrefs or Semrush make tracking these metrics systematic and save hours of manual analysis (we are all about saving time).
</p>
<p>Take into consideration that maintaining a successful backlink profile requires regular attention and input.
</p>
<p>Major monthly reviews help adjust strategies based on real-world data.
</p>
<p>How does your current monitoring approach stack up against these standards?
</p>

<h2>Are You Ready to Adapt?</h2>
<p>What does the future of backlinking look like?
</p>
<p>The techniques we depend on now might soon be outdated as the digital ‘matrix’ changes. 
</p>
<p>Originally a basic numbers game, backlinking has evolved into a complex form of decision-making needing accuracy, relevance, and foresight. 
</p>
<p>The question to you is, whether your present methods are sustainable or whether you are unintentionally (lack of knowledge) laying on a foundation that might crumble with the next Google algorithm update.
</p>
<p>I mentioned this before, and I am mentioning it again: Google’s algorithms change hundreds of times each year.
</p>
<p>Beyond strategy, what about the tools and technologies shaping the future of backlinking? AI-powered algorithms, user behavior analytics, and even Web3 innovations could redefine how search engines value connections between sites. </p>`,
      },
      {
        type: 'image',
        content: {
          url: 'https://backlinkitprod.blob.core.windows.net/assets/68ce4ece-6813-4f8c-a8d4-1a10b3171f5d_Future%20of%20Backlinks%20%28Web3%29.png-1736770330057',
          alt: 'img',
          width: '70%',
        },
      },
      {
        type: 'text',
        content: `<p>So ask yourself, are you prioritizing quality over quantity? Are you earning links that matter, or merely accumulating numbers that inflate vanity metrics?
</p>
<p>The real takeaway here is the following: Backlinks are not just "digital votes of confidence"—they are investments in your online visability. 
</p>
<p>Like any investment, they demand careful planning, consistency, and a willingness to adapt to new standards—and quick!
</p>
<p>So, how will you future-proof your backlinking approach in 2025 and beyond? 
</p>
<p>My one colleague from back in the day always use to say: “Backlinks are like links in a chain—strong ones hold everything together, weak ones snap under pressure, and broken ones… well, they’re just hanging around waiting for you to fix them.
</p>
<p>The next move is yours to make.
</p>`,
      },
      {
        type: 'cta',
        content: {
          heading: 'Grab Your 7 Day Free Trial',
          description: 'with the BacklinkIt Intelligence tool',
          backgroundImage:
            'https://backlinkitprod.blob.core.windows.net/assets/cda79755-e8c7-4836-ac99-c086aa9d5a21_article-cta-background.png-1736770954770',
          icon: 'https://backlinkitprod.blob.core.windows.net/assets/73a5f13c-7aa6-4878-a886-e8765bb672cd_article-cta.png-1736770953279',
        },
      },
    ],
  },
];

export const articleFaqs = [
  {
    question: 'What is a backlink in SEO with example?',
    answer: `<p>A backlink is a link pointing to another website. Another name for it is an external or inbound link. Backlinks can raise your website's ranking by signaling to search engines that the content is reliable and deserving of citation.</p> <br/> <p>The service page of your agency site is linked to in a tech blog post about the "best SEO agencies."</p>`,
  },
  {
    question: `How do I create an SEO backlink?`,
    answer: `<ul>
    <li>Publish content that can be shared, such as videos, infographics, and blogs.</li>
    <li>Guest posting is the practice of publishing articles with links to your website on related websites.</li>
    <li>Join forces (network) with bloggers or influencers in your niche.</li>
    <li>Look for broken links (broken link-building) on websites and offer your content as a replacement.</li>
    </ul>`,
  },
  {
    question: `What are the three types of backlinks?`,
    answer: `<ol>
      <li>DoFollow backlinks.</li>
      <li>NoFollow backlinks.</li>
      <li>User-generated or sponsored content links with particular tags are known as UGC/sponsored links.</li>
    </ol>`,
  },
  {
    question: `What backlinks are best for SEO?`,
    answer: `<p>Editorial links are acquired organically by means of high-quality content.</p> <br /> <p>Links from websites in your niche, also known as relevant links.</p>`,
  },
  {
    question: `How to get free backlinks?`,
    answer: `
      <p>Use free directories, such as DMOZ or Yelp, to submit to them.</p>
      <p>Make use of social media content to build organic backlinks.</p>
      <p>Engage in forums (such as Reddit) by posting insightful (helpful and value-added) replies along with links to your website.</p>
      <p>Building broken links by providing your content to take the place of broken links within third-party content.</p>`,
  },
  {
    question: `Is YouTube a backlink?`,
    answer: `
      <p>Yip, backlinks can be created from links in your YouTube channel or description. They increase brand visibility and drive traffic, even though they are usually nofollow.</p>
      <p>What is the fastest way to get backlinks for a website?</p>
      <p>Content outreach is usually the answer. Send a pitch to website owners or bloggers, asking them to link to your content.</p>
      <p>Guest blogging can be accomplished by building partnerships with popular blogs.</p>
      <p>Help A Reporter Out, or HARO: For a backlink, give journalists quotes.</p>
    `,
  },
  {
    question: `Do paid backlinks work?`,
    answer: `<p>Paid backlinks are against Google policy, and your website runs the risk of being penalized.</p>`,
  },
  {
    question: `How many backlinks do you need for SEO?`,
    answer: `<p>No set number exists (do competitor analsysis). Prioritize quality over quantity. Hundreds of low-quality backlinks are not as beneficial as a few high-authority ones.</p>
    `,
  },
  {
    question: `What is the difference between a link and a backlink?`,
    answer: `<p>A link is any internal or external URL that can be clicked on a page.</p>
    <p>A backlink is a link pointing to your website from another website.</p>
    `,
  },
  {
    question: `How many backlinks should a website have?`,
    answer: `<p>Your competition and niche will determine the number to aim for if we assume all their links are of high quality.</p>
    `,
  },
  {
    question: `How do you use backlink keywords?`,
    answer: `<p>When sensible, use target keywords as anchor text (text that can be clicked in a hyperlink); however, make sure it is diverse and not overly optimized (use generic and branded terms as well).</p>
    `,
  },
  {
    question: `How to find backlinks?`,
    answer: `<p>Use popular SEO tools like: Backlinks are analyzed by tools such as Ahrefs (I personally use them), SEMrush, or Moz.</p>
    <p>Analyze your competitors by looking at their backlink profiles and improving on their tactics.</p>
    `,
  },
];

// const articleMock: AppArticle = {
//   articleImage: 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   dateCreated: new Date(),
//   readingTime: '5 min read',
//   title: 'Understanding the Future of Web Development',
//   description: 'An in-depth look at the trends and technologies shaping the future of web development.',
//   author: {
//     authorImage: 'https://i.ibb.co/Xy0S4D1/boris-author-mock.png',
//     firstName: 'Boris',
//     lastName: 'Mustapic',
//     id: 'thisIsAnID',
//     socials: [
//       {
//         href: 'https://facebook.com',
//       },
//     ],
//   },
//   id: "tsek",
//   lastModified: new Date(),
// };

// export const mockRelatedArticles = [articleMock, articleMock, articleMock]
