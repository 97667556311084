import { SelectOption } from "@backlinkit/shared";

export const countryOptions: SelectOption[] = [
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Australia", label: "Australia" },
    { value: "India", label: "India" },
    { value: "Germany", label: "Germany" },
    { value: "France", label: "France" },
    { value: "Japan", label: "Japan" },
    { value: "China", label: "China" },
    { value: "Brazil", label: "Brazil" },
    { value: "South Africa", label: "South Africa" },
    { value: "Russia", label: "Russia" },
    { value: "Mexico", label: "Mexico" },
    { value: "Italy", label: "Italy" },
    { value: "Spain", label: "Spain" },
    { value: "Argentina", label: "Argentina" },
    { value: "South Korea", label: "South Korea" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Sweden", label: "Sweden" },
    { value: "Norway", label: "Norway" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Denmark", label: "Denmark" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Turkey", label: "Turkey" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "Egypt", label: "Egypt" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Kenya", label: "Kenya" },
    { value: "Ghana", label: "Ghana" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Singapore", label: "Singapore" },
    { value: "Thailand", label: "Thailand" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Poland", label: "Poland" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Hungary", label: "Hungary" },
    { value: "Austria", label: "Austria" },
    { value: "Belgium", label: "Belgium" },
    { value: "Ireland", label: "Ireland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Greece", label: "Greece" },
    { value: "Finland", label: "Finland" },
    { value: "Chile", label: "Chile" },
    { value: "Colombia", label: "Colombia" },
    { value: "Peru", label: "Peru" },
    { value: "Venezuela", label: "Venezuela" }
  ];
  