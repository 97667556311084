import React from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppArticleBanner } from '../../components/app/app-article-banner/app-article-banner';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppChangeList } from '../../components/app/app-change-list/app-change-list';
import { data } from '../../constants/change-mocks';
import { articleMocks } from '../article/mocks/article-mock';
import { PageChangelogPage } from '../../constants/meta-data';
import { usePageMeta } from '../../hooks/usePageMeta';
import { heroBackground, mobileBackground } from '../../constants/heroImages';

export const ChangeLog: React.FC = () => {
  usePageMeta(PageChangelogPage);
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <>
      <Flex flexDir={'column'} paddingBottom={'80px'} align={'center'} width={'100%'}>
        <AppComponentWrapper px={0}>
          <AppBasicHero
            pageName="Changelog"
            backgroundImage={variant === 'desktop' ? heroBackground : mobileBackground}
          />
        </AppComponentWrapper>
        <AppComponentWrapper justify={'center'} my={'80px'} px={{ base: 0, lg: 7, xl: 0 }}>
          <AppChangeList changes={data} />
        </AppComponentWrapper>
        <AppComponentWrapper justify={'center'} px={{ base: 0, lg: 7, xl: 0 }}>
          <AppArticleBanner
            tag="Featured Articles"
            title="Why Backlinks Are Key to Boosting Your Website's Rankings"
            article={articleMocks[1]}
          />
        </AppComponentWrapper>
      </Flex>
    </>
  );
};
