import { AppTextRenderer } from "@backlinkit/shared";
import { ArticleCtaContentType, ArticleImageContentType } from "../../pages/article/mocks/article-mock";
import ArticleCtaComponent from "./article-cta";
import { Image } from "@chakra-ui/react";

interface TextContent {
  type: 'text';
  content: string;
}

interface CtaContent {
  type: 'cta';
  content: ArticleCtaContentType;
}

interface ImageContent {
  type: 'image';
  content: ArticleImageContentType;
}

type ArticleContent = TextContent | CtaContent | ImageContent;

interface ArticleContentRendererProps {
  content: ArticleContent;
}

const ArticleContentRenderer: React.FC<ArticleContentRendererProps> = ({ content }) => {

  switch (content.type) {
    case 'text':
      return <AppTextRenderer className="article-content" markdown={content.content} color='primary' isArticle={true} />;
    case 'cta':
      return <ArticleCtaComponent content={content.content} />;
    case 'image':
      return <Image m={{ base: '20px 0px', lg: '30px 0px' }} alignSelf={'start'} w={{ lg: content.content.width ?? '100%', base: '100%' }} src={content.content.url} alt={content.content.alt || 'Image'} />;
    default:
      return null;
  }
};

export default ArticleContentRenderer;
