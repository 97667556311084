import React, { useState } from 'react';
import { useBreakpointValue, SimpleGrid } from '@chakra-ui/react';

import KnowledgeCard from '../../components/app-knowledge-card/app-knowledge-card';
import { knowledgeCards } from '../../constants/knowledge-card-mocks';
import PaginationControls from '../../components/pagination/pagination';
import KnowledgeSearchBar from '../../components/search/knowledge-search/knowledge-search';
import { AppText } from '@backlinkit/shared';
import FreeDemoSectionComponent from '../../components/free-demo-section/free-demo-section';
import CommunitySectionComponent from '../../components/community-section/community-section';
import { AppBasicHero } from '../../components/app/app-basic-hero/app-basic-hero';
import { AppComponentWrapper } from '../../components/app/app-component-wrapper/app-component-wrapper';
import { AppFaqSection } from '../../components/app/app-faq-section/app-faq-section';
import { faqs } from '../../components/app/faq/dummy-data';
import { useNavigate } from 'react-router-dom';
import { heroBackground } from '../../constants/heroImages';

const ITEMS_PER_PAGE = 6;

const KnoweledgePage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'medium',
    xl: 'desktop',
  });

  const totalPages = Math.ceil(knowledgeCards.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentCards = knowledgeCards.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const navigate = useNavigate();

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const onHandleSearch = (searchString: string) => {
    if (!searchString) return;

    // need to implement search logic once defined :D

    console.log('searchString', searchString);
  };

  const goToArticle = (articleLink: string) => {
    console.log('articleLink', articleLink);
    navigate(`/${articleLink}`);
  };

  return (
    <>
      <AppComponentWrapper px="0">
        <AppBasicHero pageName="Knowledge" title="Knowledge" backgroundImage={heroBackground} />
      </AppComponentWrapper>

      <AppComponentWrapper
        py={variant === 'desktop' ? '120px' : '68px'}
        flexWrap="wrap"
        justify={'center'}
        maxW={'1140px'}
        justifyContent={'center'}
      >
        <AppComponentWrapper
          px="0"
          mb={variant === 'desktop' ? '60px' : '32px'}
          flexDirection={'column'}
          gap={variant === 'desktop' ? '48px' : '24px'}
          w={'100%'}
          maxW={'820px'}
          justifyContent={'center'}
        >
          <AppText
            fontSize={variant === 'desktop' ? '48px' : '24px'}
            textAlign={'center'}
            color={'black'}
            fontWeight={'700'}
          >
            What can we do to assist you?
          </AppText>
          <KnowledgeSearchBar handleSearch={onHandleSearch} />
        </AppComponentWrapper>

        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 3 }}
          gap={variant === 'desktop' ? '30px' : '14px'}
        >
          {currentCards.map((item, idx) => (
            <KnowledgeCard
              key={idx}
              id={item.id}
              description={item.description}
              icon={item.icon}
              title={item.title}
              onClick={() => goToArticle(item.articleLink!)}
            />
          ))}
        </SimpleGrid>

        <AppComponentWrapper
          px={0}
          justify={'center'}
          justifyContent={'center'}
          marginLeft={variant === 'tablet' ? '' : '32px'}
          marginRight={variant === 'tablet' ? '' : '32px'}
        >
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPrev={handlePrevPage}
            onNext={handleNextPage}
            onPageClick={(e) => setCurrentPage(e)}
          />
        </AppComponentWrapper>
      </AppComponentWrapper>

      <AppComponentWrapper>
        <FreeDemoSectionComponent />
      </AppComponentWrapper>

      <AppComponentWrapper mt={variant === 'desktop' ? '120px' : '64px'}>
        <CommunitySectionComponent />
      </AppComponentWrapper>

      <AppComponentWrapper>
        <AppFaqSection
          title="Frequently asked questions"
          description="We have put together some commonly asked questions"
          faqs={faqs}
          variant="light"
        />
      </AppComponentWrapper>
    </>
  );
};

export default KnoweledgePage;
