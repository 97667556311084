
export enum DemoUserRole {
  Employer,
  Employee,
}

export interface CreateReqestDemo {
  userName: string;
  companyName?: string;
  companySize?: string;
  userRole: DemoUserRole;
  email: string;
  contactNumber: number;
  country: string;
  language: string;
  callPurpose: string;
  comment?: string;
  legalAgreement?: boolean;
}
