import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { CloseIcon } from '@chakra-ui/icons';
import { AppColors } from '../../theme';

export type AlertListItem = {
  name: string;
  nameLabel?: string;
  alertType: 'info' | 'warning' | 'error';
  alertMessage: string;
  alertMessageLabel?: string;
  alertNote?: string;
  alertNoteLabel?: string;
  entityId?: string;
  alertKey?: number;
};

export type AppAlertItemProps = {
  item: AlertListItem;
  showName?: boolean;
  showType?: boolean;
  itemsClearable?: boolean;
  onItemClearClick?: (item: AlertListItem) => void;
};

export const AppAlertItem: React.FC<AppAlertItemProps> = ({
  item,
  showName = true,
  showType = true,
  itemsClearable = false,
  onItemClearClick,
}) => {
  const handleClearAlertClick = (item: AlertListItem) => {
    itemsClearable && onItemClearClick && onItemClearClick(item);
  };

  const getAlertTypeLabel = (): string => {
    switch (item.alertType) {
      case 'warning':
        return 'Warning';
      case 'error':
        return 'Earning';
      case 'info':
        return 'Info';
      default:
        break;
    }

    return '';
  };

  const getStyling = () => {
    switch (item.alertType) {
      case 'error':
        return {
          bgColor: 'rgba(255, 0, 0, 0.1)',
          color: 'red',
        };
      case 'info':
        return {
          bgColor: 'rgba(3, 125, 252, 0.1)',
          color: AppColors.secondary2,
        };
      case 'warning':
        return {
          bgColor: 'rgba(255, 136, 0, 0.1)',
          color: AppColors.ctaColor,
        };
    }
  };

  return (
    <Flex flexDir={'column'} gap={4} width={'100%'} mb={2}>
      <Flex direction={'column'} width={'100%'} gap={4}>
        {showName && (
          <Box mt={3}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <AppText mr={2} width={'250px'} fontWeight={'bold'}>
                {item.nameLabel ? item.nameLabel : getAlertTypeLabel()}:
              </AppText>{' '}
              <Flex>
                {item.name}
              </Flex>
            </Flex>
          </Box>
        )}

        {showType && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} alignItems="center">
              <Box width={'250px'}>
                <AppText mr={2} width={'80px'} fontWeight={'bold'}>
                  {item.alertMessageLabel ? item.alertMessageLabel : 'Message'}:
                </AppText>
              </Box>
              <Flex borderRadius={'xl'} p={2} px={4} {...getStyling()} fontWeight={'500'}>
                {item.alertMessage}
              </Flex>
            </Flex>
          </Box>
        )}

        {item.alertNote && (
          <Box mt={showName ? 2 : 0}>
            <Flex direction={'row'} justifyContent={'start'} alignItems="center">
              <Box width={'250px'}>
                <AppText mr={2} width={'80px'} fontWeight={'bold'}>
                  {item.alertNoteLabel ? item.alertNoteLabel : 'Note'}:
                </AppText>
              </Box>
              <Flex borderRadius={'xl'} p={2} px={4} {...getStyling()} fontWeight={'500'}>
                {item.alertNote}
              </Flex>
            </Flex>
          </Box>
        )}
      </Flex>
      {itemsClearable && (
        <Flex>
          <CloseIcon
            onClick={() => handleClearAlertClick(item)}
            _hover={{
              cursor: 'pointer',
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
