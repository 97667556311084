import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const AppColors = {
  primary: '#047EFB',
  secondary: '#2CD7FB',
  tertiary: '#606060',
  contentColor: '#b1b1b1',
  featuredColor: '#0367FC',
  innerContentColor: '#ffffff',
  appBackground: '#eeeeee',
  secondaryBackground: '#rgba(45, 188, 250, 0.1)',
  appBorder: '#F1F4F7',
  ctaColor: '#FF8800',
  footerBackground: '#3B4147',
  iconColor: '#32302E',
  textColor: '#4A4F54',
};

const textStyles = {
  h1: {
    fontSize: ['36px', '56px'],
    fontWeight: '700',
  },
  h2: {
    fontSize: ['36px', '48px'],
    fontWeight: '700',
  },
  h3: {
    fontSize: ['22px', '36px'],
    fontWeight: '700',
  },
  h4: {
    fontSize: ['20px', '24px'],
    fontWeight: '700',
  },
  p1: {
    fontSize: '18px',
  },
  body: {
    fontSize: ['12px', '14px', '16px'],
    fontWeight: '400',
  },
};

const inputComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    height: '60px',
    bgColor: 'gray.100',
    border: 0,
    color: 'gray.500',
    _placeholder: {
      color: 'gray.500',
    },
  },
  variants: {
    default: {
      field: {
        bgColor: 'white',
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '2xl',
        color: 'gray.500',
      },
    },
    blue: {
      field: {
        bgColor: 'rgba(45, 188, 250, 0.1)',
        border: '1px solid',
        outline: 'rgba(3, 125, 252, 0.16) !important',
        borderColor: 'rgba(3, 125, 252, 0.16) !important',
        borderRadius: 'xl',
      },
    },
    blueSquared: {
      field: {
        bgColor: 'rgba(45, 188, 250, 0.1)',
        border: '1px solid',
        outline: 'rgba(3, 125, 252, 0.16) !important',
        borderColor: 'rgba(3, 125, 252, 0.16) !important',
        borderRadius: 'xl',
      },
    },
    grayed: {
      field: {
        bgColor: 'rgba(177, 177, 177, 0.1)',
        borderRadius: '2xl',
      },
    },
    rounded: {
      field: {
        borderRadius: 'full !important',
      },
    },
  },
};
const textAreaComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    minH: '120px',
    border: `1px solid ${AppColors.appBorder}`,
  },
  variants: {
    default: {
      bgColor: 'white',
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '2xl',
      color: 'gray.500',
    },
    blue: {
      bgColor: 'rgba(45, 188, 250, 0.1)',
      border: '1px solid',
      outline: 'rgba(3, 125, 252, 0.16) !important',
      borderColor: 'rgba(3, 125, 252, 0.16) !important',
      borderRadius: 'xl',
    },
    blueSquared: {
      bgColor: 'rgba(45, 188, 250, 0.1)',
      border: '1px solid',
      outline: 'rgba(3, 125, 252, 0.16) !important',
      borderColor: 'rgba(3, 125, 252, 0.16) !important',
      borderRadius: 'xl',
    },
    grayed: {
      bgColor: 'rgba(177, 177, 177, 0.1) !important',
      borderRadius: '2xl',
    },
  },
};

const buttonComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'medium',
    color: AppColors.primary,
    rounded: 'md',
    border: 'none',
    cursor: 'pointer',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      px: 2,
      py: 1,
    },
    sm: {
      fontSize: '16px',
    },
    md: {
      fontSize: '18px',
      px: 8,
      py: 4,
    },
    lg: {
      fontSize: '20px',
    },
    xl: {
      fontSize: '26px',
      px: 8,
      py: 4,
    },
    xxl: {
      fontSize: '36px',
      px: 8,
      py: 4,
    },
  },
  variants: {
    ghost: {
      bgColor: 'transparent !important',
      color: AppColors.innerContentColor,
      border: `1px solid ${AppColors.innerContentColor}`,
      borderRadius: 'full !important',
      cursor: 'pointer',
    },
    ctaVariant: {
      bgColor: AppColors.ctaColor,
      color: 'white',
      border: 'none',
      borderRadius: 'full !important',
      cursor: 'pointer',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const checkboxTheme: ComponentStyleConfig = {
  baseStyle: {
    control: {
      '&[data-checked]': {
        background: AppColors.iconColor,
        borderColor: AppColors.iconColor,
        color: 'white',
      },
      bgColor: 'white',
    },
  },
};

const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '991px',
  xl: '1200px',
  '1xl': '1300px',
  '2xl': '1400px',
  '3xl': '1441px'
}

const theme = extendTheme({
  textStyles: textStyles,
  breakpoints: breakpoints,
  components: {
    Button: buttonComponentStyle,
    Input: inputComponentStyle,
    Checkbox: checkboxTheme,
    Textarea: textAreaComponentStyle,
  },
});

type AppTextStyles = keyof typeof textStyles;
type AppButtonVariants =
  | 'underline'
  | 'solid'
  | 'outline'
  | 'ghost'
  | 'withStartIcon'
  | 'roundedUnderline'
  | 'ctaVariant';
type AppColorsType = keyof typeof AppColors;

export { theme, AppColors };
export type { AppTextStyles, AppButtonVariants, AppColorsType };
